import React, { useState, useCallback } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import CustomTextField from '../base/Textfield/CustomTextField';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../base/Select/CustomSelect';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Button from '../base/Button/Button';
import styles from './Guestlist.module.css'
import { relationships, debounce, validateName, validateEmail, validateTel, validateCountryCode } from '../../constants';

interface GuestFormProps {
  handleAddGuest: (data: any) => any
}

const GuestForm: React.FC<GuestFormProps> = (props) => {
  const { handleAddGuest } = props
  const [guest, setGuest] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    relationship: '',
    phoneNumber: '',
    countryCode: '',
  })
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    countryCode: false,
    relationship: false,
  })

  const resetForm = () => {
    setGuest({
      firstName: '',
      lastName: '',
      email: '',
      relationship: '',
      phoneNumber: '',
      countryCode: '',
    })
  }

  const validateSingleField = (obj: { key: string, value: string }) => {
    const { key, value } = obj
    let rv = true
    if (key === 'firstName' || key === 'lastName' || 'relationship') {
      if (value) {
        rv = validateName(value)
      }
    }
    if (key === 'email') {
      if (value) {
        rv = validateEmail(value)
      }
    }
    if (key === 'phoneNumber') {
      if (value) {
        rv = validateTel(value)
      }
    }
    if (key === 'countryCode') {
      if (value) {
        rv = validateCountryCode(value)
      } else rv = true
    }
    if (!rv) {
      setErrors(prev => ({
        ...prev,
        [key]: true
      }))
    } else {
      setErrors(prev => ({
        ...prev,
        [key]: false
      }))
    }
  }
  const debounceHandler = useCallback(
    debounce(validateSingleField)
    , []);

  const handleGuestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    setGuest({
      ...guest,
      [key]: value
    })

    debounceHandler({ key, value: value.trim() })

  }

  const addGuest = async () => {
    const hasError = validateGuest()
    if (hasError) {
      return
    }
    let rv = await handleAddGuest({ ...guest, phoneNumber: `${guest.countryCode} ${guest.phoneNumber}` })

    if (rv) {
      resetForm()
    }

  }

  const validateGuest = () => {
    let hasError = false
    for (const [key, value] of Object.entries<string>(guest)) {
      switch (key) {
        case "firstName":
          if (!value.trim()) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateName(value)
            if (rv) {
              hasError = false
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        case "lastName":
          if (!value.trim()) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateName(value)
            if (rv) {
              hasError = false
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        case "relationship":
          if (!value.trim()) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateName(value)
            if (rv) {
              hasError = false
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        case "email":
          if (!value.trim()) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateEmail(value)
            if (rv) {
              hasError = false
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        case "phoneNumber":
          if (value.trim()) {
            let rv = validateTel(value)
            if (rv) {
              hasError = false
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        case "countryCode":
          if (value.trim()) {
            let rv = validateCountryCode(value)
            if (rv) {
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        default:
          break;
      }
    }
    return hasError
  }

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} className={styles.isflex} justifyContent={'space-between'}>
        <Box className={styles.col}>
          <InputLabel><span className={styles.mandatory}>First Name</span></InputLabel>
          <CustomTextField error={errors.firstName} helperText={errors.firstName ? 'Requires a valid name' : ''} sx={{ width: { xs: '100%' }, mb: 2 }} value={guest.firstName} onChange={handleGuestChange} id="firstName" name="firstName" placeholder='First Name' size='small' className={styles.input} />
        </Box>
        <Box className={styles.col}>
          <InputLabel>
            <span className={styles.mandatory}>Last Name </span>
          </InputLabel>
          <CustomTextField error={errors.lastName} helperText={errors.lastName ? 'Requires a valid name' : ''} sx={{ width: { xs: '100%' }, mb: 2 }} value={guest.lastName} onChange={handleGuestChange} id="lastName" name="lastName" placeholder='Last Name' size='small' className={styles.input} />
        </Box>
        <Box className={styles.col}>
          <InputLabel>
            <span className={styles.mandatory}>Relationship to Deceased</span>
          </InputLabel>
          <CustomTextField
            error={errors.relationship}
            helperText={errors.relationship ? 'Requires a valid relationship' : ''}
            sx={{ width: { xs: '100%' }, height: 40, mb: 2 }}
            value={guest.relationship}
            onChange={handleGuestChange}
            id='relationship'
            name='relationship'
            placeholder='Relationship'
            size='small'
            className={styles.input}
            type='text'
          />
        </Box>
      </Grid>

      <Grid item xs={12} className={styles.isflex} justifyContent={'space-between'}>
        <Box className={styles.col} >
          <InputLabel> <span className={styles.mandatory}>Email</span> </InputLabel>
          <CustomTextField error={errors.email} helperText={errors.email ? 'Requires a valid email' : ''} sx={{ width: { xs: '100%' }, mb: 2 }} value={guest.email} onChange={handleGuestChange} id="email" name="email" placeholder='Email' size='small' className={styles.input} />
        </Box>
        <Box className={styles.col}>
          <InputLabel >
            Phone Number <span className='optional'></span>
          </InputLabel>
          <Grid container>
            <Grid item xs={12} md={3}>
              <CustomTextField
                error={errors.countryCode}
                helperText={errors.countryCode ? 'Requires a valid country code' : ''}
                sx={{ width: { xs: '100%' }, mb: 4 }}
                value={guest.countryCode}
                onChange={handleGuestChange}
                id='countryCode'
                name='countryCode'
                size='small'
                type=''
                className={styles.input}
                placeholder='65'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" >
                      <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>+</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={9}
              sx={{
                textAlign: 'end'
              }}
            >
              <CustomTextField
                error={errors.phoneNumber}
                helperText={errors.phoneNumber ? 'Requires a valid phone number' : ''}
                sx={{ width: { xs: '98%' }, mb: 2 }}
                value={guest.phoneNumber}
                onChange={handleGuestChange}
                id="phoneNumber"
                name="phoneNumber"
                size='small'
                className={styles.input}
                placeholder='Phone number'
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>+65</span>
              //     </InputAdornment>
              //   ),
              // }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={styles.col}>

        </Box>
      </Grid>

      <Grid item xs={12} className={styles.isflex} sx={{ mb: 2 }}>
        <Button className='btnSecondary' sx={{ width: { xs: 200, sm: 300 } }} onClick={addGuest}>Add Guest</Button>
      </Grid>

    </Grid>
  )
}

export default GuestForm