import React, { useState, useEffect, useCallback } from 'react'
import { useAppSelector } from '../../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import CustomTextField from '../../base/Textfield/CustomTextField';
import CustomSwitch from '../../base/Switch/CustomSwitch';
import DragAndDrop from '../../base/Upload/DragAndDrop';
import Button from '../../base/Button/Button';
import styles from './AccountSteps.module.css'
import { getAccountState } from '../../../features/account/accountSlice';
import { useUpdateAccountMutation, useAddMessageMutation } from '../../../features/account/accountRestApi';
import { debounce, validateName, validateVideoUrl } from '../../../constants';
import Toast from '../../base/Toast/Toast';
import { Typography } from '@mui/material';

interface MessagesProps {
  handleStepper: (step: number) => void
}

const Messages: React.FC<MessagesProps> = (props) => {
  const accountState = useAppSelector(getAccountState);
  const [checked, setChecked] = useState(true);
  const [isGalleryEnabled, setIsGalleryEnabled] = useState(true);
  const [errors, setErrors] = useState({
    message: false,
    videoUrl: false
  })
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })

  const [file, setFile] = useState<any>('')
  const [message, setMessage] = useState('')
  const [videoUrl, setVideoUrl] = useState('')

  const [updateAccount] = useUpdateAccountMutation()
  const [addMessage] = useAddMessageMutation()

  useEffect(() => {
    let bol = accountState.details.showMessage ? true : false
    setChecked(bol)

    let gallery: boolean = accountState.details.showGallery ? true : false
    setIsGalleryEnabled(gallery)
    setVideoUrl(accountState.details.video_url ? accountState.details.video_url : '')
  }, [accountState.details.showMessage])

  const validateSingleField = (obj: { key: string, value: string }) => {
    const { key, value } = obj
    let rv
    if (key === 'message') {
      if (value.trim() === '' || value.trim()) {
        rv = true
      } else {
        rv = false
      }
    }

    if (key === 'videoUrl') {
      if (value === '') {
        rv = false
      } else {
        rv = validateVideoUrl(value)
      }
    }

    if (!rv) {
      setErrors(prev => ({ ...prev, [key]: true }))
    } else {
      setErrors(prev => ({ ...prev, [key]: false }))
    }
  }

  const debounceHandler = useCallback(
    debounce(validateSingleField)
    , []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    let body = {
      funeralId: accountState.details.id,
      query: {
        // showMessage will be used for approveMessage.
        showMessage: event.target.checked ? 1 : 0
      }
    }
    updateAccount(body)
  };

  const handleGalleryChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsGalleryEnabled(event.target.checked);
    let body = {
      funeralId: accountState.details.id,
      query: {
        showGallery: event.target.checked ? 1 : 0
      }
    }
    updateAccount(body)
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value
    debounceHandler({ key, value })
    setMessage(value.replace(/\n\r?/g, '<br />'))
  }

  const handleVideoUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value
    debounceHandler({ key, value })
    setVideoUrl(value)
    // setMessage(value.replace(/\n\r?/g, '<br />'))
  }

  // handle File events
  const handleFile = function (e: any) {
    setFile(e[0])
  };

  const handleSubmit = async () => {
    if (!checked) {
      return
    }
    if (!message) {
      setErrors(prev => ({ ...prev, message: true }))
    } else {
      setErrors(prev => ({ ...prev, message: false }))
    }
    try {
      let funeralId = accountState.details.id
      const formData = new FormData()
      formData.append('file', file);
      formData.append('message', message);
      formData.append('funeralId', JSON.stringify(funeralId));

      let rv = await addMessage(formData).unwrap()

      if (rv) {
        setToast({
          ...toast,
          open: true,
          message: rv.message,
          type: 'success'
        })
        reset()
      }
    } catch (e: any) {
      setToast({
        ...toast,
        open: true,
        message: e.data.message,
        type: 'error'
      })
    }
  }

  const clearUpload = () => {
    setFile('')
  }

  const reset = () => {
    setMessage('')
    setFile('')
  }

  const prevStep = () => {
    // props.handleStepper(7)
    props.handleStepper(3)
  }

  const validate = () => {
    let hasError = false

    if (!videoUrl) {
      setErrors(prev => ({ ...prev, ['videoUrl']: true }))
      hasError = true
    } else {
      errors.videoUrl ? hasError = true : setErrors(prev => ({ ...prev, ['videoUrl']: false }))
    }

    return hasError
  }

  const nextStep = () => {
    const hasError = validate()

    if (isGalleryEnabled) {
      if (hasError) {
        // setLoading(false)
        return
      }
    }

    if (accountState.details.isCompleted === 0 && accountState.details.step < 9) {
      updateAccount({ funeralId: accountState.details.id, query: { step: 9 } })
    }

    if (isGalleryEnabled && videoUrl !== '' && !errors.videoUrl) {
      updateAccount({
        funeralId: accountState.details.id, query: {
          video_url: videoUrl
        }
      })
    }

    // props.handleStepper(9)
    props.handleStepper(5)
  }

  return (
    <Box className={styles.container} sx={{ mt: 4 }}>
      <Toast message={toast.message} type={toast.type} open={toast.open} handleClose={toast.handleClose} />
      <Grid container spacing={2} justifyContent='space-between'>

        <Grid item xs={12} md={7}>

          <Grid container spacing={2}>
            <Grid item>
              <InputLabel className={styles.labelText}>
                Gallery
              </InputLabel>
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", pb: 2 }}>
              <CustomSwitch
                sx={{ ml: 1 }}
                checked={isGalleryEnabled}
                onChange={handleGalleryChecked}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography component={'div'} sx={{ pl: 1 }}>
                {isGalleryEnabled ? 'Turn off the toggle to hide the gallery' : 'Turn on the toggle to show the gallery'}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="videoUrl">Video url</InputLabel>
              <CustomTextField
                disabled={isGalleryEnabled ? false : true}
                sx={{ height: 70, width: '100$', mb: '20px' }}
                variant='outlined'
                error={errors.videoUrl}
                helperText={errors.videoUrl ? 'Requires a valid video url' : ''}
                value={videoUrl}
                id="videoUrl"
                name="videoUrl"
                placeholder='Video url'
                onChange={handleVideoUrlChange}
                fullWidth
                className={styles.input}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item>
              <InputLabel error={errors.message} className={styles.labelText}>Messages

              </InputLabel>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", pb: 2 }}>
              <CustomSwitch
                sx={{ ml: 1 }}
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography component={'div'} sx={{ pl: 1 }}>
                {checked ? 'Turn off the toggle to approve the Heartfelt Messages manually' : 'Turn on the toggle to approve the Heartfelt Messages automatically'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component={'div'} sx={{ color: 'var(--TLC-drkgraytxt)' }}>Message</Typography>
              <CustomTextField error={errors.message} helperText={errors.message ? 'Requires a message' : ''} className={styles.input} sx={{ width: { xs: '100%' } }} id="message" value={message.replace(/<br \/>?/g, '\n')} onChange={handleMessageChange} multiline rows={4} placeholder="New Message"></CustomTextField >
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ pb: 3, mt: 2 }} className={styles.labelText} >Upload Message image ({file ? 1 : 0}/1)</InputLabel>
          <DragAndDrop label="message-image" filetype='.jpeg, .png' handleFile={handleFile} currFileName={''} file={file} clearUpload={clearUpload} />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
        <Grid item xs={8} md={2.2} sx={{ mt: 2 }}>
          <Button className='btnSecondary' sx={{ width: { xs: 250, sm: 300 }, mb: 4 }} onClick={handleSubmit}>Submit</Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="end" alignItems="center" sx={{ mt: 2 }}>
        {/* <Grid item xs={8} md={2.2} sx={{mt: 2}}>
          <Button className='btnPrimary' sx={{width: {xs: 250, sm: 270}, mb: 4}} onClick={prevStep}>Previous</Button>
        </Grid> */}
        <Grid item xs={8} md={2.2} sx={{ mt: 2 }}>
          <Button className='btnSecondary' sx={{ width: { xs: '100%', sm: 270 }, mb: 4 }} onClick={nextStep}>Next</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Messages