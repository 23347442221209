import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import MuiLink from "@mui/material/Link"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Modal from "@mui/material/Modal"
import Badge from "@mui/material/Badge"
import CustomSearch from "../base/Textfield/CustomSearch"
import InputAdornment from "@mui/material/InputAdornment"
import Table from "@mui/material/Table"
import { StyledTableBody, StyledTableSortLabel } from "../base/Table/CustomTable"
import Pagination from "../base/Pagination/Pagination"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Checkbox from "@mui/material/Checkbox"
import { CheckedIcon, Icon, IndeterminateIcon } from "../base/Checkbox/CustomCheckbox"
import MenuItem from "@mui/material/MenuItem"
import OutlinedInput from "@mui/material/OutlinedInput"
import { SelectChangeEvent } from "@mui/material/Select"
import CustomSelect from "../base/Select/CustomSelect"
import SearchIcon from "@mui/icons-material/Search"
import styles from "./DashboardTable.module.css"
import Button from "../base/Button/Button"
import GuestlistIcon from "../base/Icons/GuestlistIcon"
import EditIcon from "../base/Icons/EditIcon"
import ViewpgIcon from "../base/Icons/ViewpgIcon"
import CalenderIcon from "../base/Icons/CalenderIcon"
import ChecklistIcon from "../base/Icons/ChecklistIcon"
import SnowflakeIcon from "../base/Icons/SnowflakeIcon"
import { Order, funeralDashboard } from "../../type"
import { getComparator, stableSort, formatDate, csvmaker, downloadCsv } from "../../constants"
import { useDeleteFuneralMutation, useArchiveFuneralMutation, useUpdateUserSettingsMutation } from "../../features/dashboard/dashboardRestApi"
import { setDashboardPage } from "../../features/dashboard/dashboardSlice"
import Toast from "../base/Toast/Toast"
import RfmModal from "./RfmModal"
import { Typography } from "@mui/material"
import { useAppDispatch } from "../../app/hooks"

interface DashboardTableProps {
  funerals: funeralDashboard[]
  checklistCount: any[]
  familyCount: any[]
  rfmlist: any[]
  settings: any
}

interface TableToolbarProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof funeralDashboard) => void
  onRequestSearch: (value: string) => void
  order: Order
  orderBy: string
}

interface TableHeadProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleActionSubmit: (action: string) => void
  rowCount: number
}

const actions = ["Delete", "Download", "Archive", "Unarchive"]

function TableToolbar(props: TableToolbarProps) {
  const { order, orderBy, onRequestSort, onRequestSearch } = props
  const createSortHandler = (property: keyof funeralDashboard) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  const requestSearch = (event: any) => {
    onRequestSearch(event.target.value)
  }

  return (
    <Grid className={styles.toolbar} container spacing={2} justifyContent="space-between">
      <Grid item>
        <CustomSearch
          sx={{ mb: 4 }}
          variant="outlined"
          placeholder="Search"
          onChange={requestSearch}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: "var(--TLC-web-grey)" }} />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item style={{ overflowX: "auto" }}>
        <StyledTableSortLabel className="sortLabel" active={orderBy === "lastName"} direction={orderBy === "lastName" ? order : "asc"} onClick={createSortHandler("lastName")}>
          Alphabetically
        </StyledTableSortLabel>
        <StyledTableSortLabel className="sortLabel" active={orderBy === "dod"} direction={orderBy === "dod" ? order : "asc"} onClick={createSortHandler("dod")}>
          Date Deceased
        </StyledTableSortLabel>
        <StyledTableSortLabel className="sortLabel" active={orderBy === "createdAt"} direction={orderBy === "createdAt" ? order : "asc"} onClick={createSortHandler("createdAt")}>
          Date Account Created
        </StyledTableSortLabel>
      </Grid>
    </Grid>
  )
}

function TableCustomHead(props: TableHeadProps) {
  const { onSelectAllClick, handleActionSubmit, numSelected, rowCount } = props
  const [action, setAction] = useState("Delete")
  const handleActionChange = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event
    setAction(value)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="normal" sx={{ padding: 0, paddingBottom: "16px" }}>
          <Checkbox icon={<Icon />} checkedIcon={<CheckedIcon />} indeterminateIcon={<IndeterminateIcon />} color="primary" indeterminate={numSelected > 0 && numSelected < rowCount} checked={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick} />
        </TableCell>
        <TableCell sx={{ padding: 0, paddingBottom: "16px" }} colSpan={7}>
          Action:
          <CustomSelect sx={{ width: "200px", mx: 2 }} size="small" labelId="action" value={action} onChange={handleActionChange} input={<OutlinedInput />}>
            {actions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </CustomSelect>
          <Button className="btnTransparent" sx={{ width: { xs: "200px" } }} onClick={() => handleActionSubmit(action)}>
            Submit
          </Button>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const DashboardTable: React.FC<DashboardTableProps> = (props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { settings } = props
  const [toast, setToast] = useState({
    message: "",
    type: "success",
    open: false,
    handleClose: () => {
      setToast((prev) => ({ ...prev, open: false }))
    }
  })
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(settings.page)
  const [rowsPerPage, setRowsPerPage] = useState(settings.rowsPerPage)
  const [order, setOrder] = useState<Order>(settings.order)
  const [orderBy, setOrderBy] = useState(settings.orderBy)
  const [selected, setSelected] = useState<funeralDashboard[]>([])
  const [filtered, setFiltered] = useState<funeralDashboard[]>([])
  const [deleteFuneral] = useDeleteFuneralMutation()
  const [archiveFuneral] = useArchiveFuneralMutation()
  const [updateSettings] = useUpdateUserSettingsMutation()

  const [openModal, setOpenModal] = useState(false)
  const [modalProps, setModalProps] = useState({
    rfmlist: [],
    deceased: ""
  })
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)

  useEffect(() => {
    setFiltered(props.funerals)
  }, [props.funerals])

  useEffect(() => {
    setRowsPerPage(settings.rowsPerPage)
    setOrder(settings.order)
    setOrderBy(settings.orderBy)
    setPage(settings.page)
  }, [settings])

  const isSelected = (id: string | number) => {
    return selected.map((x) => x.id).indexOf(id) !== -1
  }

  const filteredItems = props.funerals.filter((item) => {
    let str = item.lastName

    let result = str.toLowerCase().includes(query.toLowerCase())

    if (result) {
      return result
    } else {
      const funeralDirector = item.funeralDirectorName
      result = funeralDirector.toLowerCase().includes(query.toLowerCase())
    }

    return result
  })

  const handleSearch = (value: string) => {
    setQuery(value)
    setPage(0)
  }

  const handleSetup = (id: any) => {
    navigate(`/account/edit/${id}`)
  }

  //Sort
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof funeralDashboard) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)

    updateSettings({ update: { dashboard_sort: property, dashboard_order: isAsc ? "desc" : "asc" } })
  }

  //All checkbox
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filtered
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  //Individual checkbox
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selected.map((x) => x.id).indexOf(row.id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected.push(...selected, row)
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1)
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1)
    } else if (selectedIndex > 0) {
      let array1 = selected.slice(0, selectedIndex)
      let array2 = selected.slice(selectedIndex + 1)
      newSelected = array1.concat(array2)
    }
    setSelected(newSelected)
  }

  const handleActionSubmit = async (action: string) => {
    try {
      let rv
      let body = {
        funerals: selected
      }
      if (action === "Delete") {
        rv = await deleteFuneral(body).unwrap()
      }
      if (action === "Archive") {
        rv = await archiveFuneral({ ...body, archive: 1 }).unwrap()
      }
      if (action === "Unarchive") {
        rv = await archiveFuneral({ ...body, archive: 0 }).unwrap()
      }
      if (action === "Download") {
        rv = handleDownload()
      }

      if (rv) {
        setToast({
          ...toast,
          message: rv.message,
          open: true,
          type: "success"
        })
        setSelected([])
      }
    } catch (e: any) {
      setToast({
        ...toast,
        message: e.toString(),
        open: true,
        type: "error"
      })
    }
  }

  const handleDownload = () => {
    let csv = csvmaker(selected)
    let rv = downloadCsv(csv, "accounts")
    return rv
  }

  //Pagination
  const totalPages = Math.ceil(filteredItems.length / rowsPerPage)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1)
    dispatch(setDashboardPage(newPage - 1))
  }

  const handleChangeRowsPerPage = (rpp: number) => {
    //let rpp = parseInt(event.target.value, 10)
    setRowsPerPage(rpp)
    setPage(0)
    updateSettings({ update: { dashboard_page: rpp } })
  }

  const getChecklistCounter = (id: string | number) => {
    let found = props.checklistCount.find((item: any) => {
      return item.funeralId === id
    })
    if (found) {
      return found.count
    } else {
      return 0
    }
  }

  const getRFMlist = (id: string | number) => {
    let found = props.rfmlist.filter((item: any) => {
      return item.funeralId === id
    })
    return found
  }

  const handleViewRfm = (data: any) => {
    setModalProps(data)
    handleOpen()
  }

  return (
    <>
      <Toast {...toast} />
      <TableToolbar order={order} orderBy={orderBy} onRequestSort={handleRequestSort} onRequestSearch={handleSearch} />
      <TableContainer sx={{ mt: { xs: 2 } }}>
        <Table>
          <TableCustomHead handleActionSubmit={handleActionSubmit} numSelected={selected.length} onSelectAllClick={handleSelectAllClick} rowCount={filteredItems.length} />
          <StyledTableBody>
            {stableSort(filteredItems, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`
                const getCount = getChecklistCounter(row.id)
                const rfmlist = getRFMlist(row.id)
                const deceased = row.lastName

                return (
                  <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={index} selected={isItemSelected}>
                    <TableCell padding="checkbox" sx={{ padding: 0, display: "flex", borderBottom: 0, paddingTop: "15px" }}>
                      <Checkbox
                        icon={<Icon />}
                        checkedIcon={<CheckedIcon />}
                        onClick={(event) => handleClick(event, row)}
                        color="primary"
                        checked={isItemSelected || false}
                        inputProps={{
                          "aria-labelledby": labelId
                        }}
                      />
                    </TableCell>
                    <TableCell component="td" id={labelId} scope="row" padding="normal" sx={{ minWidth: { xs: 150 }, width: { lg: 150 } }}>
                      <div className={styles.headers}>
                        <div>Deceased: </div>
                        <div>Main RFM: </div>
                        <div>Main Contact: </div>
                        <div>RFMs:</div>
                        <div>Funeral Director:</div>
                        <div>Date Deceased:</div>
                        <div>Date Created:</div>
                      </div>
                    </TableCell>
                    <TableCell component="td" id={labelId} scope="row" padding="normal" sx={{ width: { xs: 150, lg: 250 }, maxWidth: { xs: "150px", md: "200px", lg: "200px" } }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ minWidth: "100px" }} className={styles.deceased}>
                          {deceased}
                        </Typography>{" "}
                        {row.status === "archived" ? (
                          <>
                            <Box sx={{ width: 16, height: 16 }}>
                              <SnowflakeIcon />
                            </Box>
                            <Typography sx={{ pl: 1 }} className={styles.bodyText}>
                              Archived
                            </Typography>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={styles.label}>
                        <div>{rfmlist[0] ? rfmlist[0].lastName : "-"}</div>
                        <div>{rfmlist[0] ? rfmlist[0].mainContact || "-" : "-"}</div>
                        <div>
                          {rfmlist.length || 0}
                          {rfmlist.length > 0 && (
                            <MuiLink className={styles.link} sx={{ ml: 2 }} onClick={() => handleViewRfm({ rfmlist, deceased })}>
                              View
                            </MuiLink>
                          )}
                        </div>
                        <div>{row.funeralDirectorName}</div>
                        <div>{formatDate(row.dod, "DD MMM YYYY")}</div>
                        <div>{formatDate(row.createdAt, "DD MMM YYYY", false)}</div>
                      </div>
                    </TableCell>
                    <TableCell align="right" sx={{ minWidth: { xs: 100, lg: 80 } }}>
                      <Link className={styles.link} to={`/account/checklist/${row.id}`}>
                        <Badge badgeContent={getCount} showZero sx={{ mr: 1 }}>
                          <ChecklistIcon />
                        </Badge>
                        <span style={{ marginLeft: "4%" }}>Checklist</span>
                      </Link>
                    </TableCell>
                    <TableCell align="right" sx={{ minWidth: { xs: 100, lg: 80 } }}>
                      <Link className={styles.link} to={`/account/guestlist/${row.id}`}>
                        <Badge badgeContent={0}>
                          <GuestlistIcon />
                        </Badge>
                        <span style={{ marginLeft: "4%" }}>Guest List</span>
                      </Link>
                    </TableCell>
                    <TableCell align="right" sx={{ minWidth: { xs: 100, lg: 80 } }}>
                      <Link className={styles.link} to={`/account/event/${row.id}`}>
                        <Badge badgeContent={0}>
                          <CalenderIcon />
                        </Badge>
                        <span style={{ marginLeft: "4%" }}>Events</span>
                      </Link>
                    </TableCell>
                    <TableCell align="right" sx={{ minWidth: { xs: 100, lg: 80 } }}>
                      <MuiLink className={styles.link} href={`${process.env.REACT_APP_SHOP_URL}${row.lastName}/${row.id}`} target="_blank" rel="noopener noreferrer">
                        <Badge badgeContent={0}>
                          <ViewpgIcon />
                        </Badge>
                        <span style={{ marginLeft: "4%" }}>View Page</span>
                      </MuiLink>
                    </TableCell>
                    <TableCell align="right" sx={{ minWidth: { xs: 100, lg: 80 } }}>
                      {row.isCompleted ? (
                        <Link className={styles.link} to={`/account/edit/${row.id}`}>
                          <Badge badgeContent={0}>
                            <EditIcon />
                          </Badge>
                          <span style={{ marginLeft: "4%" }}>Edit Page</span>
                        </Link>
                      ) : (
                        <Button className="btnTransparent" sx={{ width: "100%" }} onClick={() => handleSetup(row.id)}>
                          Continue Setup
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
          </StyledTableBody>
        </Table>
      </TableContainer>
      <Pagination sx={{ mt: 3 }} variant="outlined" shape="rounded" count={totalPages} page={page + 1} onChange={handleChangePage} handleViewChange={handleChangeRowsPerPage} />
      {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      <Modal open={openModal} onClose={handleClose}>
        <RfmModal handleClose={handleClose} {...modalProps} />
      </Modal>
    </>
  )
}

export default DashboardTable
