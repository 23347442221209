import { createApi ,fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { logout } from '../features/user/userSlice'
const API_URL = process.env.REACT_APP_EXPRESS_API

if (!API_URL) {
  throw new Error(
    `The environment variable REACT_APP_EXPRESS_API is missing and it's required to access your store`
  )
}

export const customFetchQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem('vendure-auth-token')
    //const token = (getState() as RootState).auth.auth.access_token

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  },
  //credentials: 'include'
})

const customFetchBase: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await customFetchQuery(args, api, extraOptions);
  if(result.error) {
    if((result.error?.data as any).error ===  'Token error') {
      api.dispatch(logout());
      localStorage.removeItem('vendure-auth-token')
      //window.location.href = "./"
    }
  }

  return result
}

export const restApi = createApi({
  reducerPath: 'restApi',
  baseQuery: customFetchBase,
  tagTypes: ['settings', 'dashboard','selectedChecklist', 'approvalTable', 'GetEventlist', 'initialiseAccount', 'getRfmList', 'getEvent',  'checklist', 'messages', 'mainGuestlist', 'guestlist', 'event_guestlist', 'main_guestlist', 'broadcast', 'funeralDirectors', 'accountDetails'],
  endpoints: (builder) => ({})
})