import { restApi } from '../../api/restApi'
import { setEventlist, setEvent } from './eventSlice';
import { event } from '../../type';

type EventlistResponse = event[]

export const eventlistRestAPi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getEventlist: builder.query<EventlistResponse,any|undefined>({
      providesTags: (result,  error, id) => {
        return [{type: 'GetEventlist', id: id}]
      },
      query: (id) => ({
        url: `/api/app-template/account/event/list/${id}`,
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setEventlist(data))
        } catch (error) {}
      },
    }),
    getEvent: builder.query({
      providesTags: (result,  error, id) => {
        return ['getEvent']
      },
      query: (id) => ({
        url: `/api/app-template/account/event/${id}`,
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setEvent(data))
        } catch (error) {}
      },
    }),
    deleteEvent: builder.mutation({
      invalidatesTags: ['GetEventlist'],
      query: (payload) => ({
        url: '/api/app-template/event/delete',
        method: 'POST',
        body: payload,
      })
    }),
    updateEventRFM: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/event/update/rfm',
        method: 'POST',
        body: payload,
      })
    }),
  }),
})

export const { useGetEventlistQuery, useGetEventQuery, useDeleteEventMutation, useUpdateEventRFMMutation } = eventlistRestAPi