import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../../base/Select/CustomSelect';
import MenuItem from '@mui/material/MenuItem';
import DragAndDrop from '../../base/Upload/DragAndDrop';
import RfmForm from '../../Rfm/RfmForm';
import { getAccountState } from '../../../features/account/accountSlice';
import styles from './AccountSteps.module.css'
import { Typography } from '@mui/material';
import { setAccountDetails } from '../../../features/account/accountSlice';
import { useCreateAccountMutation } from '../../../features/account/accountRestApi';
import { races, religions, initialChecklist, debounce, validateName, validateNumeric } from '../../../constants';
import Toast from '../../base/Toast/Toast';
import CustomTextField from '../../base/Textfield/CustomTextField';
import InputAdornment from "@mui/material/InputAdornment"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import 'dayjs/locale/en-gb';
import { useGetFuneralDirectorListQuery } from '../../../features/funeralDirector/funeralDirectorRestApi';
interface DetailsProps {
  handleStepper: (step: number) => void
}

type RFM = {
  firstName: string,
  lastName: string,
  relationship: string,
  email: string,
  phoneNumber: string,
  address: string
}

type details = {
  firstName: string
  lastName: string
  dob: string | null
  dod: string | null
  race?: string
  religion?: string
  deceasedIc?: string | null
  deceasedAddress?: string | null
  deceasedAge?: number | null
  funeralDirectorId: number
}

const Details: React.FC<DetailsProps> = (props) => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const accountState = useAppSelector(getAccountState);
  const [file, setFile] = useState<any>('')
  const [details, setDetails] = useState<details>({
    firstName: '',
    lastName: '',
    dob: '',
    dod: '',
    religion: 'Budhhist',
    race: '-',
    deceasedIc: '',
    deceasedAddress: '',
    deceasedAge: 0,
    funeralDirectorId: 0
  })
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    rfm: false,
    deceasedAge: false
  })
  const [toast, setToast] = useState({
    type: 'success',
    open: false,
    message: "",
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })
  const [errorMsg, setErrorMsg] = useState({
    error: false,
    message: ''
  })

  const [createAccount] = useCreateAccountMutation()

  useEffect(() => {
    let initial = {
      firstName: accountState.details.firstName,
      lastName: accountState.details.lastName,
      dob: formatDate(accountState.details.dob),
      dod: formatDate(accountState.details.dod),
      race: accountState.details.race || '-',
      religion: accountState.details.religion || 'Budhhist',
      deceasedIc: accountState.details?.deceasedIc || '',
      deceasedAddress: accountState.details?.deceasedAddress || '',
      deceasedAge: accountState.details?.deceasedAge || 0,
      funeralDirectorId: accountState.details?.funeralDirector.id || 1
    }
    setDetails({
      ...initial
    })
    // setFuneralDirector(accountState.details.funeralDirector)
  }, [accountState.details])

  const [funderalDirectors, setFunderalDirectors] = useState<any[]>([])

  const getFuneralDirectorList = useGetFuneralDirectorListQuery(null);
  useEffect(() => {
    if (!getFuneralDirectorList.isLoading && getFuneralDirectorList.data) {
      setFunderalDirectors(getFuneralDirectorList.data)
    }
  }, [getFuneralDirectorList])


  const formatDate = (date: Date | null) => {
    let selectedDate = date ? date : new Date()
    let strMonth

    let month = new Date(selectedDate).getMonth() + 1
    if (month < 10) {
      strMonth = '0' + month.toString()
    } else {
      strMonth = month.toString()
    }
    let year = new Date(selectedDate).getFullYear()
    let currDate = new Date(selectedDate).getDate()
    return `${strMonth}/${currDate}/${year}`

  }

  // handle File events
  const handleFile = function (e: any) {
    setFile(e[0])
  };

  const clearUpload = () => {
    setFile('')
  }

  const validateSingleField = (obj: { key: string, value: string }) => {
    const { key, value } = obj
    let rv
    if (key === 'firstName' || key === 'lastName') {
      rv = validateName(value)
    }
    if (key === 'deceasedAge') {
      rv = validateNumeric(value)
    }

    if (!rv) {
      setErrors(prev => ({ ...prev, [key]: true }))
    } else {
      setErrors(prev => ({ ...prev, [key]: false }))
    }
  }

  const debounceHandler = useCallback(
    debounce(validateSingleField)
    , []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    setDetails({
      ...details,
      [key]: value
    })

    debounceHandler({ key, value: value.trim() })
  }

  const handleReligionChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setDetails({
      ...details,
      religion: value
    })
  }

  const handleFuneralDirectorChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    setDetails({
      ...details,
      funeralDirectorId: parseInt(value)
    })
  }

  const handleRaceChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setDetails({
      ...details,
      race: value
    })
  }

  const handleDateChange = (key: string, value: any) => {
    const dateStr = dayjs(value).format('MM/DD/YYYY')
    setDetails({
      ...details,
      [key]: dateStr
    })
    /* if(key === 'dob') {
      setDob(value)
    }else {
      setDod(value)
    } */
  }

  const onSave = async (rfmList: RFM[]) => {
    let rv = validate()

    if (rv) return

    if (rfmList.length === 0) {
      setErrors({ ...errors, rfm: true })
      return
    }

    let body = {
      type: accountState.details.id ? 'update' : 'add',
      funeralId: accountState.details.id ? accountState.details.id : null,
      ...details,
      rfmList: rfmList,
      initialChecklist: initialChecklist
    }

    const formData = new FormData()
    formData.append('file', file);
    formData.append('body', JSON.stringify(body));

    if (!accountState.details.id) {
      try {
        await createAccount(formData).unwrap();
        dispatch(setAccountDetails(body))
        props.handleStepper(1)
      } catch (error) {
        console.error('rejected', error);
      }
    } else {
      try {
        let rv = await createAccount(formData).unwrap();
        dispatch(setAccountDetails(body))
        props.handleStepper(1)
      } catch (e) {
        console.error('rejected', e);
      }
    }

    // props.handleStepper(1)
  }

  const validate = () => {
    let hasError = false
    for (const [key, value] of Object.entries(details)) {
      switch (key) {
        case "firstName":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          }
          break;
        case "lastName":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          }
          break;
        default:
          break;
      }
    }

    return hasError

  }

  return (
    <Box sx={{ px: 2, pb: 4 }}>
      <Box sx={{ pt: 4, pb: 2 }} style={{ display: 'flex' }}>
        <div className={styles.blueSq}></div>
        <span className='sectionText' style={{ marginLeft: 10 }}>Required fields to be filled.</span>
      </Box>
      <Box className={styles.container} sx={{ mt: 1, pb: 1 }}>
        <Toast {...toast} />
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4}>
            <Box>
              <InputLabel required>
                <span className={styles.mandatory}>
                  Funeral Director
                </span>
              </InputLabel>
              <CustomSelect
                id="funeralDirector"
                sx={{ width: { xs: '100%', md: '92%' } }}
                size='small'
                labelId="action"
                value={details.funeralDirectorId}
                onChange={handleFuneralDirectorChange}
              >
                {funderalDirectors.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <InputLabel
              error={errors.firstName}
              required
            >
              <span className={styles.mandatory}>
                Deceased Full Name
              </span>
            </InputLabel>
            <CustomTextField
              error={errors.firstName}
              helperText={errors.firstName ? 'Requires a valid name' : ''}
              sx={{ width: { xs: '100%', md: '92%' }, height: 40, mb: 4 }}
              value={details.firstName}
              onChange={handleChange}
              id="firstName"
              name="firstName" placeholder='Deceased Full Name'
              size='small'
              className={styles.input}
            />

            <InputLabel
              required
            >
              <span className={styles.mandatory}>
                Deceased Date of Birth
              </span>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <MobileDatePicker
                className={styles.input}
                value={details.dob}
                disableFuture
                onChange={(newValue) => handleDateChange('dob', newValue)}
                renderInput={(params) => <CustomTextField sx={{ width: { xs: '100%', md: '92%' }, height: 40, mb: 4 }} size='small' {...params} placeholder='dd/mm/yy' InputProps={{ endAdornment: (<InputAdornment position="end"><CalendarMonthIcon /></InputAdornment>) }} />}
                inputFormat="DD/MM/YYYY"
                componentsProps={{
                  actionBar: {
                    sx: {
                      '& .MuiButtonBase-root': {
                        color: 'var(--TLC-gray)',
                      }
                    }
                  },
                }}
              />
            </LocalizationProvider>


            <InputLabel
              required
            >
              {/* Race <span className='optional'>(optional)</span> */}
              Race
            </InputLabel>
            <CustomSelect
              id="race"
              sx={{ width: { xs: '100%', md: '92%' }, mb: 4 }}
              size='small'
              labelId="action"
              value={details.race}
              onChange={handleRaceChange}
            >
              {races.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'var(--TLC-web-grey)',
                      '&:hover': {
                        backgroundColor: 'var(--TLC-web-grey)',
                      },
                    },
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </CustomSelect>

            <Box>
              <InputLabel><span className={styles.mandatory}>Religion</span> </InputLabel>
              <CustomSelect
                id="religion"
                sx={{ width: { xs: '100%', md: '92%' } }}
                size='small'
                labelId="action"
                value={details.religion}
                onChange={handleReligionChange}
              >
                {religions.map((item) => (
                  <MenuItem
                    key={item}
                    value={item || 'Budhhist'}
                  >
                    {item}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <InputLabel
                error={errors.lastName}
                required
              >
                <span className={styles.mandatory}>
                  Deceased Display Name
                </span>
              </InputLabel>
              <CustomTextField error={errors.lastName} helperText={errors.lastName ? 'Requires a valid name' : ''} sx={{ width: { xs: '100%', md: '92%' }, height: 40, mb: 4 }} value={details.lastName} onChange={handleChange} id="lastName" name="lastName" placeholder='Deceased Display Name' size='small' className={styles.input} />
            </Box>
            <Box>
              <InputLabel
                required
              >
                <span className={styles.mandatory}>
                  Deceased Date of Death
                </span>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <MobileDatePicker
                  className={styles.input}
                  value={details.dod}
                  disableFuture
                  onChange={(newValue) => handleDateChange('dod', newValue)}
                  renderInput={(params) => <CustomTextField sx={{ width: { xs: '100%', md: '92%' }, height: 40, mb: 4 }} size='small' {...params} placeholder='dd/mm/yy' InputProps={{ endAdornment: (<InputAdornment position="end"><CalendarMonthIcon /></InputAdornment>) }} />}
                  componentsProps={{
                    actionBar: {
                      sx: {
                        '& .MuiButtonBase-root': {
                          color: 'var(--TLC-gray)',
                        }
                      }
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <InputLabel><span className={styles.mandatory}>Deceased IC </span> <span className='optional'>(optional)</span></InputLabel>
              <CustomTextField sx={{ width: { xs: '100%', md: '92%' }, height: 40, mb: 4 }} value={details.deceasedIc} onChange={handleChange} id="deceasedIc" name="deceasedIc" placeholder='Deceased IC' size='small' className={styles.input} />
            </Box>
            <Box>
              <InputLabel><span className={styles.mandatory}>Deceased Address </span> <span className='optional'>(optional)</span></InputLabel>
              <CustomTextField sx={{ width: { xs: '100%', md: '92%' }, height: 40, mb: 4 }} value={details.deceasedAddress} onChange={handleChange} id="deceasedAddress" name="deceasedAddress" placeholder='Deceased Address' size='small' className={styles.input} />
            </Box>
            <Box>
              <InputLabel><span className={styles.mandatory}>Deceased Age </span>  <span className='optional'>(optional)</span></InputLabel>
              <CustomTextField error={errors.deceasedAge} helperText={errors.deceasedAge ? 'Requires a valid age' : ''} sx={{ width: { xs: '100%', md: '92%' }, height: 40, mb: 4 }} value={details.deceasedAge} onChange={handleChange} id="deceasedAge" name="deceasedAge" placeholder='Deceased Age' size='small' className={styles.input} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <InputLabel>Profile Picture of Deceased</InputLabel>
              <DragAndDrop label="profile" filetype='.jpeg, .png' handleFile={handleFile} currFileName={accountState.details.profile_image} file={file} clearUpload={clearUpload} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Typography sx={{ mb: 2, mt: 4 }} className='textBold'>Registered Family Member (RFM)</Typography>
      <RfmForm onSave={onSave} errorMsg={errorMsg} />
    </Box>
  )
}

export default Details