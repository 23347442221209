import React, { useState, useEffect, useCallback } from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import CustomTextField from "../base/Textfield/CustomTextField"
import InputAdornment from "@mui/material/InputAdornment"
import { SelectChangeEvent } from "@mui/material/Select"
import CustomSelect from "../base/Select/CustomSelect"
import MenuItem from "@mui/material/MenuItem"
import Button from "../base/Button/Button"
import Toast from "../base/Toast/Toast"
import styles from "./Rfm.module.css"
import { relationships, debounce, validateName, validateEmail, validateTel, validatePostal, validateCountryCode } from "../../constants"

interface EditRfmProps {
  selectedRFM: any
  handleUpdateRFM: (rfm: any) => void
  handleClose: () => void
  rfmList: any
}

const EditRfm = React.forwardRef<"", EditRfmProps>((props, ref) => {
  const { selectedRFM, handleClose, handleUpdateRFM } = props
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    message: "",
    type: "",
    open: false,
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })
  const [rfm, setRfm] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    relationship: "",
    phoneNumber: "",
    address: "",
    countryCode: ""
  })
  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    postalCode: ''
  })
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    countryCode: false,
    relationship: false,
  })
  const [failMsg, setFailMsg] = useState<any>({
    error: false,
    message: ''
  })

  useEffect(() => {
    setRfm({
      ...selectedRFM,
      phoneNumber: selectedRFM?.phoneNumber.split(" ")[1],
      countryCode: selectedRFM?.phoneNumber.split(" ")[0],
    })
    let address = selectedRFM.address.split(',')
    setAddress({
      line1: address[0] ? address[0].trim() : '',
      line2: address[1] ? address[1].trim() : '',
      postalCode: address[2] ? address[2].trim() : ''
    })
  }, [selectedRFM])

  const validateSingleField = (obj: { key: string; value: string }) => {
    const { key, value } = obj
    let rv
    if (key === "firstName" || key === "lastName" || 'relationship') {
      rv = validateName(value)
    }
    if (key === "phoneNumber") {
      rv = validateTel(value)
    }
    if (key === "countryCode") {
      rv = validateCountryCode(value)
    }
    if (key === "email") {
      rv = validateEmail(value)
    }

    if (!rv) {
      setErrors((prev) => ({ ...prev, [key]: true }))
    } else {
      setErrors((prev) => ({ ...prev, [key]: false }))
    }
  }

  const debounceHandler = useCallback(debounce(validateSingleField), [])

  const validateRFM = (item: any) => {
    let hasError = false
    for (const [key, value] of Object.entries<string>(item)) {
      switch (key) {
        case "firstName":
          if (!value.trim()) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateName(value.trim())
            if (rv) {
              hasError = false
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break
        case "lastName":
          if (!value.trim()) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateName(value.trim())
            if (rv) {
              hasError = false
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break
        case "relationship":
          if (!value.trim()) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateName(value.trim())
            if (rv) {
              hasError = false
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break
        case "email":
          if (!value.trim()) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateEmail(value.trim())
            if (rv) {
              hasError = false
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break
        case "phoneNumber":
          if (value.trim()) {
            let rv = validateTel(value.trim())
            if (rv) {
              hasError = false
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break
        case "countryCode":
          if (value.trim()) {
            let rv = validateCountryCode(value.trim())
            if (rv) {
              hasError = false
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break

        default:
          break
      }
    }
    return hasError
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    setRfm({
      ...rfm,
      [key]: value
    })

    debounceHandler({ key, value: value.trim() })
  }

  const handleRelationshipChange = (event: any) => {
    const {
      target: { value }
    } = event
    setRfm({
      ...rfm,
      relationship: value
    })
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value
    if (key === 'postalCode') {
      let regex = /^\d*$/
      if (!regex.test(value.trim())) {
        return
      } else {
        setAddress({
          ...address,
          [key]: value
        })
        return
      }
    }
    setAddress({
      ...address,
      [key]: value.trim()
    })

    debounceHandler({ key, value: value.trim() })
  }

  const addAddress = async () => {
    if (address.line1 || address.line2 || address.postalCode) {
      let rv = validatePostal(address.postalCode)
      if (!rv) {
        setFailMsg({
          error: true,
          message: "Please enter a valid Postal Code!"
        })
        throw new Error()
      } else {
        setFailMsg({
          error: false,
          message: ""
        })
      }
    }
    let str: string = ''
    /* if(address.line1) {
      str = address.line1
    }
    if(address.line2) {
      str += ', ' + address.line2
    }
    if(address.postalCode) {
      str += ', ' + address.postalCode
    } */
    str = address.line1.trim()
    str += ', ' + address.line2.trim()
    str += ', ' + address.postalCode.trim()
    return str.trim()
  }

  const updateRfm = async () => {
    setLoading(true)
    const hasError = validateRFM(rfm)

    if (hasError) {
      setLoading(false)
      return
    } else {
      try {
        let addressString = await addAddress()

        handleUpdateRFM({
          ...rfm,
          phoneNumber: `${rfm.countryCode} ${rfm.phoneNumber}`,
          address: addressString
        })
        
        setLoading(false)
        handleClose()
      } catch (e: any) {
        setLoading(false)
        setFailMsg({
          error: true,
          message: e.data.message,
        })
        return false
      }

    }
  }

  return (
    <Card className={styles.card} sx={{ p: 4, width: { xs: "250px", lg: "365px" } }}>
      <Toast {...toast} />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ height: "100px" }}>
            <InputLabel error={errors.email}>
              <span className={styles.mandatory}>Email</span>{" "}
            </InputLabel>
            <CustomTextField error={errors.email} helperText={errors.email ? "Requires a valid email" : ""} sx={{ width: { sm: "250px", lg: "365px" }, mb: 2 }} value={rfm.email} onChange={handleChange} id="email" name="email" placeholder="Email" size="small" className={styles.input} />
          </Grid>
          <Grid item xs={12} sx={{ height: "100px" }}>
            <InputLabel error={errors.firstName}>
              <span className={styles.mandatory}>First Name</span>
            </InputLabel>
            <CustomTextField
              error={errors.firstName}
              helperText={errors.firstName ? "Requires a valid name" : ""}
              sx={{ width: { xs: "250px", lg: "365px" }, mb: 2 }}
              value={rfm.firstName}
              onChange={handleChange}
              id="firstName"
              name="firstName"
              placeholder="First Name"
              size="small"
              className={styles.input}
            />
          </Grid>
          <Grid item xs={12} sx={{ height: "100px" }}>
            <InputLabel error={errors.lastName}>
              {" "}
              <span className={styles.mandatory}>Last Name</span>
            </InputLabel>
            <CustomTextField
              error={errors.lastName}
              helperText={errors.lastName ? "Requires a valid name" : ""}
              sx={{ width: { xs: "250px", lg: "365px" }, mb: 2 }}
              value={rfm.lastName}
              onChange={handleChange}
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              size="small"
              className={styles.input}
            />
          </Grid>
          <Grid item xs={12} sx={{ height: "100px" }}>
            <InputLabel>
              Relationship to Deceased
            </InputLabel>
            <CustomTextField
              error={errors.relationship}
              helperText={errors.relationship ? 'Requires a valid relationship' : ''}
              sx={{ width: { xs: '100%' }, height: 40, mb: 2 }}
              value={rfm.relationship}
              onChange={handleRelationshipChange}
              id='relationship'
              name='relationship'
              placeholder='Relationship'
              size='small'
              className={styles.input}
              type='text'
            />
          </Grid>

          <Grid item xs={12} sx={{ height: "100px" }}>
            <InputLabel>
              Phone Number <span className="optional"></span>
            </InputLabel>
            <Grid container>
              <Grid item xs={12} md={3}>
                <CustomTextField
                  error={errors.countryCode}
                  helperText={errors.countryCode ? 'Requires a valid country code' : ''}
                  sx={{ width: { xs: '100%' }, mb: 4 }}
                  value={rfm.countryCode}
                  onChange={handleChange}
                  id='countryCode'
                  name='countryCode'
                  size='small'
                  type=''
                  className={styles.input}
                  placeholder='65'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >
                        <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>+</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={9}
                sx={{
                  textAlign: 'end'
                }}
              >
                <CustomTextField
                  error={errors.phoneNumber}
                  helperText={errors.phoneNumber ? "Requires a valid phone number" : ""}
                  sx={{ width: { xs: "98%" }, mb: 2 }}
                  value={rfm.phoneNumber}
                  onChange={handleChange}
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder='Phone number'
                  size="small"
                  className={styles.input}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mb: 4 }} className={`${styles.isflex}`} style={{ flexDirection: 'column' }}>
            <InputLabel>Address <span className='optional'></span></InputLabel>
            <CustomTextField sx={{ width: { xs: '365px' }, mb: 1 }} value={address.line1} onChange={handleAddressChange} id='line1' name='line1' placeholder='Address Line 1' size='small' className={styles.input} />

            <CustomTextField sx={{ width: { xs: '365px' }, mb: 1 }} value={address.line2} onChange={handleAddressChange} id='line2' name='line2' placeholder='Address Line 2' size='small' className={styles.input} />

            <CustomTextField sx={{ width: { xs: '200px' }, mb: 4 }} value={address.postalCode} onChange={handleAddressChange} id='postalCode' name='postalCode' placeholder='Postal Code' size='small' className={styles.input} />


          </Grid>
          <Grid item sx={{ mt: 1 }}>
            {
              failMsg.error ? <Box className={styles.error}>{failMsg.message}</Box> : <></>
            }
          </Grid>
          <Grid item xs={12} className={styles.isflex}>
            <Button className="btnSecondary" sx={{ width: { xs: "250px", lg: "365px" }, mb: 2 }} loading={loading} onClick={updateRfm}>
              Update RFM
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
})

export default EditRfm
