import React, { useState, useEffect } from 'react'
import { useGetMeMutation } from '../features/user/userGqlApi'
import { useVerifyTokenQuery,  } from '../features/auth/authRestApi';
import { useLogoutMutation } from '../features/auth/authGqlAPI';

type AuthenticateProps = {
  children: React.ReactNode;
};

const Authenticate: React.FC<AuthenticateProps> = (props) => {
  const [getUser, {data, isSuccess}] = useGetMeMutation()
  let verifyToken = useVerifyTokenQuery('')
  const [logout] = useLogoutMutation()
  
  useEffect(() => {
    getUser(null)
  }, [])

  useEffect(() => {
    if(isSuccess && data.activeAdministrator) {
      verifyVendureToken()
    }
  }, [isSuccess])

  const verifyVendureToken = async () => {
    try {
      let rv = await verifyToken.refetch().unwrap()
      if(rv) {
      }
    }catch(e: any) {
      console.log(e.toString())
      logout(null)
      localStorage.removeItem('vendure-auth-token')
      window.location.href = "./"
    }
  }
  
  if(isSuccess) {
    return (
      <div>{props.children}</div>
    )
  }else {
    return  <></>
  }
  
}

export default Authenticate