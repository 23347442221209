import React, { useState } from 'react'
import styles from './FuneralDirectorTable.module.css'
import Toast from '../base/Toast/Toast'
import { Grid, InputAdornment, Modal, Table, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import CustomSearch from '../base/Textfield/CustomSearch'
import SearchIcon from '@mui/icons-material/Search';
import TableContainer from '@mui/material/TableContainer';
import { StyledTableBody } from '../base/Table/CustomTable'
import Button from '../base/Button/Button'
import { getComparator, stableSort } from '../../constants'
import { Order } from '../../type';
import FuneralDirectorEdit from './FuneralDirectorEdit/FuneralDirectorEdit'
import Pagination from '../base/Pagination/Pagination'

interface DirectorTableProps {
    data: any[]
}

interface TableToolbarProps {
    onRequestSearch: (value: string) => void;
}

function TableToolbar(props: TableToolbarProps) {
    const { onRequestSearch } = props

    return (
        <Grid container spacing={2} justifyContent="end" alignItems="center">
            <Grid item>
                <CustomSearch sx={{ width: { xs: 200, sm: 300 }, mb: 4 }}
                    placeholder='Search'
                    onChange={e => onRequestSearch(e.target.value)}
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </Grid>
    );
}

const FuneralDirectorTable: React.FC<DirectorTableProps> = (props) => {

    const [toast, setToast] = useState({
        message: '',
        type: 'success',
        open: false,
        handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
    })
    const [query, setQuery] = useState('')
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState('name')
    const [selected, setSelected] = useState<any[]>([])

    const isSelected = (id: string | number) => {
        return selected.map(x => x.id).indexOf(id) !== -1
    }

    const filteredItems = props.data.filter((item: any) => {
        // return item.type.toLowerCase().includes(query.toLowerCase()) || item.title.toLowerCase().includes(query.toLowerCase())
        return item.name.toLowerCase().includes(query.toLowerCase())
    })

    const handleSearch = (value: string) => {
        setQuery(value)
    }

    //Sort
    const handleRequestSort = (
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const totalPages = Math.ceil(filteredItems.length / rowsPerPage)
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage - 1);
        // dispatch(setDashboardPage(newPage-1))
    };
    const handleChangeRowsPerPage = (rpp: number) => {
        //let rpp = parseInt(event.target.value, 10)
        setRowsPerPage(rpp);
        setPage(0);
        // updateSettings({ update: { 'dashboard_page': rpp } })
    };

    // Edit Funeral Director
    const handleEditFuneralDirector = (data: any) => {
        setSelectedEdit(data)
        handleOpen()
    }
    const [isOpenEditModal, setOpenEditModal] = useState(false)
    const [selectedEdit, setSelectedEdit] = useState<any>({ id: 0, name: '' })
    const handleOpen = () => setOpenEditModal(true)
    const handleClose = () => {
        setSelectedEdit({ id: 0, name: '' })
        setOpenEditModal(false)
    }

    return (
        <>
            <Toast {...toast} />
            <TableToolbar onRequestSearch={handleSearch} />
            <TableContainer className={styles.outlined}>
                <Table>
                    <TableHead>
                        <TableRow></TableRow>
                        <TableRow className={styles.headerTable}>
                            <TableCell>
                                <TableSortLabel
                                    className={styles.tableheader}
                                    active={orderBy === 'type'}
                                    direction={orderBy === 'type' ? order : 'asc'}
                                    onClick={() => handleRequestSort('name')}
                                    sx={{ paddingLeft: 0 }}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <StyledTableBody>
                        {
                            stableSort(filteredItems, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any) => {
                                    const isItemSelected = isSelected(row.id);

                                    return (
                                        <TableRow
                                            className={styles.tablwRowStyle}
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell sx={{ width: { xs: 200, sm: 300 } }}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell sx={{ width: { xs: 200, sm: 300 } }}></TableCell>
                                            <TableCell sx={{ width: { xs: 200, sm: 300 } }}></TableCell>
                                            <TableCell sx={{ width: { xs: 200, sm: 300 } }}></TableCell>
                                            <TableCell>
                                                <Button
                                                    className='btnTable'
                                                    onClick={() => handleEditFuneralDirector(row)}
                                                >
                                                    Edit
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                        }
                    </StyledTableBody>
                </Table>
            </TableContainer>
            <Pagination
                sx={{ mt: 3 }}
                variant="outlined" shape="rounded"
                count={totalPages}
                page={page + 1}
                onChange={handleChangePage}
                handleViewChange={handleChangeRowsPerPage}
            />

            <Modal
                open={isOpenEditModal}
                onClose={handleClose}
            >
                <FuneralDirectorEdit
                    funeralDirector={selectedEdit}
                    handleCloseModal={handleClose}
                />
            </Modal>
        </>
    )
}

export default FuneralDirectorTable