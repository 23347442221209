import { restApi } from '../../api/restApi'

export const userRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (payload) => ({
        url: `/api/app-template/user`,
        method: 'GET',
        params: payload
      })
    }),
    getActiveUser: builder.query({
      query: () => ({
        url: `/api/app-template/auth/user`,
        method: 'GET',
      })
    }),
  }),
})

export const { useGetUserQuery, useGetActiveUserQuery } = userRestApi