import React, { useState, useEffect} from 'react'
import { useAppSelector } from '../../../app/hooks';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '../../../components/base/Button/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EventTable from '../../../components/Event/EventTable/EventTable';
import styles from './Eventlist.module.css'
import { getAccountState } from '../../../features/account/accountSlice';
import { getEventState } from '../../../features/event/eventSlice';
import { useGetEventlistQuery } from '../../../features/event/eventRestApi';
import { useGetAccountQuery } from '../../../features/account/accountRestApi';

function Event() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { details } = useAppSelector(getAccountState)
  const { eventlist } = useAppSelector(getEventState)
  const [deceased, setDeceased] = useState('')
  useGetEventlistQuery(id, { refetchOnMountOrArgChange: true })
  useGetAccountQuery(id, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    let name = details.lastName
    setDeceased(name)
    
  }, [details])

  const handleNavigate = () => {
    navigate(`/account/event/${id}/create`)
  }

  return (
    <Box sx={{mt:2, px:2}}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <div className={styles.header}>Events</div>
        </Grid>
        <Grid item>
          <Button className='btnSecondary' sx={{my: 0,width: {xs: 200, sm: 300}}} onClick={handleNavigate}>Create Event</Button>
        </Grid>
      </Grid>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link className={styles.link} to="/dashboard">
          Accounts
        </Link>,
        <Box>Account: <span className={styles.name}>{deceased}</span></Box>
        <Box >
          Events List
        </Box>
      </Breadcrumbs>
      <EventTable data={eventlist}/>
    </Box>
  )
}

export default Event