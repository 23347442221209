import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box';
import { Navigate } from "react-router-dom";
import { useAppSelector } from '../app/hooks';
import { getUserState } from '../features/user/userSlice';
import Navbar from '../layouts/Navbar/Navbar';
import Footer from '../layouts/Footer/Footer';

type ProtectedRouteProps = {
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const userState = useAppSelector(getUserState);

  if(!userState.status) {
    return <Navigate to="/login" />;
  }

  if(userState.status) {
    return (
      <Container  maxWidth="xl" style={{minHeight: '750px' }}>
        <Box>
          <Navbar />
          {props.children}
        </Box>
      </Container>
    )
  }

  return <></>
  
}

export default ProtectedRoute