import { restApi } from '../../api/restApi'
import {setId, initialiseAccount } from './accountSlice'

export const accountRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    createAccount: builder.mutation({
      // invalidatesTags: ['accountDetails'],
      query: (payload) => ({
        url: '/api/app-template/account/details',
        method: 'POST',
        body: payload
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setId(data))
        } catch (error) {}
      },
    }),
    updateAccount: builder.mutation({
      invalidatesTags: [`initialiseAccount`, 'dashboard'],
      query: (payload) => ({
        url: '/api/app-template/account/update',
        method: 'POST',
        body: payload,
      }),
    }),
    updateRFM: builder.mutation({
      invalidatesTags: ['initialiseAccount', 'getRfmList'],
      query: (payload) => ({
        url: '/api/app-template/account/update/rfm',
        method: 'POST',
        body: payload,
      })
    }),
    updateObituary: builder.mutation({
      invalidatesTags: [`initialiseAccount`],
      query: (payload) => ({
        url: '/api/app-template/account/update/obituary',
        method: 'POST',
        body: payload
      }),
    }),
    updatePaynow: builder.mutation({
      invalidatesTags: ['initialiseAccount'],
      query: (payload) => ({
        url: '/api/app-template/account/update/paynow',
        method: 'POST',
        body: payload
      }),
    }),
    addMasterProductList: builder.mutation({
      invalidatesTags: ['initialiseAccount'],
      query: (payload) => ({
        url: '/api/app-template/account/master/products',
        method: 'POST',
        body: payload,
      })
    }),
    addEvent: builder.mutation({
      invalidatesTags: (result,  error, id) => {
        if(!error) {
          return ['initialiseAccount', 'getEvent']
        }else {
          return []
        }
      },
      query: (payload) => ({
        url: '/api/app-template/account/event',
        method: 'POST',
        body: payload,
      })
    }),
    addEventProducts: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/account/event/product',
        method: 'POST',
        body: payload,
      })
    }),
    addMessage: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/account/message',
        method: 'POST',
        body: payload
      })
    }),
    getAccount: builder.query({
      providesTags: [`initialiseAccount`],
      query: (id) => ({
        url: `/api/app-template/account/${id}`,
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(initialiseAccount(data))
        } catch (error) {}
      },
    }),
    getRFMlist: builder.query({
      providesTags: ['getRfmList'],
      query: (funeralId) => ({
        url: `/api/app-template/account/rfm/${funeralId}`,
        method: 'GET',
      })
    }),
    getImageUrl: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/account/imageUrl',
        method: 'POST',
        body: payload
      })
    }),
    broadcast: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/broadcast',
        method: 'POST',
        body: payload
      })
    }),
    invitation: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/invitation',
        method: 'POST',
        body: payload
      })
    }),
    completion: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/account/completion',
        method: 'POST',
        body: payload
      })
    }),
    getFuneralMasterProducts: builder.query({
      query: (payload) => ({
        url: '/api/app-template/account/master/products',
        method: 'GET',
        params: payload
      })
    }),
    getBroadcast: builder.query({
      providesTags: ['broadcast'],
      query: (funeralId) => ({
        url: `/api/app-template/broadcast/${funeralId}`,
        method: 'GET',
      })
    }),
    updateBroadcast: builder.mutation({
      invalidatesTags: (result,  error, id) => {
        if(!error) {
          return ['broadcast']
        }else {
          return []
        }
      },
      query: (payload) => ({
        url: '/api/app-template/broadcast/update',
        method: 'POST',
        body: payload,
      })
    }),
  }),
})

export const { 
  useCreateAccountMutation, 
  useUpdateAccountMutation, 
  useUpdateRFMMutation,
  useAddMasterProductListMutation, 
  useAddEventMutation, 
  useAddEventProductsMutation, 
  useGetAccountQuery,
  useAddMessageMutation,
  useUpdateObituaryMutation,
  useUpdatePaynowMutation,
  useGetRFMlistQuery,
  useGetImageUrlMutation,
  useBroadcastMutation,
  useCompletionMutation,
  useGetFuneralMasterProductsQuery,
  useGetBroadcastQuery,
  useUpdateBroadcastMutation,
  useInvitationMutation,
} = accountRestApi