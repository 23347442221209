import React, { useState, useEffect} from 'react'
import { useAppSelector } from '../../../app/hooks';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '../../../components/base/Button/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getAccountState } from '../../../features/account/accountSlice';
import GuestTable from '../../../components/Guestlist/GuestTable';
import { useGetAccountQuery } from '../../../features/account/accountRestApi';
import { useGetSpecificGuestlistQuery, useUpdateEventGuestMutation } from '../../../features/guestlist/guestlistRestApi';
import Toast from '../../../components/base/Toast/Toast';
import styles from './Eventlist.module.css'
import RSVPTable from '../../../components/Guestlist/RSVPTable';

function EventRsvp() {
  const { id, eventId } = useParams()
  useGetAccountQuery(id, { refetchOnMountOrArgChange: true })
  const { details } = useAppSelector(getAccountState)
  const [deceased, setDeceased] = useState('')
  const [guestlist, setGuestlist] = useState([])
  const [rsvplist, setRsvplist] = useState([])
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => {setToast(prev => ({...prev, open: false}))}
  })
  const {data, isLoading} = useGetSpecificGuestlistQuery({eventId:eventId, funeralId:id}, { refetchOnMountOrArgChange: true})
  const [updateEventGuest] = useUpdateEventGuestMutation()

  useEffect(() => {
    let name = details.lastName
    setDeceased(name)
    
  }, [details])

  useEffect(() => {
    if(data && !isLoading) {
      setGuestlist(data.invites)
      setRsvplist(data.rsvplist)
    }
  }, [data, isLoading])

  const handleGuestDelete = async (item: any) => {
    try {
      let body = {
        type: 'delete',
        eventId: eventId,
        funeralId: id,
        guest: item
      }

      let rv = await updateEventGuest(body).unwrap()
      

      if(rv) {
        setToast({
          ...toast,
          open: true,
          message: rv.message,
          type: 'success'
        })
      }
      
    }catch(e: any) {
      setToast({
        ...toast,
        open: true,
        message: e.data.message,
        type: 'Error'
      })
      console.error('rejected', e);
    }
  }

  return (
    <Box sx={{mt:2, px:2}}>
    <Toast {...toast}/>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <div className={styles.header}>Event RSVP</div>
        </Grid>
      </Grid>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{mb:4}}
      >
        <Link className={styles.link} to="/dashboard">
          Accounts
        </Link>,
        <Box>Account: <span className={styles.name}>{deceased}</span></Box>
        <Link className={styles.link} to={`/account/event/${id}`}>
          Events List
        </Link>
        <Box>RSVP</Box>
      </Breadcrumbs>
      <RSVPTable data={rsvplist} />
    </Box>
  )
}

export default EventRsvp