import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';
import { approval, message } from '../../type';

type messageCount = {
  funeralId: number | string
  count: number | string
}

export interface ChecklistState {
  approval: approval[]
  messageCount: messageCount[]
  messageList: message[]
  rfmlist: any[]
}

const initialState: ChecklistState  = {
  approval: [],
  messageCount: [],
  messageList: [],
  rfmlist: []
  
};

export const approvalSlice = createSlice({
  initialState,
  name: 'approval',
  reducers: {
    setApproval: (state, {payload}) => {
      state.approval = payload.approveTable
      state.messageCount = payload.messageCount
      state.rfmlist = payload.rfmlist
    },
    setMsgList: (state, {payload}) => {
      state.messageList = payload
    },
  },
})

export const getApprovalState = (state: RootState) => state.approval;

export default approvalSlice.reducer;

export const { setApproval, setMsgList } = approvalSlice.actions;