import React from 'react'

function ViewpgIcon() {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.6072 5.19517C1.6072 5.2557 1.6072 5.30601 1.6072 5.35591C1.6072 7.68208 1.6068 10.0083 1.60761 12.3344C1.60802 12.9324 1.84199 13.4036 2.37291 13.7059C2.58316 13.8253 2.81426 13.8601 3.05232 13.8601C3.61474 13.8597 4.17716 13.8572 4.73958 13.8613C5.05208 13.8638 5.26109 14.0761 5.2525 14.3677C5.24555 14.6078 5.07744 14.8242 4.82343 14.8258C4.05813 14.8312 3.28383 14.8741 2.52875 14.7776C1.52867 14.6495 0.752326 13.7444 0.649659 12.7422C0.636161 12.6101 0.626344 12.4772 0.626344 12.3446C0.625117 9.21105 0.62389 6.07704 0.626753 2.94303C0.627162 2.0497 0.971159 1.3249 1.71928 0.814426C2.09723 0.556735 2.52344 0.440161 2.97869 0.439752C6.13847 0.438116 9.29784 0.435252 12.4576 0.44057C13.6782 0.442615 14.7584 1.32694 14.9887 2.49473C15.0173 2.63912 15.0272 2.78923 15.0276 2.9369C15.0304 4.27116 15.0272 5.60583 15.0312 6.9401C15.0321 7.20188 14.9212 7.38022 14.6881 7.48534C14.4586 7.58842 14.2488 7.54015 14.0598 7.38513C13.9281 7.27714 13.8778 7.13357 13.8786 6.96464C13.881 6.4329 13.8794 5.90116 13.8794 5.36941C13.8794 5.31419 13.8794 5.25897 13.8794 5.19517C9.78704 5.19517 5.70898 5.19517 1.6072 5.19517ZM13.8778 4.19794C13.8778 3.75251 13.8831 3.32343 13.8765 2.89436C13.8639 2.04275 13.2327 1.42102 12.3823 1.42102C9.27534 1.4202 6.16792 1.4202 3.06091 1.42102C2.30829 1.42102 1.69433 1.95031 1.63093 2.70047C1.59003 3.18763 1.61579 3.68052 1.61252 4.17054C1.61252 4.17872 1.62316 4.1869 1.63093 4.19794C5.70612 4.19794 9.78254 4.19794 13.8778 4.19794Z" fill="#545454" />
      <path d="M9.84599 14.8098C7.77546 14.7869 6.30336 13.7929 5.32659 12.0087C5.16788 11.7191 5.05131 11.4058 4.9286 11.0982C4.90078 11.0283 4.90528 10.9244 4.93637 10.8548C5.79043 8.96757 7.23636 7.83087 9.29093 7.54823C11.6866 7.21896 14.0378 8.60394 14.9695 10.8385C15.0031 10.9191 15.0104 11.032 14.9826 11.1138C14.2971 13.1315 12.9366 14.3742 10.8093 14.7288C10.6735 14.7513 10.5364 14.7701 10.399 14.7807C10.2153 14.7951 10.0313 14.8004 9.84599 14.8098ZM10.2472 8.51027C8.31784 8.50864 6.75861 9.49563 6.03994 11.0091C5.99494 11.1039 5.98349 11.1817 6.03462 11.2798C6.86823 12.8877 8.18818 13.777 9.99774 13.7753C11.8008 13.7737 13.1166 12.8857 13.9474 11.2835C13.9973 11.187 13.9948 11.1084 13.9457 11.0127C13.1187 9.40278 11.7864 8.59372 10.2472 8.51027Z" fill="#545454" />
      <path d="M8.50217 2.40234C8.8523 2.40275 9.10549 2.63999 9.10958 2.97213C9.11367 3.30712 8.85475 3.55295 8.49726 3.55295C8.14713 3.55295 7.89435 3.3149 7.89066 2.98276C7.88698 2.64613 8.14426 2.40234 8.50217 2.40234Z" fill="#545454" />
      <path d="M10.3395 2.40235C10.6917 2.40358 10.9342 2.64081 10.9322 2.98154C10.9301 3.32717 10.6851 3.55418 10.3154 3.55255C9.964 3.55132 9.72062 3.31326 9.72267 2.97295C9.72512 2.62895 9.97095 2.40112 10.3395 2.40235Z" fill="#545454" />
      <path d="M12.0511 3.55254C11.6883 3.55254 11.4421 3.31612 11.4453 2.97049C11.4482 2.63263 11.6936 2.40234 12.0499 2.40234C12.4103 2.40234 12.6606 2.64245 12.6561 2.9844C12.6516 3.32144 12.4058 3.55254 12.0511 3.55254Z" fill="#545454" />
      <path d="M9.99825 13.1241C8.92045 13.1303 8.02549 12.2398 8.02344 11.1583C8.02099 10.0842 8.89877 9.19906 9.9778 9.1876C11.056 9.17656 11.951 10.0601 11.9608 11.1452C11.9702 12.2226 11.0826 13.1176 9.99825 13.1241ZM9.99743 12.1077C10.5234 12.1032 10.9533 11.6676 10.9452 11.1461C10.937 10.6245 10.514 10.2049 9.99416 10.2028C9.46978 10.2008 9.03743 10.6331 9.03907 11.1571C9.03989 11.6823 9.47346 12.1122 9.99743 12.1077Z" fill="#545454" />
    </svg>
  )
}

export default ViewpgIcon