import React, { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styles from './DashboardTable.module.css'

interface RfmModalProps {
  children?: React.ReactNode;
  handleClose: () => void;
  rfmlist: any[]
  deceased: string
}

const RfmModal = React.forwardRef<'',RfmModalProps>((props,ref) => {
  const { handleClose, rfmlist, deceased } = props
  console.log(props)
  return (
    <Box className={styles.rfmModal} >
      <Card className={styles.card}>
        <Typography component={'h4'} className='sectionTitle'>RFMs</Typography>
        <Typography><span className='text'>Account:</span> <span className='textBold'>{deceased}</span></Typography>
        <Grid container spacing={2} sx={{mt:2}}>
          {rfmlist &&
            rfmlist.map((item, index) => {
              return (
                <Grid key={index} item xs={6} md={6} sx={{my:2}}>
                  <Typography className={styles.bodyText}>{item.lastName}</Typography>
                  {
                    item.phoneNumber && (
                      <Typography className={styles.bodyText}>+65 {item.phoneNumber}</Typography>
                    )
                  }
                </Grid>
              )
            })
          }

        </Grid>
      </Card>
    </Box>
  )
})

export default RfmModal