import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "../../app/hooks"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import MuiLink from "@mui/material/Link"
import Badge from "@mui/material/Badge"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import CustomTextField from "../base/Textfield/CustomTextField"
import { SelectChangeEvent } from "@mui/material/Select"
import { CopyToClipboard } from "react-copy-to-clipboard"

import IconButton from "@mui/material/IconButton"
import { useGetImageUrlMutation, useBroadcastMutation, useGetBroadcastQuery, useUpdateBroadcastMutation, useInvitationMutation } from "../../features/account/accountRestApi"
import Button from "../base/Button/Button"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import EditIcon from "../base/Icons/EditIcon"
import styles from "./Share.module.css"
import Toast from "../base/Toast/Toast"
import { formatDate } from "../../constants"

interface ShareProps {
  account: any
  eventlist: any
}

interface EmailEditContentProps {
  account: any
  deceased: any
  imageUrl: any
  share: string
  isEdit: boolean
  handleSave: (email: any) => void
}

interface PreviewEmailProps {
  deceased: any
  email: any
  share: string
  imageURL: string
  handleClose: () => void
}

const EmailContent = (props: EmailEditContentProps) => {
  const { account, deceased, imageUrl, isEdit, share, handleSave } = props
  const [preview, setPreview] = useState(false)
  const [email, setEmail] = useState({
    subject: "",
    message: ""
  })
  const getBroadcast: any = useGetBroadcastQuery(account.id, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (getBroadcast.isSuccess && getBroadcast.data) {
      let text = getBroadcast.data?.message.replace(/<br \/>?/g, "\n")
      setEmail({
        ...email,
        subject: getBroadcast.data?.subject,
        message: text
      })
    } else {
    }
  }, [getBroadcast])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    if (key === "message") {
      value = value.replace(/\n\r?/g, "<br />")
      setEmail({
        ...email,
        message: value
      })
    } else {
      setEmail({
        ...email,
        subject: value
      })
    }
  }

  if (isEdit) {
    return (
      <Box sx={{ mt: 4 }}>
        <Box>
          <InputLabel>Subject</InputLabel>
          <CustomTextField className="text-input" sx={{ width: { xs: "300px", lg: "100%" }, mb: 2 }} value={email.subject} onChange={handleChange} id="subject" name="subject" size="small" />
        </Box>
        <Box sx={{ mt: 4 }}>
          <InputLabel>Message</InputLabel>
          <CustomTextField className="text-input" sx={{ width: { xs: "300px", lg: "100%" }, mb: 4 }} multiline rows={6} value={email.message.replace(/<br \/>?/g, "\n")} onChange={handleChange} id="message" name="message" size="small" />
        </Box>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid></Grid>
          <Grid item>
            <MuiLink sx={{ mr: 2, cursor: "pointer", color: "#545454", textDecoration: "underline" }} onClick={() => setPreview(true)}>
              Preview email
            </MuiLink>
            <Button className="btnPrimary" sx={{ width: "300px" }} onClick={() => handleSave(email)}>
              Save
            </Button>
          </Grid>
        </Grid>

        <Dialog open={preview} fullWidth={true} scroll={"paper"} onClose={() => setPreview(false)}>
          <DialogContent>
            <Box sx={{ mb: 4 }} style={{ display: "flex", justifyContent: "center" }}>
              <img src={`/images/TLC-MAIN-LOGO.png`} width={"60%"} alt="img" loading="lazy" />
            </Box>

            {imageUrl ? (
              <Box sx={{ mb: 4 }} style={{ display: "flex", justifyContent: "center" }}>
                <img src={`${imageUrl}`} width={"100%"} alt="img" loading="lazy" />
              </Box>
            ) : (
              <></>
            )}
            <Box sx={{ pb: 2 }}>
              <Box className={styles.nameBox}>
                <div className={styles.deceasedName}>{deceased.name}</div>
                <div className={styles.deceasedLifespan}>{deceased.lifespan}</div>
              </Box>
            </Box>
            <Box style={{ width: "100%", margin: "auto" }}>
              <Box sx={{ pb: 4, width: "100%" }}>
                <div className={styles.emailSubj}>{email.subject}</div>
                <p className={styles.emailBody} style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: email.message }}></p>
              </Box>
              <MuiLink href={share} target="_blank" sx={{ textDecoration: "none" }}>
                <Button className="btnPrimary" sx={{ width: { xs: "300px" } }}>
                  Visit
                </Button>
              </MuiLink>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
  } else {
    return (
      <Box sx={{ py: 4 }} className={styles.emailContainer}>
        {/* <Box sx={{mb:4}} style={{display:'flex', justifyContent: 'center'}}>
          <img 
            src={`/images/TLC-MAIN-LOGO.png`}
            width={'60%'}
            alt="img"
            loading="lazy"
          />
        </Box> */}

        <Box sx={{ pb: 4, width: "80%" }}>
          <div className={styles.emailSubj}>{email.subject}</div>
          <p className={styles.emailBody} style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: email.message }}></p>
        </Box>
        <MuiLink href={share} target="_blank" sx={{ textDecoration: "none" }}>
          <Button className="btnPrimary" sx={{ width: { xs: "300px" } }}>
            Visit
          </Button>
        </MuiLink>
      </Box>
    )
  }
}

const Share: React.FC<ShareProps> = (props) => {
  const { id } = useParams()
  const { account, eventlist } = props
  const dispatch = useAppDispatch()
  const [share, setShare] = useState("")
  const [events, setEventlist] = useState<any[]>(eventlist)
  const [selectedEvent, setEvent] = useState<any>(events[0])
  const [imageURL, setImageUrl] = useState("")
  const [deceased, setDeceased] = useState({
    name: "",
    lifespan: ""
  })
  console.log("deceased: ", deceased)
  const [email, setEmail] = useState({
    subject: "",
    message: ""
  })
  const [toast, setToast] = useState({
    type: "success",
    open: false,
    message: "",
    handleClose: () => {
      setToast((prev) => ({ ...prev, open: false }))
    }
  })
  const [preview, setPreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [isEdit, setEdit] = useState(false)
  const getBroadcast = useGetBroadcastQuery(account.id, { refetchOnMountOrArgChange: true })
  const [getImageUrl] = useGetImageUrlMutation()
  const [broadcast] = useBroadcastMutation()
  const [updateBroadcast] = useUpdateBroadcastMutation()
  const [sendInvitation] = useInvitationMutation()

  useEffect(() => {
    if (getBroadcast.isSuccess && getBroadcast.data) {
      let text = getBroadcast.data?.message.replace(/<br \/>?/g, "\n")
      setEmail({
        subject: getBroadcast.data?.subject,
        message: text
      })
    }
  }, [getBroadcast])

  useEffect(() => {
    let sharelink = `${process.env.REACT_APP_URL}${account.lastName}/${account.id}`
    setShare(sharelink)
    if (account.profile_image) {
      getURL(account.profile_image)
    }
    let user = {
      firstName: account.firstName,
      lastName: account.lastName,
      dob: account.dob,
      dod: account.dod
    }
    let name = user.lastName.charAt(0).toUpperCase() + user.lastName.substring(1)
    let lifespan = formatDate(user.dob, "DD MMM YYYY") + " - " + formatDate(user.dod, "DD MMM YYYY")
    setDeceased({
      name: name,
      lifespan: lifespan
    })
  }, [account.firstName, account.lastName, account.id])

  useEffect(() => {
    if (eventlist) {
      setEventlist([{ id: 0, type: "Master", startDate: null }, ...eventlist])
    }
  }, [eventlist])

  const getURL = async (filename: string) => {
    try {
      let rv = await getImageUrl({ filename: filename }).unwrap()
      setImageUrl(rv.imageUrl)
    } catch (e) { }
  }

  const handleEventChange = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event
    setEvent(value)
  }

  const handleEdit = () => {
    setEdit(true)
  }

  /* const handleEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    if(key === 'message') {
      value = value.replace(/\n\r?/g, '<br />');
      setEmail({
        ...email,
        message: value
      })
    }else {
      setEmail({
        ...email,
        subject: value
      })
    }
  } */

  const handleSaveEdit = async (email: any) => {
    try {
      let body = {
        funeralId: id || account.id,
        ...email
      }
      let rv = await updateBroadcast(body).unwrap()
      if (rv) {
      }
    } catch (e) { }
    setEdit(false)
  }

  const handleCopy = async () => {
    // if ("clipboard" in navigator) {
    //   await navigator.clipboard.writeText(share);
    // } else {
    //   document.execCommand("copy", true, share);
    // }

    setToast({
      ...toast,
      open: true,
      message: "Link copied",
      type: "success"
    })
  }

  const handleSend = async () => {
    try {
      let body = {
        ...email,
        ...deceased,
        filename: account.profile_image,
        href: share,
        funeralId: account.id,
        eventId: selectedEvent.id
      }

      let rv = await broadcast(body).unwrap()

      if (rv) {
        setToast({
          ...toast,
          open: true,
          type: "success",
          message: rv.message
        })
      }
    } catch (e: any) {
      setToast({
        ...toast,
        open: true,
        type: "error",
        message: e.data.message
      })
    }
  }

  const handleSendInvitation = async () => {
    try {
      setIsLoading(true)
      let body = {
        ...email,
        ...deceased,
        filename: account.profile_image,
        href: `${process.env.REACT_APP_SHOP_URL}${account.lastName}/${account.id}/about`,
        funeralId: account.id
      }

      let rv = await sendInvitation(body).unwrap()

      if (rv) {
        setToast({
          ...toast,
          open: true,
          type: "success",
          message: rv.message
        })
        setIsLoading(false)
      }
    } catch (e: any) {
      setIsLoading(false)
      setToast({
        ...toast,
        open: true,
        type: "error",
        message: "Error while sending invitation"
      })
    }
  }

  return (
    <Box>
      <Toast {...toast} />
      <Grid container spacing={2} sx={{ py: 2 }} justifyContent={"space-between"}>
        <Grid item xs={12} md={6}>
          {/* <Box style={{position: 'relative'}}>
            {
              imageURL ? 
              <Box style={{backgroundImage: `url(${imageURL})`, backgroundSize: 'cover',width:'100%', height:'500px'}}>
                <Box className={styles.absBox}>
                  <Box className={styles.nameBox}>
                    <div className={styles.deceasedName}>{deceased.name}</div>
                    <div className={styles.deceasedLifespan}>{deceased.lifespan}</div>
                  </Box>
                </Box>
              </Box> : 
              <Box>
                  <Box className={styles.nameBox}>
                    <div className={styles.deceasedName}>{deceased.name}</div>
                    <div className={styles.deceasedLifespan}>{deceased.lifespan}</div>
                  </Box>
              </Box>
            }
          </Box> */}
          {imageURL ? (
            <Box sx={{ mb: 4 }} style={{ display: "flex", justifyContent: "center" }}>
              <img src={`${imageURL}`} width={"100%"} alt="img" loading="lazy" />
            </Box>
          ) : (
            <>
              <Box>
                <Box className={styles.nameBox}>
                  <div className={styles.deceasedName}>{deceased.name}</div>
                  <div className={styles.deceasedLifespan}>{deceased.lifespan}</div>
                </Box>
              </Box>
            </>
          )}
          <Box>
            <Box className={styles.nameBox}>
              <div className={styles.deceasedName}>{deceased.name}</div>
              <div className={styles.deceasedLifespan}>{deceased.lifespan}</div>
            </Box>
          </Box>

          <EmailContent account={account} deceased={deceased} imageUrl={imageURL} isEdit={isEdit} share={share} handleSave={handleSaveEdit} />
        </Grid>
        {!isEdit && (
          <Grid item xs={12} md={4}>
            <InputLabel>Share Message</InputLabel>
            <CustomTextField
              disabled
              sx={{ width: "100%", mb: 4 }}
              size="small"
              value={share.replaceAll(' ', '%20')}
              className={styles.shareLink}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard text={share.replaceAll(' ', '%20')} onCopy={() => handleCopy}>
                      <IconButton onClick={handleCopy}>
                        <ContentCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                )
              }}
            />
            {/* <InputLabel sx={{ mt: 4 }}>Broadcast</InputLabel>
              <div>Broadcast this event to this guest list</div>
              <CustomSelect
                className={styles.input}
                sx={{ width: '100%', mb: 4 }}
                size='small'
                labelId="action"
                value={selectedEvent || ""}
                onChange={handleEventChange}
              >
                {events.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item}
                  >
                    {item.type + ' ' + formatDate(item.startDate, 'DD MMM YYYY')}
                  </MenuItem>
                ))}
              </CustomSelect>
              <Button className='btnTransparent' sx={{ width: '300px', mt: 4 }} onClick={handleSend}>Send</Button> */}
            <Button loading={isLoading} disabled={isLoading} className="btnTransparent" sx={{ width: "300px" }} onClick={handleSendInvitation}>
              Send
            </Button>
          </Grid>
        )}

        {isEdit ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <MuiLink className={styles.link} onClick={handleEdit} style={{ cursor: "pointer", color: "var(--TLC-gray)", textDecorationColor: "var(--TLC-gray)" }}>
              Edit
              <Badge badgeContent={0} sx={{ ml: 1 }}>
                <EditIcon />
              </Badge>
            </MuiLink>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default Share
