import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks';
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '../../components/base/Button/Button';
import Grid from '@mui/material/Grid';
import { InputLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './Login.module.css'
import { useLoginMutation } from '../../features/auth/authGqlAPI'
import { useBackendLoginMutation, useCheckAdminEmailMutation } from '../../features/auth/authRestApi';
import { getUserState } from '../../features/user/userSlice'
import { debounce, validateEmail } from '../../constants';
import Toast from '../../components/base/Toast/Toast';
import CustomFailMsg from '../../components/base/Toast/CustomFailMsg';
import CustomTextField from '../../components/base/Textfield/CustomTextField';

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement,
  passowrd: HTMLInputElement,
}

interface LoginFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

function Login() {
  const user = useAppSelector(getUserState);
  const [toast, setToast] = useState({
    open: false,
    message: '',
    type: 'success',
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    email: false,
    password: false
  })
  const [loginFail, setLoginFail] = useState({
    error: false,
    message: ''
  })
  const [loginForm, setLoginForm] = useState<any>({ email: '', password: '' })
  const navigate = useNavigate()
  const [loginUser, result0] = useLoginMutation();
  const [backendLogin, result] = useBackendLoginMutation()
  const [checkAdminEmail] = useCheckAdminEmailMutation()

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);

  const validateSingleField = (obj: { key: string, value: string }) => {
    const { key, value } = obj
    let rv
    if (key === 'email') {
      rv = validateEmail(value)
    }
    if (key === 'password') {
      value ? rv = true : rv = false
    }

    if (!rv) {
      setErrors(prev => ({ ...prev, [key]: true }))
    } else {
      setErrors(prev => ({ ...prev, [key]: false }))
    }
  }

  const debounceHandler = useCallback(
    debounce(validateSingleField)
    , []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    setLoginForm({
      ...loginForm,
      [key]: value
    })

    debounceHandler({ key, value })
  }

  const validate = () => {
    let hasError = false
    for (const [key, value] of Object.entries(loginForm)) {
      switch (key) {
        case "email":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.email ? hasError = true : setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        case "password":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.password ? hasError = true : setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        default:
          break;
      }
    }

    return hasError

  }

  //User state
  useEffect(() => {
    if (user.status) {
      navigate('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.status]);

  //Backend login useeffect
  /* useEffect(() => {
    if(loginForm.email && loginForm.password) {
      backendLogin(loginForm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginForm]) */

  useEffect(() => {
    if (result.isSuccess && result.data.user_meta) {
      loginUser(loginForm)
    }
    if (result.isError && result.status === 'rejected') {
      /* setToast({
        ...toast,
        open: true,
        message: 'Incorrect Credentials!',
        type: 'error'
      }) */
      setLoginFail({
        error: true,
        message: 'Incorrect Credentials!'
      })
      setLoading(false)
      /* setTimeout(() => {
        setLoginFail({
          error: false,
          message: ''
        })
      }, 3000) */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  useEffect(() => {
    if (result0.isSuccess) {
    } else {
      setLoading(false)
    }
  }, [result0.isSuccess])

  const handleSubmit = async () => {
    setLoading(true)
    const hasError = validate()
    if (hasError) {
      setLoading(false)
      return
    }
    try {
      let rv = await checkAdminEmail({ email: loginForm.email }).unwrap()
      if (rv.id) {
        loginUser(loginForm)
        //backendLogin(loginForm)
        /* setTimeout(() => {
          setLoading(false)
        },2000) */
      } else {
        setLoginFail({
          error: true,
          message: "Incorrect credentials"
        })
        setLoading(false)
      }


    } catch (e: any) {
      console.log('error login:', e)
      console.log(e.toString())
      setLoading(false)
    }

  };


  return (
    <div className={styles.loginContainer}>
      <Toast {...toast} />
      <Box>
        <img
          src={`/images/TLC-MAIN-LOGO.png`}
          alt="The Life Celebrant"
          width={250}
          loading="lazy"
        />
      </Box>
      <Card className={styles.card} sx={{ width: { xs: '90%', md: '38%' } }}>
        <div className={styles.login}>Admin Login</div>
        <CustomFailMsg error={loginFail.error} message={loginFail.message} />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <CustomTextField sx={{ height: 70 }} variant='outlined' error={errors.email} helperText={errors.email ? 'Requires a valid email' : ''} value={loginForm.email} id="email" name="email" placeholder='Email' onChange={handleChange} fullWidth className={styles.input} />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <CustomTextField sx={{ mb: 1 }} id="password" name="password" placeholder='Password' onChange={handleChange} fullWidth
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton
                    className={styles.icon}
                    aria-label="toggle password visibility"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? <VisibilityOffIcon sx={{ color: 'var(--TLC-web-grey)' }} /> : <VisibilityIcon sx={{ color: 'var(--TLC-web-grey)' }} />}
                  </IconButton>
                )
              }} />
          </Grid>
        </Grid>

        <Grid container sx={{ mb: 2 }}>
          <Grid item xs>
            {<Link to="/request-reset" className={styles.link}>
              Forgot password
            </Link>}
          </Grid>
        </Grid>

        <Button fullWidth loading={loading} variant="contained" className="btnPrimary" sx={{ mt: 2 }} onClick={handleSubmit}>Login</Button>

      </Card>
      <br />
      <p className={styles.notice}> &#169; {new Date().getFullYear()} The Life Celebrant. All Rights Reserved. Powered by
        <a href="https://www.visiongroup.co/" target="_blank" rel="noopener noreferrer"> VisionTech</a>.</p>
    </div>
  )
}

export default Login