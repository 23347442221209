import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import userReducer from '../features/user/userSlice'
import authReducer from '../features/auth/authSlice'
import dashboardReducer from '../features/dashboard/dashboardSlice';
import checklistReducer from '../features/checklist/checklistSlice'
import approvalReducer from '../features/approval/approvalSlice'
import eventReducer from '../features/event/eventSlice'
import productReducer from '../features/product/productSlice'
import accountReducer from '../features/account/accountSlice'
import guestlistReducer from '../features/guestlist/guestlistSlice'
import { restApi } from '../api/restApi';
import { authGqlApi } from '../features/auth/authGqlAPI';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    auth: authReducer,
    funeral: dashboardReducer,
    checklist: checklistReducer,
    approval: approvalReducer,
    event: eventReducer,
    product: productReducer,
    account: accountReducer,
    guestlist: guestlistReducer,
    [restApi.reducerPath]: restApi.reducer,
    [authGqlApi.reducerPath]: authGqlApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([restApi.middleware, authGqlApi.middleware]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
