import React from 'react'
import { useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ApprovalTable from '../../components/Approval/ApprovalTable';
import { getApprovalState } from '../../features/approval/approvalSlice';
import { useGetApprovalsQuery } from '../../features/approval/approvalRestApi';
import styles from './Approval.module.css'

function Approval() {
  useGetApprovalsQuery(null, { refetchOnMountOrArgChange: true })
  const approval = useAppSelector(getApprovalState)

  return (
    <Box sx={{ mt: 2, px: 2 }}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <div className={styles.header}>Approval</div>
        </Grid>
      </Grid>
      <ApprovalTable
        data={approval.approval}
        messageCount={approval.messageCount}
        rfmlist={approval.rfmlist}
      />
    </Box>
  )
}

export default Approval