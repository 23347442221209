import React, { useState, useEffect, useCallback } from 'react'
import { useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import InputLabel from '@mui/material/InputLabel';
import CustomTextField from '../base/Textfield/CustomTextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../base/Select/CustomSelect';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import { StyledTableCell, StyledTableBody } from '../base/Table/CustomTable';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '../base/Button/Button';
import EditIcon from '../base/Icons/EditIcon';
import EditRfm from './EditRfm';
import { getAccountState } from '../../features/account/accountSlice';
import { useUpdateRFMMutation, useGetRFMlistQuery } from '../../features/account/accountRestApi';
import styles from './Rfm.module.css'
import Toast from '../base/Toast/Toast';
import { relationships, debounce, validateName, validateEmail, validateTel, validatePostal, validateCountryCode } from '../../constants';
import { Tooltip } from '@mui/material';
import countryCode from '../../constants/phoneCountryCode.json'

type RFM = {
  firstName: string,
  lastName: string,
  relationship: string,
  email: string,
  phoneNumber: string,
  address: string
}

interface RfmFormProps {
  onSave: (rfmList: RFM[]) => void
  errorMsg?: object
}

const RfmForm: React.FC<RfmFormProps> = (props) => {
  const { id } = useParams()
  const accountState = useAppSelector(getAccountState)
  const funeralId = id || accountState.details.id
  const [rfmList, setRfmList] = useState<any[]>([])
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    relationship: '',
    email: '',
    phoneNumber: '',
    countryCode: '',
    address: '',
  })
  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    postalCode: ''
  })
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    phoneNumber: false,
    countryCode: false,
    email: false,
    relationship: false
  })
  const [failMsg, setFailMsg] = useState<any>({
    error: false,
    message: ''
  })

  //Edit RFM
  const [isOpen, setOpenEditModal] = useState(false)
  const [selectedEdit, setSelectedEdit] = useState<any>()
  const handleOpen = () => setOpenEditModal(true);
  const handleClose = () => setOpenEditModal(false);

  const [updateRFM] = useUpdateRFMMutation()
  const rfmQuery = useGetRFMlistQuery(funeralId, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (funeralId) {
      getRFM()
    } else {
      setRfmList(accountState.details.rfmList)
    }

  }, [accountState])

  useEffect(() => {
    setFailMsg(props.errorMsg)
  }, [props.errorMsg])

  useEffect(() => {
    if (rfmQuery.data && !rfmQuery.isLoading) {
      setRfmList(rfmQuery.data.rfmList)
    }
  }, [rfmQuery])

  /* useEffect(() => {
    if(failMsg.error) {
      setTimeout(() => {
        setFailMsg({
          error: false,
          message: ''
        })
      }, 5000)
    }
  }, [failMsg]) */

  const getRFM = async () => {
    try {
      let data = await rfmQuery.refetch().unwrap()
      if (data) {
        setRfmList(data.rfmList)
      }
    } catch (e: any) {
      console.log(e.toString())
    }
  }

  const reset = () => {
    setForm({
      firstName: '',
      lastName: '',
      relationship: '',
      email: '',
      phoneNumber: '',
      countryCode: '',
      address: '',
    })
    setAddress({
      line1: '',
      line2: '',
      postalCode: ''
    })
  }

  const checkIfAdded = async (email: string) => {
    //check if added
    let found = rfmList.find((item) => {
      return item.email === email.trim()
    })
    if (found) {
      return true
    }
  }

  const addAddress = async () => {
    if (address.line1 || address.line2 || address.postalCode) {
      let rv = validatePostal(address.postalCode)
      if (!rv) {
        setFailMsg({
          error: true,
          message: "Please enter a valid Postal Code!"
        })
        throw new Error()
      } else {
        setFailMsg({
          error: false,
          message: ""
        })
      }
    }
    let str: string = ''
    /* if(address.line1) {
      str = address.line1
    }
    if(address.line2) {
      str += ', ' + address.line2
    }
    if(address.postalCode) {
      str += ', ' + address.postalCode
    } */
    str = address.line1.trim()
    str += ', ' + address.line2.trim()
    str += ', ' + address.postalCode.trim()
    return str.trim()
  }

  const addNewRfm = async (showError = true) => {
    let oldList = rfmList
    let rv, addressString: any
    const hasError = validateRFM(form, showError)
    if (hasError) {
      return false
    }
    if (form.email) {
      //check if added
      let found = await checkIfAdded(form.email)
      if (found) {
        setFailMsg({
          error: true,
          message: 'Duplicate User!',
        })

        return false
      }
      try {
        addressString = await addAddress()

        let updateRFMBody: any = { type: 'add', funeralId: id || accountState.details.id, details: { ...form, phoneNumber: `${form.countryCode} ${form.phoneNumber}`, address: addressString } }

        delete updateRFMBody.details.countryCode

        console.log('updateRFMBody:', updateRFMBody)

        if (id || accountState.details.id) {
          console.log('updating rfm...')
          rv = await updateRFM(updateRFMBody)
        }

        if (rv) {
          setToast({
            ...toast,
            open: true,
            message: 'RFM added/updated!',
            type: 'success'
          })

        }
      } catch (e: any) {
        console.log('add new rfm error:', e)
        setFailMsg({
          error: true,
          message: e.data.message,
        })
        return false
      }

      let newDetails: any = { ...form, phoneNumber: `${form.countryCode} ${form.phoneNumber}`, address: addressString }

      delete newDetails.countryCode

      let newlist = [...oldList, newDetails]
      setRfmList(newlist)
      reset()
      return newlist
    }

  }

  const validateSingleField = (obj: { key: string, value: string }) => {
    const { key, value } = obj
    let rv
    if (key === 'firstName' || key === 'lastName' || key === 'relationship') {
      if (value) {
        rv = validateName(value)
      } else rv = true
    }
    if (key === 'phoneNumber') {
      if (value) {
        rv = validateTel(value)
      } else rv = true
    }
    if (key === 'countryCode') {
      if (value) {
        rv = validateCountryCode(value)
      } else rv = true
    }
    if (key === 'email') {
      if (value) {
        rv = validateEmail(value)
      } else rv = true

    }

    if (!rv) {
      setErrors(prev => ({ ...prev, [key]: true }))
    } else {
      setErrors(prev => ({ ...prev, [key]: false }))
    }

  }

  const debounceHandler = useCallback(
    debounce(validateSingleField)
    , []);

  //Check if all required inputs are filled
  const validateRFM = (item: any, showError: boolean) => {
    let hasError = false
    for (const [key, value] of Object.entries<string>(item)) {
      switch (key) {
        case "firstName":
          if (!value.trim()) {
            if (showError) setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateName(value)
            if (rv) {
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        case "lastName":
          if (!value.trim()) {
            if (showError) setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateName(value)
            if (rv) {
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        case "relationship":
          if (!value.trim()) {
            if (showError) setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateName(value)
            if (rv) {
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        case "email":
          if (!value.trim()) {
            if (showError) setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let rv = validateEmail(value)
            if (rv) {
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        case "phoneNumber":
          if (value.trim()) {
            let rv = validateTel(value)
            if (rv) {
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        case "countryCode":
          if (value.trim()) {
            let rv = validateCountryCode(value)
            if (rv) {
              setErrors(prev => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors(prev => ({ ...prev, [key]: true }))
            }
          }
          break;
        default:
          break;
      }
    }
    return hasError
  }

  const checkIfEmpty = async () => {
    let isEmpty = true
    for (const [key, value] of Object.entries(form)) {
      switch (key) {
        case "firstName":
        case "lastName":
        case "phoneNumber":
        case "countryCode":
        case "relationship":
        case "email":
          if (value) {
            isEmpty = false
          }
          break;
        case "address":
          if (address.line1 || address.line2 || address.postalCode) {
            isEmpty = false
          }
          break;
        default:
          break;
      }
    }
    return isEmpty
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    setForm({
      ...form,
      [key]: value
    })

    debounceHandler({ key, value })
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value
    if (key === 'postalCode') {
      let regex = /^\d*$/
      if (!regex.test(value.trim())) {
        return
      } else {
        setAddress({
          ...address,
          [key]: value
        })
        return
      }
    }
    setAddress({
      ...address,
      [key]: value
    })

    debounceHandler({ key, value })
  }

  const handleDelete = async (rfm: RFM) => {
    if (id) {
      await updateRFM({ type: 'del', fuenralId: id, details: rfm })
    } else {
      let newList = rfmList.filter((item) => {
        return item.email !== rfm.email
      })
      setRfmList(newList)
    }
  }

  const handleEditRFM = (row: any, idx: number) => {
    setSelectedEdit({ idx: idx, ...row })
    handleOpen()
  }

  const handleUpdateRFM = async (rfm: any) => {
    let rv
    if (id || accountState.details.id) {
      try {
        delete rfm.idx
        rv = await updateRFM({ type: 'update', funeralId: rfm.funeralId, details: rfm }).unwrap()

        console.log('rev update:', rv)

        if (rv) {
          setToast({
            ...toast,
            open: true,
            message: rv.message,
            type: 'success'
          })
        }
      } catch (e: any) {
        console.log('error prod:', e)
        setToast({
          ...toast,
          open: true,
          message: e.toString(),
          type: 'error'
        })
      }
    } else {
      let oldList = rfmList

      oldList[rfm.idx] = rfm

      delete oldList[rfm.idx].idx

      setRfmList(oldList)
    }
  }

  const handleSave = async () => {
    if (rfmList.length === 0) {
      let rv0 = await addNewRfm()
      if (!rv0) {
        setFailMsg({
          error: true,
          message: "Required to have at least one RFM."
        })
      } else {
        props.onSave(rv0)
      }
    } else {
      //Check if form is empty
      let isEmpty = await checkIfEmpty()

      if (isEmpty) {
        props.onSave(rfmList)
      } else {
        let rv1 = await addNewRfm(false)
        if (!rv1) {
          setFailMsg({
            error: true,
            message: "Please fill out the RFM form. Leave blank if you are not planning to add another RFM."
          })
        }
        if (rv1) {
          props.onSave(rv1)
        }
      }
    }
  }

  return (
    <>
      <Toast {...toast} />
      <Grid container spacing={2}>
        <Grid item xs={12} className={`${styles.isflex}`} justifyContent={'space-between'}>
          <Box sx={{ width: { xs: '100%', md: '30%' } }}>
            <InputLabel
              error={errors.firstName}
              required
            >
              <span className={styles.mandatory}>
                First Name
              </span>
            </InputLabel>
            <CustomTextField
              error={errors.firstName}
              helperText={errors.firstName ? 'Requires a valid name' : ''}
              sx={{ width: { xs: '100%' }, height: 40, mb: 2 }} value={form.firstName}
              onChange={handleChange}
              id='firstName'
              name='firstName'
              placeholder='First Name'
              size='small'
              className={styles.input}
            />
          </Box>
          <Box sx={{ width: { xs: '100%', md: '30%' } }}>
            <InputLabel
              error={errors.lastName}
              required
            >
              <span className={styles.mandatory}>Last Name </span>
            </InputLabel>
            <CustomTextField
              error={errors.lastName}
              helperText={errors.lastName ? 'Requires a valid name' : ''}
              sx={{ width: { xs: '100%' }, height: 40, mb: 2 }}
              value={form.lastName}
              onChange={handleChange}
              id='lastName'
              name='lastName'
              placeholder='Last Name'
              size='small'
              className={styles.input}
            />
          </Box>
          <Box sx={{ width: { xs: '100%', md: '30%' } }}>
            <InputLabel
              required
            >
              Relationship to Deceased
            </InputLabel>
            <CustomTextField
              error={errors.relationship}
              helperText={errors.relationship ? 'Requires a valid relationship' : ''}
              sx={{ width: { xs: '100%' }, height: 40, mb: 2 }}
              value={form.relationship}
              onChange={handleChange}
              id='relationship'
              name='relationship'
              placeholder='Relationship'
              size='small'
              className={styles.input}
            />
          </Box>
        </Grid>

        <Grid item xs={12} className={`${styles.isflex}`} justifyContent={'space-between'}>
          <Box sx={{ width: { xs: '100%', md: '30%' } }}>
            <InputLabel
              required
              error={errors.email}
            >
              <span className={styles.mandatory}>Email </span>
            </InputLabel>
            <CustomTextField error={errors.email} helperText={errors.email ? 'Requires a valid email' : ''} sx={{ width: { xs: '100%' }, mb: 4 }} value={form.email} onChange={handleChange} id='email' name='email' placeholder='Email' size='small' className={styles.input} />
          </Box>
          <Box sx={{ width: { xs: '100%', md: '30%' } }}>
            <InputLabel>
              Phone Number <span className='optional'></span>
            </InputLabel>
            <Grid container>
              <Grid item xs={12} md={3}>
                <CustomTextField
                  error={errors.countryCode}
                  helperText={errors.countryCode ? 'Requires a valid country code' : ''}
                  sx={{ width: { xs: '100%' }, mb: 4 }}
                  value={form.countryCode}
                  onChange={handleChange}
                  id='countryCode'
                  name='countryCode'
                  size='small'
                  type=''
                  className={styles.input}
                  placeholder='65'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >
                        <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>+</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={9}
                sx={{
                  textAlign: 'end'
                }}
              >
                <CustomTextField
                  error={errors.phoneNumber}
                  helperText={errors.phoneNumber ? 'Requires a valid phone number' : ''}
                  sx={{
                    width: { xs: '100%', md: '98%' }, mb: 4,
                  }}
                  value={form.phoneNumber}
                  onChange={handleChange}
                  id='phoneNumber'
                  name='phoneNumber'
                  size='small'
                  className={styles.input}
                  placeholder='Phone number'
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ width: { xs: '100%', md: '30%' } }}>

          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mb: 4 }} className={`${styles.isflex}`} style={{ flexDirection: 'column' }}>
          <InputLabel required>Address <span className='optional'></span></InputLabel>
          <CustomTextField sx={{ width: { xs: '100%', md: '365px' }, mb: 1 }} value={address.line1} onChange={handleAddressChange} id='line1' name='line1' placeholder='Address Line 1' size='small' className={styles.input} />

          <CustomTextField sx={{ width: { xs: '100%', md: '365px' }, mb: 1 }} value={address.line2} onChange={handleAddressChange} id='line2' name='line2' placeholder='Address Line 2' size='small' className={styles.input} />

          <CustomTextField sx={{ width: { xs: '100%', md: '365px' }, mb: 4 }} value={address.postalCode} onChange={handleAddressChange} id='postalCode' name='postalCode' placeholder='Postal Code' size='small' className={styles.input} />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 4 }}>
        <Grid item xs={12} md={3} sx={{ mt: 2 }}>
          <Button className='btnTransparent' sx={{ width: { xs: '100%', sm: '100%' }, mb: 4 }} onClick={() => addNewRfm()}>Add Another RFM</Button>
        </Grid>
        <Grid item sx={{ mt: 1 }}>
          {
            failMsg.error ? <Box className={styles.error}>{failMsg.message}</Box> : <></>
          }
        </Grid>
        <Grid item xs={12} md={3} sx={{ mt: 2 }}>
          <Button className='btnPrimary' sx={{ width: { xs: '100%', sm: '100%' }, mb: 4 }} onClick={handleSave}>Save</Button>
        </Grid>
      </Grid>

      <TableContainer>
        <Table sx={{ mt: 4, overflow: 'auto' }} id="tableRfm">
          <TableHead className={styles.tableHead}>
            <TableRow>
              <StyledTableCell className={styles.rfmTablehead} sx={{ width: { xs: '80px', md: '200px' } }}
              >
                RFM
              </StyledTableCell>
              <StyledTableCell className={styles.rfmTablehead} sx={{ width: { xs: '100px', md: '200px' } }}
              >
                Email
              </StyledTableCell>
              <StyledTableCell className={styles.rfmTablehead} sx={{ width: { xs: '120px' } }}
              >
                Phone Number
              </StyledTableCell>
              <StyledTableCell className={styles.rfmTablehead} sx={{ width: { xs: '100px' } }}
              >
                Relationship
              </StyledTableCell>
              <StyledTableCell
                className={`${styles.rfmTablehead} ${styles.addressHead}`}
                sx={{ width: { xs: '300px' } }}
              >
                Address
              </StyledTableCell>
              <StyledTableCell className={styles.rfmTablehead} sx={{ width: { xs: '100px' } }}></StyledTableCell>
            </TableRow>
          </TableHead>
          <StyledTableBody className={styles.tableRfm_body}>
            {rfmList.map((item, index) => {
              return (
                <TableRow key={item.email} >
                  <TableCell sx={{ width: { xs: '80px', md: '200px' } }}
                  >
                    {item.firstName + ' ' + item.lastName}
                  </TableCell>
                  <TableCell sx={{ width: { xs: '100px', md: '200px' } }}
                  >
                    {item.email}
                  </TableCell>
                  <TableCell sx={{ width: { xs: '120px' } }}
                  >
                    +{item.phoneNumber}
                  </TableCell>
                  <TableCell sx={{ width: { xs: '100px' } }}
                  >
                    {item.relationship}
                  </TableCell>
                  <TableCell
                    sx={{ width: { xs: '300px' } }}
                    className={styles.addressCell}
                  >
                    <Tooltip
                      title={item.address}
                    >
                      <div
                        className={styles.addressTextContainer}
                      >
                        {item.address}
                      </div>
                    </Tooltip>
                  </TableCell>
                  <StyledTableCell sx={{ display: 'flex' }} >
                    <MuiLink
                      className={styles.link}
                      sx={{ mr: 1 }}
                      onClick={() => handleEditRFM(item, index)}
                    >
                      Edit
                    </MuiLink><EditIcon />
                    <MuiLink
                      className={styles.link}
                      onClick={() => handleDelete(item)}
                      sx={{ ml: 4 }}
                    >
                      Delete
                    </MuiLink>
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </StyledTableBody>
        </Table>
      </TableContainer>

      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <EditRfm
          selectedRFM={selectedEdit}
          handleClose={handleClose}
          handleUpdateRFM={handleUpdateRFM}
          rfmList={rfmList}
        />
      </Modal>
    </>
  )
}

export default RfmForm