import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import MuiStepper, { StepperProps as MuiStepperProps } from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { StepLabelProps } from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import styles from './Stepper.module.css'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-90% + 16px)',
    right: 'calc(100%)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--TLC-gray)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--TLC-gray)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: 'var(--TLC-web-grey)',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: 'var(--TLC-gray)',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: 'var(--TLC-gray)',
      zIndex: 1,
      fontSize: 14,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-alternativeLabel': {
    cursor: 'pointer'
  },
}))

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

interface StepperProps extends MuiStepperProps {
  steps: string[]
  active: number
  handleClick: (index: number) => void
};

const Stepper: React.FC<StepperProps> = (props) => {
  const { steps, active, handleClick } = props
  return (
    <Stack sx={{ ...props.sx, width: '100%', overflow: 'auto' }} spacing={4}>
      <MuiStepper alternativeLabel activeStep={active} connector={<QontoConnector />}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StyledStepLabel className={styles.label} StepIconComponent={QontoStepIcon} onClick={() => handleClick(index)}>{label}</StyledStepLabel>
          </Step>
        ))}
      </MuiStepper>
    </Stack>
  );
}

export default Stepper