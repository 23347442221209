import React from 'react'
import { styled } from '@mui/material/styles';

export  const Icon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 18,
  height: 18,
  border: '2px solid var(--TLC-gray)',
  '.Mui-focusVisible &': {
    outline: '2px auto var*--TLC-gray)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--TLC-web-grey)' : 'var(--TLC-web-grey)',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

export const IndeterminateIcon = styled(Icon)({
  backgroundColor: 'var(--TLC-gray)',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage: "url(\"data:image/svg+xml;charset=UTF-8,%3csvg  version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 203.372 203.372' xml:space='preserve' fill='%23fff'%3e%3cg id='SVGRepo_bgCarrier' stroke-width='0'/%3e%3cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'/%3e%3cg id='SVGRepo_iconCarrier'%3e%3cg%3e%3cpath style='fill:%23fff;' d='M191.229,89.548H12.147C5.433,89.548,0,94.981,0,101.688c0,6.703,5.433,12.136,12.147,12.136 h179.082c6.71,0,12.143-5.433,12.143-12.136C203.368,94.977,197.939,89.548,191.229,89.548z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: 'var(--TLC-gray)',
  },
});

export const CheckedIcon = styled(Icon)({
  backgroundColor: 'var(--TLC-gray)',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: 'var(--TLC-gray)',
  },
});
