import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '../../base/Button/Button';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../../base/Select/CustomSelect';
import OutlinedInput from '@mui/material/OutlinedInput';
import styles from './EventSteps.module.css'
import RecipientForm from './RecipientForm';
import MenuItem from '@mui/material/MenuItem';
import RfmForm from '../../Rfm/RfmForm';
import { getEventState } from '../../../features/event/eventSlice';
import { useGetRFMlistQuery } from '../../../features/account/accountRestApi';
import { useUpdateEventRFMMutation } from '../../../features/event/eventRestApi';
import Toast from '../../base/Toast/Toast';

interface RecipientProps {
  handleStepper: (step: number) => void
}

const initial = {id: 0, funeralId: 0, firstName: "temp", lastName: "temp", email: "",address: "", phoneNumber: '', race: '', relationship: '-'}


const Recipient: React.FC<RecipientProps> = (props) => {
  const { event } = useAppSelector(getEventState)
  const { data, isLoading } = useGetRFMlistQuery(event.funeralId, { refetchOnMountOrArgChange: true })
  const [ rfmlist, setRfmlist ] = useState<any[]>([initial])
  const [selectedRFM, setSelectedRFM] = useState<any>(initial)
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => {setToast({...toast, open: false})}
  })
  const [updateRFM] = useUpdateEventRFMMutation()
  

  useEffect(() => {
    if(data && !isLoading) {
      setRfmlist(data.rfmList)
      setSelectedRFM(data.rfmList[0])
    }
  }, [data, isLoading]) 

  const nextStep = async () => {
    try {
      let body = {
        eventId: event.id,
        rfmId: selectedRFM.id
      }
      let rv = await updateRFM(body).unwrap()
      if(rv) {
        setToast({
          ...toast,
          open: true,
          message: rv.message,
          type: 'success'
        })
        props.handleStepper(3)
      }
    }catch(e: any) {
      setToast({
        ...toast,
        open: true,
        message: e.data.message,
        type: 'error'
      })
    }
    
  }

  const prevStep = () => {
    props.handleStepper(1)
  }
  
  return (
    <>
      <Toast {...toast}/>
      <Box className={styles.container2}>
        <Grid container spacing={2}>
          {/*Row 1 */}
          <Grid item xs={12} className='text'>Send Products to RFM</Grid>
          <Grid item xs={12}>
            <CustomSelect
              className={styles.input}
              sx={{width:  {xs: '400px', md: '480px'}}}
              size='small'
              value={selectedRFM}
              onChange={(event) => setSelectedRFM(event.target.value)}
              input={<OutlinedInput />}
            >
              {rfmlist.map(((item, index) => (
                <MenuItem
                  key={index}
                  value={item}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'var(--TLC-web-grey)',
                      '&:hover': {
                        backgroundColor: 'var(--TLC-web-grey)',
                      },
                    },
                  }}
                >
                  {item.lastName}
                </MenuItem>
              )))}
            </CustomSelect>
          </Grid>
          {/*Row 2 */}
          <Grid item xs={12} sx={{my:2}} className='sectionTextBold'>Registered Family Member (RFM)</Grid>
        </Grid>
        <RecipientForm rfmList={rfmlist} onNext={nextStep}/>
        {/* <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{mt:2}}>
          <Grid item xs={6} md={2.2} sx={{mt: 2}}>
            <Button className='btnPrimary' sx={{width: {xs: 200, sm: 300}, mb: 4}} onClick={prevStep}>Previous</Button>
          </Grid>
          <Grid item xs={6} md={2.2} sx={{mt: 2}}>
            <Button className='btnPrimary' sx={{width: {xs: 200, sm: 300}, mb: 4}} onClick={nextStep}>Next</Button>
          </Grid>
        </Grid> */}
      </Box>
    </>
  )
}

export default Recipient