import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks';
import { Link, useParams, useNavigate } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './EditEvent.module.css'
import Stepper from '../../../components/base/Stepper/Stepper';
import CreateEventPage from '../../../components/Event/EventSteps/CreateEventPage';
// import Productslist from '../../../components/Event/EventSteps/Products';
import Recipient from '../../../components/Event/EventSteps/Recipient';
import EventShare from '../../../components/Event/EventSteps/EventShare';
import { getAccountState } from '../../../features/account/accountSlice';
import { useGetAccountQuery } from '../../../features/account/accountRestApi';
import { useGetEventQuery } from '../../../features/event/eventRestApi';

interface StepContainerProps {
  step: number
  handleStepper: (step: number) => void
}

const steps = [
  'Create Event',
  // 'Products',
  'Product Recipient',
  'Share'
];

function StepContainer(props: StepContainerProps) {
  switch (props.step) {
    case 0:
      return (<CreateEventPage handleStepper={props.handleStepper} />)
    // case 1:
    //   return (<Productslist handleStepper={props.handleStepper} />)
    case 1:
      return (<Recipient handleStepper={props.handleStepper} />)
    case 2:
      return (<EventShare handleStepper={props.handleStepper}></EventShare>)
    default:
      return (<div>Default</div>)
  }
}

function EditEvent() {
  const { id, eventId } = useParams()
  const { details } = useAppSelector(getAccountState)
  const [deceased, setDeceased] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  useGetEventQuery(eventId, { refetchOnMountOrArgChange: true })
  useGetAccountQuery(id, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    let name = details.lastName
    setDeceased(name)

  }, [details])

  const handleStepper = (step: number) => {
    setActiveStep(step)
  }

  const skip = () => {
    let current = activeStep
    let newStep = current + 1
    setActiveStep(newStep)
  }

  return (
    <Box sx={{ mx: 1, py: 2, px: 1 }}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <div className={styles.header}>Edit Event</div>
        </Grid>
        <Grid item>
          {
            activeStep < (steps.length - 1) ? (
              <MuiLink onClick={skip} sx={{ textDecorationColor: 'var(--TLC-gray)', color: 'var(--TLC-gray)', cursor: 'pointer' }}>Skip</MuiLink>
            ) : ('')
          }

        </Grid>
      </Grid>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <Link className={styles.link} to="/dashboard">
          Accounts
        </Link>,
        <Box>Account: <span className={styles.name}>{deceased}</span></Box>
        <Link className={styles.link} to={`/account/event/${id}`}>
          Events List
        </Link>
        <Box>Edit</Box>
      </Breadcrumbs>
      <Stepper steps={steps} active={activeStep} handleClick={handleStepper} />
      <StepContainer step={activeStep} handleStepper={handleStepper} />
    </Box>
  )
}

export default EditEvent