import { Box, Grid, Modal } from '@mui/material'
import { useEffect, useState } from 'react'
import styles from './FuneralList.module.css'
import Button from '../../../components/base/Button/Button'
import FuneralDirectorTable from '../../../components/FuneralDirector/FuneralDirectorTable'
import { useGetFuneralDirectorListQuery } from '../../../features/funeralDirector/funeralDirectorRestApi'
import FuneralDirectorEdit from '../../../components/FuneralDirector/FuneralDirectorEdit/FuneralDirectorEdit'

const FuneralList = () => {

    const [funderalDirectors, setFunderalDirectors] = useState<any[]>([])

    const getFuneralDirectorList = useGetFuneralDirectorListQuery(null);
    useEffect(() => {
        if (!getFuneralDirectorList.isLoading && getFuneralDirectorList.data) {
            setFunderalDirectors(getFuneralDirectorList.data)
        }
    }, [getFuneralDirectorList])

    // Add funeral director
    const [isOpenEditModal, setOpenEditModal] = useState(false)
    const [selectedEdit, setSelectedEdit] = useState<any>({ id: 0, name: '' })
    const handleOpen = () => setOpenEditModal(true)
    const handleClose = () => {
        setSelectedEdit({ id: 0, name: '' })
        setOpenEditModal(false)
    }

    return (
        <Box sx={{ mt: 2, px: 2 }}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <div className={styles.header}>Funeral Director</div>
                </Grid>
                <Grid item>
                    <Button className='btnSecondary' sx={{ my: 0, width: { xs: 200, sm: 300 } }} onClick={handleOpen}>Add Funeral Director</Button>
                </Grid>
            </Grid>

            <Box
                sx={{
                    mt: 3
                }}
            >
                <FuneralDirectorTable data={funderalDirectors} />
            </Box>

            <Modal
                open={isOpenEditModal}
                onClose={handleClose}
            >
                <FuneralDirectorEdit
                    funeralDirector={selectedEdit}
                    handleCloseModal={handleClose}
                />
            </Modal>
        </Box>
    )
}

export default FuneralList