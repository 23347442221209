import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { Grid, Box, Typography, Pagination as MuiPagination, PaginationProps, PaginationItem, TextField } from '@mui/material';
import styles from './Pagination.module.css'
import Button from '../Button/Button';

const CustomPagination = styled(MuiPagination)({
  '& .MuiButtonBase-root' : {
    border: '1px solid var(--TLC-gray)',
    color: 'var(--TLC-gray)'
  },
  '& .MuiButtonBase-root.Mui-disabled' : {
    border: '1px solid #CCCCCC',
    color: '#CCCCCC',
    backgroundColor: 'transparent',
    opacity: '1',
  },
  '& .Mui-selected': {
    border: '1px solid var(--TLC-gray)',
    color: 'var(--TLC-white)',
    backgroundColor: 'var(--TLC-gray)',
  },
  '& li:last-child': {
    color: 'var(--TLC-gray)',
    '& button': { 
      backgroundColor: 'var(--TLC-gray)',
      width: 100,
      color: 'var(--TLC-white)'
    },
    '& svg': {
      visibility: 'hidden'
    },
    '& button:before': {
      content: '"Next"',
      verticalAlign: 'sub',
      marginLeft: '5px'
    }
  },
  '& li:first-of-type': {
    border: '1px solid var(--TLC-web-gray)',
    color: 'var(--TLC-gray)',
    '& button': { 
      backgroundColor: 'var(--TLC-gray)',
      width: 100,
      color: 'var(--TLC-white)'
    },
    '& svg': {
      visibility: 'hidden'
    },
    '& button:before': {
      content: '"Previous"',
      verticalAlign: 'sub',
      marginLeft: '5px'
    }
  }
  
})

const PageSelector = styled(TextField)({
  '&': {
      marginRight: '4%'
    },
  '& .MuiOutlinedInput-root': {
    background: 'transparent',
    width: 40,
    '& fieldset': {
      border: '1px solid var(--TLC-web-grey)',
      borderRadius: '5px',
    },
    '& input': {
      padding: '8px 5px',
      textAlign: 'center'
    },
  }
})

interface CustomPaginationProps extends PaginationProps {
  handleViewChange: (rowPerPage: number) => void
  type?: string
}

const Pagination: React.FC<CustomPaginationProps> = (props) => {
  const { handleViewChange, type, sx, ...rest } = props
  const [rowPerPage, setRowPerPage] = useState<any>(5)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value

    let regex = /^\d*$/
    if(regex.test(value)) {
      setRowPerPage(value)
    }else {
      return
    }
    
  }

  return (
    <Grid container spacing={2} justifyContent={'space-between'} sx={{...sx}}>
      <Grid item xs={12} md={5}>
        <Box sx={{display:'flex', alignItems:'center'}}>
          <Typography className={styles.text}>View Per Page</Typography>
          <PageSelector size="small" value={rowPerPage} onChange={handleChange}/>
        <Button className='btnTransparent' sx={{width: {xs: 150, md:300}, ml:4}} onClick={() => handleViewChange(+rowPerPage)}>View</Button>
        </Box>
      </Grid>
      <Grid item>
        <CustomPagination {...rest}/>
      </Grid>
    </Grid>
    
  )
}

export default Pagination