import React, { useState, useEffect } from 'react'
import Box, { BoxProps} from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '../base/Button/Button'
import styles from './CookiesBar.module.css'
import { useCookies } from 'react-cookie'

interface CookiesBarProps extends BoxProps {
}

const CookiesBar: React.FC<CookiesBarProps> = (props) => {
  const [acceptedCookies, setAcceptedCookies] = useState(true)
  const [cookies, setCookie] = useCookies(['accept_cookies'])

  useEffect(() => {
    if (!cookies['accept_cookies']) {
      setAcceptedCookies(false)
    }
  }, [])

  const handleClick = () => {
    setCookie('accept_cookies', true)
    setAcceptedCookies(true)
  }

  if(!acceptedCookies) {
    return (
      <Box className={styles.cookies}> 
        <Paper className={styles.cookies} elevation={0} sx={{display: 'flex', alignItems: 'center'}}>
          <p>This site uses cookies to improve your experience. By clicking, you agree to our Privacy Policy.</p>
          <Button className='btnAccept' variant='outlined' size="large" onClick={handleClick}>Accept</Button>
        </Paper>
      </Box>
    )
  }
  return (<></>)
}

export default CookiesBar