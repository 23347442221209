import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';
import { checklist } from '../../type';

export interface ChecklistState {
  checklist: checklist[]
}

const initialState: ChecklistState  = {
  checklist: []
};

export const checklistSlice = createSlice({
  initialState,
  name: 'checklist',
  reducers: {
    setChecklist: (state, {payload}) => {
      state.checklist = payload
    }
  },
});

export const getChecklistState = (state: RootState) => state.checklist;

export default checklistSlice.reducer;

export const { setChecklist } = checklistSlice.actions;