import react, { useState, useEffect } from 'react'
import { Container } from '@mui/material';
import { Link, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styles from './Footer.module.css'

function Footer() {
  const location = useLocation()
  const [value, setValue] = useState(0);

  useEffect(() => {
    let path = location.pathname
    if (path.includes('approval')) {
      setValue(1)
    }
    if (path.includes('sales')) {
      setValue(2)
    }
    if (path.includes('dashboard')) {
      setValue(0)
    }
    if (path.includes('profile')) {
      setValue(-1)
    }
  }, [location])

  const handleGotoVendure = () => {
    window.open(process.env.REACT_APP_VENDUR_ADMIN + '/orders', '_blank')
    return null;
  }

  return (
    <Box className={styles.footer} sx={{ mt: 4, height: '150px' }}>
      <Container maxWidth='xl'>
        <Box>
          <Grid className={styles.nav} container spacing={2} sx={{ pt: 4, mb: 2 }}>
            <Grid item xs={3} md={1}>
              <Link className={styles.link} style={{ color: value === 0 ? 'var(--TLC-gray)' : 'var(--TLC-bodytxt)' }} to='/dashboard'>Accounts</Link>
            </Grid>
            <Grid item xs={3} md={1}>
              <Link className={styles.link} style={{ color: value === 1 ? 'var(--TLC-gray)' : 'var(--TLC-bodytxt)' }} to='/approval'>Approval</Link>

            </Grid>
            <Grid item xs={3} md={1}>
              <Link className={styles.link} style={{ color: value === 2 ? 'var(--TLC-gray)' : 'var(--TLC-bodytxt)' }} to='#' onClick={handleGotoVendure}>Sales</Link>
            </Grid>
          </Grid>
        </Box>
        <Box className={styles.center} sx={{ width: '70%', margin: 'auto' }}>
          <p className={styles.notice}> &#169; {new Date().getFullYear()} The Life Celebrant. All Rights Reserved. Powered by
            <a href="https://www.visiongroup.co/" target="_blank" rel="noopener noreferrer"> VisionTech</a>.</p>
        </Box>

      </Container>
    </Box>


  )
}

export default Footer