import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '../../components/base/Button/Button';
import styles from './Dashboard.module.css'
import DashboardTable from '../../components/Dashboard/DashboardTable';
import { getDashboardState } from '../../features/dashboard/dashboardSlice';
import { useGetFuneralsQuery, useGetUserSettingsQuery } from '../../features/dashboard/dashboardRestApi';


function Dashboard() {
  const navigate = useNavigate()
  const dashboard = useAppSelector(getDashboardState)
  useGetFuneralsQuery(null, {refetchOnMountOrArgChange: true})
  useGetUserSettingsQuery(null, {refetchOnMountOrArgChange: true})
  const handleNavigate = () => {
    navigate('/account/create')
  }

  return (
    <Box sx={{mt:2, px:2}}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <div className={styles.header}>Registered Accounts</div>
        </Grid>
        <Grid item>
          <Button className='btnSecondary' sx={{my: 0,width: {xs: 200, sm: 300}}} onClick={handleNavigate}>Create New Account</Button>
        </Grid>
      </Grid>
      <DashboardTable funerals={dashboard.funerals} checklistCount={dashboard.checklistCount} familyCount={dashboard.familyCount} rfmlist={dashboard.rfmlist} settings={dashboard.settings}/>
    </Box>
  )
}

export default Dashboard