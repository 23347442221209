import { restApi } from '../../api/restApi'

export const funeralDirectorRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getFuneralDirectorList: builder.query({
      providesTags: ['funeralDirectors'],
      query: () => ({
        url: `/api/app-template/funeral-director/list`,
        method: 'GET',
      })
    }),
    addFuneralDirector: builder.mutation({
      invalidatesTags: ['funeralDirectors'],
      query: (payload) => ({
        url: '/api/app-template/funeral-director/add',
        method: 'POST',
        body: payload
      })
    }),
    updateFuneralDirector: builder.mutation({
      invalidatesTags: ['funeralDirectors'],
      query: (payload) => ({
        url: '/api/app-template/funeral-director/update',
        method: 'POST',
        body: payload
      })
    }),
  }),
})

export const {
  useGetFuneralDirectorListQuery,
  useAddFuneralDirectorMutation,
  useUpdateFuneralDirectorMutation,
} = funeralDirectorRestApi