import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '../../components/base/Button/Button';
import Grid from '@mui/material/Grid';
import { InputLabel } from '@mui/material';
import CustomTextField from '../../components/base/Textfield/CustomTextField';
import { validateEmail, debounce } from '../../constants';
import styles from './Login.module.css'
import Toast from '../../components/base/Toast/Toast';
import { useRequestPasswordResetMutation } from '../../features/auth/authGqlAPI';

interface CustomFailMsgProps {
  error: any
  message: string
}

const CustomFailMsg: React.FC<CustomFailMsgProps> = (props) => {
  let {error, message} = props
  if(error) {
    return <p className={styles.error}>{message}</p>
  }else {
    return <p style={{height:10}}></p>
  }
}

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [ requestResetPassword] = useRequestPasswordResetMutation()
  const [toast, setToast] = useState({
    open: false,
    type: 'success',
    message: '',
    handleClose: () => {setToast(prev => ({...prev, open:false}))}
  })
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({
    email: false,
    message: ''
  })

  const validateSingleField = (obj: {key: string, value: string}) => {
    const { key, value} = obj
    let rv
    if(key === 'email') {
      rv = validateEmail(value)
    }
    
    if(!rv) {
      setErrors({
        email: true,
        message: "Requires a valid email address."
      }) 
    }else {
      setErrors({
        email: false,
        message: ""
      }) 
    }
  }

  const debounceHandler = useCallback(
    debounce(validateSingleField)
  , []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value
    
    setEmail(value)

    debounceHandler({key, value:value.trim()})
  }

  const handleClick = async () => {
    try {
      setLoading(true)
      const hasError = validate()
      if(hasError) {
        setLoading(false)
        return
      }
      let rv = await requestResetPassword({emailAddress: email}).unwrap()
      if(rv && rv.requestPasswordReset.success) {
        setToast({
          ...toast,
          open: true,
          message: "Email has been sent. Please check your email for the steps to reset your password!",
          type: 'success'
        })
      }
      if(rv && !rv.requestPasswordReset.success) {
        setErrors({
          email: true,
          message: "Please make sure that the email is a registered admin!"
        })
      }
      setLoading(false)
    }catch(e: any) {
      if(e.data.message.includes('error.cannot-locate-admin-for-user')) {
        setErrors({
          email: true,
          message: "Please make sure that the email is a registered admin!"
        })
      }else {
        setErrors({
          email: true,
          message: e.data.message
        })
      }
      
      setLoading(false)
    }
  }

  const validate = () => {
    let hasError = false
    if(!email) {
      setErrors(prev => ({...prev, email: true})) 
      hasError = true
    }else {
      errors.email ? hasError = true : setErrors(prev => ({...prev, email: false}))
    }
    return hasError
  }

  return (
    <div className={styles.loginContainer}>
    <Toast {...toast} />
      <Box>
      <img
          src={`/images/TLC-MAIN-LOGO.png`}
          alt="The Life Celebrant"
          width={250}
          loading="lazy"
        />
      </Box>
      <Card className={styles.card} sx={{width: {xs: '90%', md:'38%'}}}>
        <div className={styles.login}>Request Password Reset</div>
        <Box>
          <InputLabel sx={{my:2}} htmlFor="email">Email</InputLabel>
          <CustomTextField sx={{mb:1}} id="email" name="email" placeholder='Email' fullWidth className={styles.input} onChange={handleChange}/>

          <Grid container sx={{mb:2}}>
            <Grid item xs>
              {<Link to="/login" className={styles.link}>
              Go back to login page
              </Link>}
            </Grid>
          </Grid>
         
          <CustomFailMsg error={errors.email} message={errors.message}/>
          <Button fullWidth variant="contained" className="btnPrimary" sx={{mt: 4}} onClick={handleClick}>Request</Button>
        </Box>
      </Card>
      <p className={styles.notice}> &#169; {new Date().getFullYear()} The Life Celebrant. All Rights Reserved. Powered by 
      <a href="https://www.visiongroup.co/" target="_blank" rel="noopener noreferrer">VisionTech</a>.</p>
    </div>
  )
}

export default ResetPassword