import { useState, useEffect } from 'react'
import { useAppSelector } from '../../app/hooks';
import { useParams, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import styles from './EditAccount.module.css'
import Stepper from '../../components/base/Stepper/Stepper';
import Details from '../../components/Account/AccountSteps/Details';
// import PageTemplate from '../../components/Account/AccountSteps/PageTemplate';
// import MasterGuestList from '../../components/Account/AccountSteps/MasterGuestList';
// import MasterProductlist from '../../components/Account/AccountSteps/MasterProductlist';
// import EventProductList from '../../components/Account/AccountSteps/EventProductList';
import Obituary from '../../components/Account/AccountSteps/Obituary';
import Paynow from '../../components/Account/AccountSteps/Paynow';
import Messages from '../../components/Account/AccountSteps/Messages';
import EventAdd from '../../components/Account/AccountSteps/EventAdd';
import Checklist from '../../components/Account/AccountSteps/Checklist';
import Broadcast from '../../components/Account/AccountSteps/Broadcast';
import { getAccountState } from '../../features/account/accountSlice';
import { useGetAccountQuery } from '../../features/account/accountRestApi';
import { useUpdateAccountMutation } from '../../features/account/accountRestApi';
import { Typography } from '@mui/material';

interface StepContainerProps {
  step: number
  handleStepper: (step: number) => void
}

const steps = [
  'Details of Deceased',
  // 'Page Template',
  // 'Create Master Guest List',
  // 'Create Master Product List',
  'Create Event',
  // 'Choose Products',
  'Obituary',
  'PayNow & Images',
  'Heartfelt Messages',
  'Checklist',
  'Share & Broadcast'
];
const header = [
  'Create New Account',
  // 'Page Template',
  // 'Create Master Guest List',
  // 'Master Product List',
  'Create Event',
  // 'Choose Products',
  'Obituary',
  'PayNow & Images',
  'Heartfelt Messages',
  'Checklist',
  'Share & Broadcast'
]

function StepContainer(props: StepContainerProps) {
  switch (props.step) {
    case 0:
      return (<Details handleStepper={props.handleStepper} />)
    // case 1:
    //   return (<PageTemplate handleStepper={props.handleStepper} />)
    // case 2:
    //   return (<MasterGuestList handleStepper={props.handleStepper} />)
    // case 3:
    //   return (<MasterProductlist handleStepper={props.handleStepper} />)
    case 1:
      return (<EventAdd handleStepper={props.handleStepper} />)
    // case 5:
    //   return (<EventProductList handleStepper={props.handleStepper} />)
    case 2:
      return (<Obituary handleStepper={props.handleStepper} />)
    case 3:
      return (<Paynow handleStepper={props.handleStepper} />)
    case 4:
      return (<Messages handleStepper={props.handleStepper} />)
    case 5:
      return (<Checklist handleStepper={props.handleStepper} />)
    case 6:
      return (<Broadcast handleStepper={props.handleStepper} />)
    default:
      return (<div>Default</div>)
  }
}

function EditAccount() {
  const { id } = useParams()
  useGetAccountQuery(id, { refetchOnMountOrArgChange: true })
  const accountState = useAppSelector(getAccountState);
  const [activeStep, setActiveStep] = useState(0)
  const [updateAccount] = useUpdateAccountMutation()
  const navigate = useNavigate()

  useEffect(() => {
    setActiveStep(accountState.details.step)
  }, [accountState.details.step])

  const handleStepper = (step: number) => {
    setActiveStep(step)
  }

  const skip = () => {
    let current = activeStep
    let newStep = current + 1
    if (accountState.details.isCompleted === 0 && accountState.details.step < newStep) {
      updateAccount({ funeralId: accountState.details.id, query: { step: newStep } })
    }

    if (steps.length === current + 1) {
      navigate('/dashboard')
    } else {
      setActiveStep(newStep)
    }
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ py: 2 }}>
        <Grid item md={10}>
          <div className={styles.header}>{header[activeStep]}</div>
        </Grid>
        <Grid item md={1}>
          {
            activeStep + 1 === steps.length ? ('') : (
              <Link onClick={skip} style={{ cursor: 'pointer', color: 'var(--TLC-gray)', textDecorationColor: 'var(--TLC-gray)' }}>Skip</Link>
            )
          }

        </Grid>
      </Grid>
      <Typography component={"div"} variant={"body2"} className="hr"></Typography>
      <Stepper steps={steps} active={activeStep} handleClick={handleStepper} sx={{ mt: 2 }} />
      <StepContainer step={activeStep} handleStepper={handleStepper} />
    </>
  )
}

export default EditAccount