import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';

export interface AuthState {
  auth: {
    access_token: string
    refresh_token: string
  }
}

const initialState: AuthState  = {
  auth: {
    access_token: '',
    refresh_token: ''
  }
};

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    backendLogout: (state) =>{
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      state = initialState
    },
    setAuthToken: (state, {payload}) => {
      const {access_token, refresh_token} = payload
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      state.auth = {
        access_token: access_token, 
        refresh_token: refresh_token
      }
    },
  },
});

export const getAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;

export const { backendLogout, setAuthToken } = authSlice.actions;