import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { account } from '../../type';


export interface AccountState {
  details: account
  email: {
    subject: string
    message: string
  }
}

const initialState: AccountState = {
  details: {
    id: null,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    dob: null,
    dod: null,
    race: '-',
    religion: 'Budhhist',
    pageTemplate: '1',
    profile_image: '',
    obituary: '',
    obituary_image: '',
    note: '',
    bankDetails: '',
    paynow_image: '',
    banner_image: '',
    video_url: '',
    showMessage: 1,
    showObituary: 1,
    showGifts: 1,
    showGallery: 1,
    step: 1,
    isCompleted: 0,
    livestream: '',
    livestreamUrl: '',
    rfmList: [],
    guestList: [],
    eventList: [],
    funeralDirector: { id: 0, name: '' }
  },
  email: {
    subject: 'Passing of <<Deceased Name>>',
    message: 'Our mother passed away peacefully in her sleep in hospital surrounded by her family and loved ones on the 16 October 2022. We are blessed to have been able to spend the remaining time with her.'
  }
};

export const accountSlice = createSlice({
  initialState,
  name: 'account',
  reducers: {
    resetState: (state, { payload }) => {
      state.details = {
        id: null,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        dob: null,
        dod: null,
        race: '',
        religion: 'Budhhist',
        pageTemplate: '1',
        profile_image: '',
        obituary: '',
        obituary_image: '',
        note: '',
        bankDetails: '',
        paynow_image: '',
        banner_image: '',
        video_url: '',
        showMessage: 1,
        showObituary: 1,
        showGifts: 1,
        showGallery: 1,
        step: 1,
        isCompleted: 0,
        livestream: '',
        livestreamUrl: '',
        rfmList: [],
        guestList: [],
        eventList: [],
        funeralDirector: { id: 0, name: '' },
      }
      state.email = {
        subject: 'Passing of <<Deceased Name>>',
        message: 'Our mother passed away peacefully in her sleep in hospital surrounded by her family and loved ones on the 16 October 2022. We are blessed to have been able to spend the remaining time with her.'
      }
    },
    setId: (state, { payload }) => {
      state.details.id = payload.id
      if (payload.profile_image) {
        state.details.profile_image = payload.profile_image
      }
    },
    setAccountDetails: (state, { payload }) => {
      state.details = {
        ...state.details,
        ...payload
      }
    },
    setTemplate: (state, { payload }) => {
      state.details.pageTemplate = payload
    },
    addMainGuest: (state, { payload }) => {
      for (var guest of payload) {
        let found = state.details.guestList.find((item) => {
          return item.email === guest.email
        })
        if (!found) {
          state.details.guestList.push(guest)
        }
      }
    },
    deleteMainGuest: (state, { payload }) => {
      state.details.guestList = payload
    },
    addNewEvent: (state, { payload }) => {
      state.details.eventList.push(payload)
    },
    updateEvent: (state, { payload }) => {
      let index = state.details.eventList.findIndex((item) => {
        return item.id === payload.id
      })
      if (index >= 0) {
        state.details.eventList[index] = payload
      }
    },
    /* setEventProducts: (state, {payload}) => {
      let eventlists = state.details.eventList
      let index = eventlists.findIndex((item) => item.type === payload.type)
    }, */
    initialiseAccount: (state, { payload }) => {
      state.details = {
        ...payload,
        image: null,
        imageName: '',
      }
      state.email = {
        subject: 'Passing of <<Deceased Name>>',
        message: 'Our mother passed away peacefully in her sleep in hospital surrounded by her family and loved ones on the 16 October 2022. We are blessed to have been able to spend the remaining time with her.'
      }

    },
    updateState: (state, { payload }) => {
      state.details = {
        ...state.details,
        ...payload
      }
    },
    setEmail: (state, { payload }) => {
      state.email = {
        ...state.email,
        ...payload
      }
    },
  },
});

export const getAccountState = (state: RootState) => state.account;

export default accountSlice.reducer;

export const { initialiseAccount, setId, setAccountDetails, setTemplate, addMainGuest, deleteMainGuest, addNewEvent, updateEvent, updateState, resetState, setEmail } = accountSlice.actions;