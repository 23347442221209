import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';

export interface UserState {
  status: string
  user: {
    email: string
    firstName: string
    lastName: string
  }
}

const initialState: UserState  = {
  status: '',
  user: {
    email: '',
    firstName: '',
    lastName: ''
  }
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    logout: () => initialState,
    setUser: (state, {payload}) => {
      const {emailAddress, firstName, lastName} = payload.activeAdministrator
      state.status = 'loggedIn'
      state.user = {
        email: emailAddress,
        firstName: firstName,
        lastName: lastName
      }
    },
  },
});

export const getUserState = (state: RootState) => state.user;

export default userSlice.reducer;

export const { logout, setUser } = userSlice.actions;

