import { gqlApi } from '../../api/graphql'
import { gql } from 'graphql-request'

import { setUser } from './userSlice'

export const userGqlApi = gqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.mutation({
      query: () => ({
        body: gql`
          query {
            activeAdministrator
              {
                firstName
                lastName
                emailAddress
              }
          }
        `
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {}
      },
    }),
  }),
})

export const { useGetMeMutation } = userGqlApi