import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChecklistTable from '../../components/Checklist/ChecklistTable';
import styles from './Checklist.module.css'
import { getDashboardState } from '../../features/dashboard/dashboardSlice';
import { getChecklistState } from '../../features/checklist/checklistSlice';
import { useGetChecklistQuery } from '../../features/checklist/checklistRestApi';
import { useGetAccountQuery } from '../../features/account/accountRestApi';

function Checklist() {
  const { id } = useParams()
  const [deceased, setDeceased] = useState('')
  const { funerals } = useAppSelector(getDashboardState)
  const { checklist} = useAppSelector(getChecklistState)
  useGetChecklistQuery(id, { refetchOnMountOrArgChange: true })
  useGetAccountQuery(id, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    let found = funerals.find((item) => item.id == id)
    if(found) {
      let name = found.lastName
      setDeceased(name)
    }
  }, [])

  return (
    <Box sx={{mt:2, px:2}}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <div className={styles.header}>Checklist</div>
        </Grid>
      </Grid>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link className={styles.link} to="/dashboard">
          Accounts
        </Link>,
        <div>Account: <span className={styles.name}>{deceased}</span></div>
        <Box>Checklist</Box>
      </Breadcrumbs>
      <ChecklistTable data={checklist}/>
    </Box>
  )
}

export default Checklist