import { restApi } from '../../api/restApi'
import { setChecklist } from './checklistSlice';
import { checklist } from '../../type';
import { dashboardRestApi } from '../dashboard/dashboardRestApi';

type ChecklistResponse = checklist[]

export const checklistRestAPi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getChecklist: builder.query<ChecklistResponse,string|undefined>({
      providesTags: ['checklist'],
      query: (id) => ({
        url: `/api/app-template/account/checklist/${id}`,
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setChecklist(data))
        } catch (error) {}
      },
    }),
    addChecklist: builder.mutation({
      invalidatesTags: ['checklist'],
      query: (payload) => ({
        url: '/api/app-template/account/checklist/add',
        method: 'POST',
        body: payload,
      })
    }),
    updateChecklist: builder.mutation({
      invalidatesTags: [`checklist`],
      query: (payload) => ({
        url: '/api/app-template/account/checklist/update',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteChecklist: builder.mutation({
      invalidatesTags: ['checklist'],
      query: (payload) => ({
        url: '/api/app-template/account/checklist/delete',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
})

export const { useGetChecklistQuery, useAddChecklistMutation, useUpdateChecklistMutation, useDeleteChecklistMutation } = checklistRestAPi