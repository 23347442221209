import react, { useEffect } from 'react';

const NavigateExternal = (props:any) => {
    useEffect(() => {
        window.open(process.env.REACT_APP_VENDUR_ADMIN + props.to, '_blank')
        // window.location.href = process.env.REACT_APP_VENDUR_ADMIN + props.to;
    }, []);
    return null;
};

export default NavigateExternal;