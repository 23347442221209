import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Grid from "@mui/material/Grid"
import CustomSearch from "../base/Textfield/CustomSearch"
import InputAdornment from "@mui/material/InputAdornment"
import Table from "@mui/material/Table"
import { StyledTableBody } from '../base/Table/CustomTable'
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import Pagination from '../base/Pagination/Pagination'
import TableRow from "@mui/material/TableRow"
import SearchIcon from "@mui/icons-material/Search"
import styles from "./Approval.module.css"
import Button from "../base/Button/Button"

type approveTable = {
  id: string | number
  firstName: string
  lastName: string
  rfm_firstName: string
  rfm_lastName: string
  msgCount: any
}

interface ApproveTableProps {
  data: approveTable[]
  messageCount: any[]
  rfmlist: any[]
}

interface TableToolbarProps {
  onRequestSearch: (value: string) => void
}

function TableToolbar(props: TableToolbarProps) {
  const { onRequestSearch } = props

  return (
    <Grid container spacing={2} justifyContent="end" alignItems="center" sx={{ pr: 2 }}>
      <Grid item>
        <CustomSearch
          sx={{ mb: 4 }}
          placeholder="Search"
          onChange={(e) => onRequestSearch(e.target.value)}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: "var(--TLC-web-grey)" }} />
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

const ApprovalTable: React.FC<ApproveTableProps> = (props) => {
  const navigate = useNavigate()
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [filtered, setFiltered] = useState<readonly approveTable[]>([])

  useEffect(() => {
    setFiltered(props.data)
  }, [props.data])

  const filteredItems = props.data.filter((item) => {
    return item.firstName.toLowerCase().includes(query.toLowerCase()) || item.lastName.toLowerCase().includes(query.toLowerCase())
  })

  const handleSearch = (value: string) => {
    setQuery(value)
    setPage(0)
  }
  //Pagination
  const totalPages = Math.ceil(filteredItems.length / rowsPerPage)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage-1)
  }

  const handleChangeRowsPerPage = (rpp: number) => {
    setRowsPerPage(rpp)
    setPage(0)
  }

  /* const getMsgCount = (id: string | number) => {
    let found = props.messageCount.find((item: any) => {
      return item.funeralId === id
    })
    if (found) {
      return found.count
    } else {
      return 0
    }
  } */

  const getRFMlist = (id: string | number) => {
    let found = props.rfmlist.filter((item: any) => {
      return item.funeralId === id
    })
    return found
  }

  return (
    <>
      <TableToolbar onRequestSearch={handleSearch} />
      <TableContainer sx={{overflowY: 'auto', mb:4}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={2} className={styles.tablehead}>
                Account
              </TableCell>
              <TableCell align="left" colSpan={2} className={styles.tablehead}>
                Messages to Approve
              </TableCell>
            </TableRow>
          </TableHead>
          <StyledTableBody>
            {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              const labelId = `approve-${index}`
              //const counts = getMsgCount(row.id)
              const rfmlist = getRFMlist(row.id)

              {
               return (
                  <TableRow key={row.id}>
                    <TableCell component="td" id={labelId} scope="row" padding="normal" sx={{ width: { xs: 100, sm: "none" }, minWidth: { xs: 100, sm: "none" } }} className={styles.tableheadTxt}>
                      <div>
                        <div>Deceased: </div>
                        <div>Main RFM: </div>
                      </div>
                    </TableCell>
                    <TableCell align="left" component="td" id={labelId} scope="row" padding="normal" sx={{ width: { xs: 200, sm: 400 }, minWidth: { xs: 200, sm: "none" } }}>
                      <div className={styles.deceasedName}>
                        <b>{row.lastName}</b>
                      </div>
                      <div className={styles.rfmName}>{rfmlist[0] ? rfmlist[0].lastName : '-'}</div>
                    </TableCell>
                    <TableCell align="left" sx={{ width: { xs: 200, sm: 400 }, minWidth: { xs: 200 } }}>
                      {row.msgCount}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        className="btnTransparent"
                        disableRipple={true}
                        sx={{ width: { xs: 200, sm: 300 } }}
                        onClick={() => {
                          navigate(`/approval/${row.id}`)
                        }}
                      >
                        View To Approve
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              }

              
            })}
          </StyledTableBody>
        </Table>
      </TableContainer>

      <Pagination
        sx={{mt:3}}
        variant="outlined" shape="rounded"
        count={totalPages}
        page={page+1}
        onChange={handleChangePage} 
        handleViewChange={handleChangeRowsPerPage}
      />
      
      {/* <TablePagination rowsPerPageOptions={[5, 10, 25, 100]} component="div" count={filtered.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
     */}
    </>
  )
}

export default ApprovalTable
