import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';
import { funeralDashboard } from '../../type';

type ChecklistCount = {
  funeralId: number | string
  count: number | string
}

export interface DashboardState {
  funerals: funeralDashboard[]
  checklistCount: ChecklistCount[]
  familyCount: any[]
  rfmlist: any[]
  settings: any
}

const initialState: DashboardState  = {
  funerals: [],
  checklistCount: [],
  familyCount: [],
  rfmlist: [],
  settings: {
    page: 0,
    rowsPerPage: 5,
    order: '',
    orderBy: ''
  }
};

export const dashboardSlice = createSlice({
  initialState,
  name: 'funeral',
  reducers: {
    setFunerals: (state, {payload}) => {
      state.funerals = payload.result
      state.checklistCount = payload.checklist
      state.familyCount = payload.familyCount
      state.rfmlist = payload.rfmlist
    },
    setSettings: (state, {payload}) => {
      state.settings = {
        ...state.settings,
        ...payload
      }
    },
    setDashboardPage: (state, {payload}) => {
      state.settings.page = payload
    },
  },
});

export const getDashboardState = (state: RootState) => state.funeral;

export default dashboardSlice.reducer;

export const { setFunerals, setSettings, setDashboardPage } = dashboardSlice.actions;