import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { event, guest, eventDetails } from '../../type';

export interface EventState {
  eventlist: event[]
  event: any
  guestlist: guest[]
}


const initialState: EventState  = {
  eventlist: [],
  guestlist: [],
  event: {
    id: null,
    funeralId: '',
    type: 'Funeral',
    title: '',
    createdAt: null,
    startDate: null,
    endDate: null,
    startTime: '',
    endTime: '',
    details: '',
    address: '',
    livestream: '',
    livestreamUrl: '',
    rfmId: null,
    products: ""
  },
};

export const eventlistSlice = createSlice({
  initialState,
  name: 'event',
  reducers: {
    resetEventState: (state, {payload}) => {
      state.event = {
          id: null,
          funeralId: '',
          type: 'Funeral',
          title: '',
          createdAt: null,
          startDate: null,
          endDate: null,
          startTime: '',
          endTime: '',
          details: '',
          address: '',
          livestream: 'Live Stream Not Available',
          livestreamUrl: '',
          rfmId: null,
          products: ""
      }
    },
    setEventlist: (state, {payload}) => {
      state.eventlist = payload
    },
    addGuest: (state, {payload}) => {
      for(var guest of payload) {
        let found = state.guestlist.find((item) => {
          return item.email === guest.email
        })
        if(!found) {
          state.guestlist.push(guest)
        }
      }
    },
    deleteGuest: (state, {payload}) => {
      state.guestlist = payload
    },
    setEvent: (state, {payload}) => {
      state.event = {
        ...state.event,
        ...payload
      }
    }
  },
});

export const getEventState = (state: RootState) => state.event;

export default eventlistSlice.reducer;

export const { resetEventState, setEventlist, addGuest, deleteGuest, setEvent } = eventlistSlice.actions;