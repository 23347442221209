import React, {useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '../../components/base/Pagination/Pagination';
import { CheckedIcon, Icon, IndeterminateIcon } from '../../components/base/Checkbox/CustomCheckbox';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import FormControlLabel from '@mui/material/FormControlLabel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './Approval.module.css'
import ApprovalCard from '../../components/Approval/ApprovalCard';
import { getDashboardState } from '../../features/dashboard/dashboardSlice';
import { getApprovalState } from '../../features/approval/approvalSlice';
import { useGetMessagesQuery, useUpdateMessageMutation, useDeleteMessageMutation } from '../../features/approval/approvalRestApi';
import Button from '../../components/base/Button/Button';

function ApprovalList() {
  const { id } = useParams()
  const [deceased, setDeceased] = useState('')
  const { funerals } = useAppSelector(getDashboardState)
  const { messageList } = useAppSelector(getApprovalState)
  const [list, setList] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowPerPage] = useState(5)
  const [updateMsg] = useUpdateMessageMutation()
  const [deleteMsg] = useDeleteMessageMutation()
  useGetMessagesQuery(id, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    let found = funerals.find((item) => item.id === id)
    if(found) {
      let name = found.lastName
      setDeceased(name)
    }
  },[funerals])

  useEffect(() => {
    // console.log(messageList)
    setList(messageList)
  }, [messageList])

  //All checkbox
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newList
    if (event.target.checked) {
      newList = list.map((item) => {
        return {
          ...item,
          approve: 1
        }
      })
    }else{
      newList = list.map((item) => {
        return {
          ...item,
          approve: 0
        }
      })
    }
    setList(newList);
  };

  const checkedCount = list.filter((item) => {
    return item.approve === 1
  })

  const handleCheckbox = (id: string | number | undefined, type: string) => {
    let newList: any[]
    
    switch(type) {
      case 'approve':
        newList = list.map((item) => {
          if(item.id === id) {
            return {
              ...item,
              approve: item.approve ? 0 : 1
            }
          }else {
            return item
          }
        })
        break;
      case 'private': 
        newList = list.map((item) => {
          if(item.id === id) {
            return {
              ...item,
              approve: item.private ? item.approve : 1,
              private: item.private ? 0 : 1
            }
          }else {
            return item
          }
        })
        break;
      case 'broadcast': 
        newList = list.map((item) => {
          if(item.id === id) {
            return {
              ...item,
              approve: item.broadcast ? item.approve : 1,
              broadcast: item.broadcast ? 0 : 1
            }
          }else {
            return item
          }
        })
        break;
      default:
        return
    } 
    setList(newList)
  }

  const onApprove = async () => {
    try {
      await updateMsg({list, funeralId:id}).unwrap()
    }catch(e) {
      console.log(e)
    }
    
  }

  const onDelete = async (messageId: any) => {
    try {
      let rv = await deleteMsg({messageId: messageId, funeralId:id}).unwrap()
      return rv
    }catch(e) {
      console.log(e)
    }
  }

  //Pagination
  const totalPages = Math.ceil(list.length / rowsPerPage)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage-1)
  }
  const handleViewChange = (rowperpage: number) => {
    setRowPerPage(rowperpage)
  }

  return (
    <Box sx={{mt:2, px:2}}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <div className={styles.header}>Approval</div>
        </Grid>
      </Grid>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{mb:4}}
      >
        <Link className={styles.link} to="/dashboard">
          Accounts
        </Link>,
        <div>Account: <span className={styles.name}>{deceased}</span></div>
      </Breadcrumbs>
      <Box>
        <Grid container spacing={2}>
          <Grid sx={{pl:3}} >
            <FormControlLabel
              label="Approve All"
              control={
                <Checkbox 
                  icon ={<Icon />}
                  checkedIcon={<CheckedIcon />}
                  indeterminateIcon={<IndeterminateIcon/>}
                  checked={messageList.length > 0 && checkedCount.length === messageList.length} 
                  indeterminate={checkedCount.length > 0 && checkedCount.length < messageList.length}
                  name="approveAll" 
                  onChange={handleSelectAllClick} />
              }
            />
          </Grid>
          <Grid>
            <Button className='btnSecondary' sx={{my: 0,width: {xs: 200, sm: 300}}} onClick={onApprove}>Approve</Button>
          </Grid>
        </Grid>
      </Box>
      
      <hr className={styles.divider}/>
      {
        list
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          return (
            <Box key={index}>
              <ApprovalCard data={item} handleCheckbox={handleCheckbox} onDelete={onDelete}/>
              <hr className={styles.divider}/>
            </Box>
            
          )
        })
      }
      <Box sx={{mt:4, mb:2}} >
        <Grid container spacing={2}>
          <Grid item >
            <FormControlLabel
              label="Approve All"
              control={
                <Checkbox 
                  icon ={<Icon />}
                  checkedIcon={<CheckedIcon />}
                  indeterminateIcon={<IndeterminateIcon/>}
                  checked={messageList.length > 0 && checkedCount.length === messageList.length} 
                  indeterminate={checkedCount.length > 0 && checkedCount.length < messageList.length}
                  name="approveAll" 
                  onChange={handleSelectAllClick} />
              }
            />
          </Grid>
          <Grid item>
            <Button className='btnSecondary' sx={{my: 0,width: {xs: 200, sm: 300}}} onClick={onApprove}>Approve</Button>
          </Grid>
        </Grid>
      </Box>

      <Pagination count={totalPages} variant="outlined" shape="rounded" page={page+1} onChange={handleChangePage} handleViewChange={handleViewChange}/>
    </Box>
  )
}

export default ApprovalList