import React, { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import MuiLink from "@mui/material/Link"
import InputLabel from "@mui/material/InputLabel"
import Grid from "@mui/material/Grid"
import Avatar from "@mui/material/Avatar"
import CustomTextField from "../Textfield/CustomTextField"
import Toast from "../Toast/Toast"
import Button from "../Button/Button"
import { useGetImageUrlMutation } from "../../../features/account/accountRestApi"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import styles from "./DragAndDrop.module.css"

interface DragAndDropProps {
  label: string
  filetype: string
  handleFile: (e: any) => void
  file?: any
  currFileName?: string
  clearUpload?: () => void
}

interface ImagePreviewProps {
  file: any
  currFileName?: string
}

const ImagePreview = (props: ImagePreviewProps) => {
  const { file, currFileName } = props
  const [preview, setPreview] = useState("")
  const [imageURl, setImageUrl] = useState("")
  const [getImageUrl] = useGetImageUrlMutation()

  useEffect(() => {
    if (file && !file.type.includes("csv")) {
      const objectUrl = window.URL.createObjectURL(file)
      setPreview(objectUrl)
    } else if (currFileName) {
      getURL(currFileName)
    }
  }, [file, currFileName])

  const getURL = async (filename: string) => {
    try {
      let rv = await getImageUrl({ filename: filename }).unwrap()
      setImageUrl(rv.imageUrl)
    } catch (e) { }
  }

  if ((file && !file.type.includes("csv"))) {
    return <Box className={styles.imagePreview}>
      <img src={preview} width="100%" loading="lazy" />
    </Box>
  } else if (currFileName) {
    return <Box className={styles.imagePreview}>
      <img src={imageURl} width="100%" loading="lazy" />
    </Box>
  } else if (file && file.type.includes("csv")) {
    return (
      <Box id={styles.csv_upload}>
        <div className={styles.uploadBox}>
          CSV file uploaded.
        </div>
      </Box>

    )
  } else {
    return <></>
  }
}

const DragAndDrop: React.FC<DragAndDropProps> = (props) => {
  const { currFileName, file, clearUpload } = props
  const [dragActive, setDragActive] = useState(false)
  const [length, setLength] = useState(0)
  /*  const [file, setFile] = useState<any>({
     file: "",
     type: "upload"
   }) */
  const [fileName, setFileName] = useState("")
  const [toast, setToast] = useState({
    message: "",
    type: "success",
    open: false,
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })

  useEffect(() => {
    if (!file) {
      setLength(0)
      setFileName("")
    } else {
      setFileName(file.name)
      setLength(1)
    }
  }, [file])

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    const check = validateFiletype(e.dataTransfer.files[0].type)
    if (!check) {
      setToast({
        ...toast,
        message: `Only files of type ${props.filetype} is allowed!`,
        type: "error",
        open: true
      })
      return
    }
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      props.handleFile(e.dataTransfer.files)
      /* setFile({
        file: e.dataTransfer.files[0],
        type: "upload"
      }) */
      //setLength(e.dataTransfer.files.length)
      //setFileName(e.dataTransfer.files[0].name)
    }
  }

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault()
    console.log(e.target.files[0])
    const check = validateFiletype(e.target.files[0].type)
    if (!check) {
      setToast({
        ...toast,
        message: `Only files of type ${props.filetype} is allowed!`,
        type: "error",
        open: true
      })
      return
    }
    if (e.target.files && e.target.files[0]) {
      // at least one file has been selected so do something
      console.log("e.target.files", e.target.files)
      props.handleFile(e.target.files)
      /* setFile({
        file: e.target.files[0],
        type: "upload"
      }) */
      //setLength(e.target.files.length)
      //setFileName(e.target.files[0].name)
    }
  }

  const validateFiletype = (type: string) => {
    let allowedtypes = props.filetype.replace(/\./g, "").split(", ")
    let uploadedType = type.split("/")
    console.log(allowedtypes, uploadedType)

    if (allowedtypes.includes(uploadedType[1])) {
      return true
    } else {
      return false
    }
  }

  /* const clearUpload = () => {
    setFile({
      file: "",
      type: "upload"
    })
    props.handleFile(null)
  } */

  return (
    <>
      <Toast {...toast} />
      <ImagePreview file={file} currFileName={currFileName} />
      {/* {!file ? (
        <> */}
      {!currFileName && !file ? (
        <Box id={styles.file_upload} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <CustomTextField id={props.label} type="file" name={props.label} className={styles.input_file_upload} onChange={handleChange} />
          <Box className={dragActive ? styles.drag_active : ""}>
            <div className={styles.uploadBox}><p>Drag and drop your file here ({props.filetype})</p></div>
          </Box>

          {dragActive && <div id={styles.drag_file_element} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
        </Box>
      ) : (
        <CustomTextField id={props.label} type="file" name={props.label} className={styles.input_file_upload} onChange={handleChange} />
      )}
      {/* </>
      ) : (
        <></>
      )} */}

      <Grid container spacing={2} sx={{ pt: 1 }} justifyContent="space-between">
        <Grid item sx={{ display: 'inherit', alignItems: 'center' }}>
          {file ? (
            <Box sx={{ textAlign: "start" }}>
              <MuiLink className={styles.link} onClick={clearUpload}>
                Remove file
              </MuiLink>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item>
          <Box alignContent={"end"} >
            <InputLabel className={styles.label_file_upload} htmlFor={props.label}>
              <span style={{ textDecoration: "underline" }}>Browse files</span>
              <Avatar sx={{ bgcolor: "var(--TLC-web-grey)", ml: 1 }}>
                <CameraAltIcon sx={{ color: "var(--TLC-gray)" }} />
              </Avatar>
            </InputLabel>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default DragAndDrop
