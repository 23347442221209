import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge'
import CustomTextField from '../../base/Textfield/CustomTextField';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import { StyledTableCell, StyledTableBody } from '../../base/Table/CustomTable';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import { CheckedIcon, Icon, IndeterminateIcon } from '../../base/Checkbox/CustomCheckbox';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../../base/Select/CustomSelect';
import CustomSearch from '../../base/Textfield/CustomSearch';
import styles from './EventTable.module.css'
import Button from '../../base/Button/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import GuestlistIcon from '../../base/Icons/GuestlistIcon';
import { Order, event } from '../../../type';
import { getComparator, stableSort, formatDate, csvmaker, downloadCsv } from '../../../constants';
import { useDeleteEventMutation } from '../../../features/event/eventRestApi';
import Toast from '../../base/Toast/Toast';

interface EventTableProps {
  data: event[]
}

interface TableHeadProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleActionSubmit: (action: string) => void
  rowCount: number;
}

interface TableToolbarProps {
  onRequestSearch: (value: string) => void;
}

const actions = [
  'Delete',
  'Download',
]

function TableToolbar (props: TableToolbarProps) {
  const { onRequestSearch } = props

  return (
    <Grid container spacing={2} justifyContent="end" alignItems="center">
      <Grid item>
      <CustomSearch sx={{width: {xs: 200, sm: 300},mb:4}} 
            placeholder='Search'
            onChange={e => onRequestSearch(e.target.value)}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
      </Grid>
    </Grid>
    );
}

function TableCustomHead (props: TableHeadProps) {
  const { onSelectAllClick, handleActionSubmit, numSelected, rowCount } = props;
  const [action, setAction] = useState('Delete');
  const handleActionChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setAction(value);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox" sx={{paddingLeft:0}}>
            <Checkbox
              icon ={<Icon />}
              checkedIcon={<CheckedIcon />}
              indeterminateIcon={<IndeterminateIcon/>}
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
        </StyledTableCell>
        <StyledTableCell colSpan={7}>
          <span style={{fontWeight: 400}}>Action:</span>
          <CustomSelect
            sx={{width: '200px', mx: 2}}
            size='small'
            labelId="action"
            value={action}
            onChange={handleActionChange}
            input={<OutlinedInput />}
          >
            {actions.map((item) => (
              <MenuItem
                key={item}
                value={item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'var(--TLC-web-grey)',
                    '&:hover': {
                      backgroundColor: 'var(--TLC-web-grey)',
                    },
                  },
                }}
              >
                {item}
              </MenuItem>
            ))}
          </CustomSelect>
          <Button className='btnTransparent' sx={{width: {xs: '200px'}}} onClick={() => handleActionSubmit(action)}>Submit</Button>
        </StyledTableCell>
      </TableRow>
    </TableHead>
  )
}

const EventTable: React.FC<EventTableProps> = (props) => {
  const navigate = useNavigate()
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => {setToast(prev => ({...prev, open: false}))}
  })
  const [query, setQuery] = useState('')
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState('type')
  const [filtered, setFiltered] = useState<event[]>([])
  const [selected, setSelected] = useState<any[]>([])

  const [deleteEvent] = useDeleteEventMutation()

  useEffect(() => {
    setFiltered(props.data)
  }, [props.data])

  const isSelected = (id: string | number) => {
    return selected.map(x => x.id).indexOf(id) !== -1
  }

  const filteredItems = props.data.filter((item) => {
    return item.type.toLowerCase().includes(query.toLowerCase()) || item.title.toLowerCase().includes(query.toLowerCase())
  })

  const handleSearch = (value: string) => {
    setQuery(value)
  }

  const handleActionSubmit = async (action: string) => {
    if(selected.length === 0) {
      return
    }
    let rv
    try {
      if(action === 'Delete') {
        rv = await deleteEvent({events: selected}).unwrap()
      }
      if(action === 'Download') {
        rv = handleDownload()
      }

      if(rv) {
        setToast({
          ...toast,
          message: rv.message,
          type: 'success',
          open: true
        })
        setSelected([])
      }
      

      }catch(e: any) {
        setToast({
          ...toast,
          message: e.data.message,
          type: 'error',
          open: true
        })
      }
  }
  
  const handleDownload = () => {
    let csv = csvmaker(selected)
    let rv = downloadCsv(csv, 'events')
    return rv
  }
  

  //Sort
  const handleRequestSort = (
    property: keyof event,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //All checkbox
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filtered;
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  //Individual checkbox
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selected.map(x => x.id).indexOf(row.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected.push(...selected, row) 
    }
    else if (selectedIndex === 0) {
      newSelected = selected.slice(1)
    }
    else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1)
    }
    else if (selectedIndex > 0) {
      let array1 = selected.slice(0, selectedIndex)
      let array2 = selected.slice(selectedIndex + 1)
      newSelected = array1.concat(array2)
    }
    setSelected(newSelected)
  };
  
  const editEvent = (row: any) => {
    navigate(`/account/${row.funeralId}/event/edit/${row.id}`)
  }

  const eventRsvp = (row: any) => {
    console.log("path", `/account/${row.funeralId}/event/rsvp/${row.id}`)
    navigate(`/account/${row.funeralId}/event/rsvp/${row.id}`)
  }


  return (
    <>
    <Toast {...toast}/>
    <TableToolbar onRequestSearch={handleSearch}/>
    <TableContainer className={styles.outlined}>
      <Table>
        <TableCustomHead 
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={filteredItems.length}
          handleActionSubmit={handleActionSubmit}
        />
        <TableHead>
          <TableRow></TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <TableSortLabel 
                className={styles.tableheader}
                active={orderBy === 'type'} 
                direction={orderBy === 'type' ? order : 'asc'} 
                onClick={() => handleRequestSort('type')}
                sx={{paddingLeft: 0}}
              >Event Type
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel 
                className={styles.tableheader}
                active={orderBy === 'name'} 
                direction={orderBy === 'name' ? order : 'asc'} 
                onClick={() => handleRequestSort('title')}>
              Event Name</TableSortLabel>
            </TableCell>
            <TableCell colSpan={7}>
              <TableSortLabel 
                className={styles.tableheader}
                active={orderBy === 'startDate'} 
                direction={orderBy === 'startDate' ? order : 'asc'} 
                onClick={() => handleRequestSort('startDate')}>
              Event Date</TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <StyledTableBody>
          {stableSort(filteredItems, getComparator(order, orderBy))
              //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `eventlist-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell sx={{padding: 0}}>
                      <Checkbox
                        icon ={<Icon />}
                        checkedIcon={<CheckedIcon />}
                        onClick={(event) => handleClick(event, row)}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 300}}}>{row.type}</TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 300}}}>{row.title}</TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 500}}}>{formatDate(row.startDate,'DD MMMM YYYY')}</TableCell>
                    <TableCell sx={{width: {xs: 200}}} align="right">
                      <Link className={styles.link} to={`/account/${row.funeralId}/event/rsvp/${row.id}`}>
                        <Badge badgeContent={0}>
                          <GuestlistIcon/>
                        </Badge>
                        <span style={{marginLeft:'4%'}}>RSVP List</span>
                      </Link>
                    </TableCell>
                    <TableCell><Button className='btnTable' onClick={() => editEvent(row)}>Edit</Button></TableCell>
                  </TableRow>
                )
              })
          }
        </StyledTableBody>
      </Table>
      
    </TableContainer>
    </>
  )
}

export default EventTable