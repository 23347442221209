import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { useLogoutMutation } from '../../features/auth/authGqlAPI'
import Logo from './Logo'
import styles from './Navbar.module.css'
import Button from '../../components/base/Button/Button';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

function Navbar() {
  const navigate = useNavigate()
  let location = useLocation();
  const [logoutUser] = useLogoutMutation()
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let path = location.pathname
    if (path.includes('approval')) {
      setValue(1)
    }
    if (path.includes('sales')) {
      setValue(2)
    }
    if (path.includes('dashboard')) {
      setValue(0)
    }
    if (path.includes('profile')) {
      setValue(-1)
    }
  }, [location])

  const handleLogout = () => {
    logoutUser(null)
    handleClose()
  }

  const handleNavigate = () => {
    navigate('/profile')
    handleClose()
  }

  const handleGotoVendure = () => {
    window.open(process.env.REACT_APP_VENDUR_ADMIN + '/orders', '_blank')
    return null;
  }

  return (
    <>
      <AppBar position="static" className={styles.nav} sx={{ mt: 4 }}>
        <Toolbar>
          <Logo />
          <Typography component="div" align="right" sx={{ flexGrow: 1, display: { xs: "block", sm: "none" } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="open menu"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
          </Typography>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleNavigate} className={styles.menuItem}>Profile</MenuItem>
            <MenuItem onClick={handleLogout} className={styles.menuItem}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box className={styles.tabs} sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="nav tabs example" sx={{ '& .MuiTabs-indicator': { backgroundColor: 'transparent' }, '& .Mui-selected': { fontWeight: 700, color: 'var(--TLC-gray) !important' } }}>
          <Tab label="Accounts" disableRipple component={Link} icon={<AccountCircleIcon />} iconPosition='start' to={"/dashboard"} sx={{ paddingBottom: 0 }} />
          <Tab label="Approval" disableRipple component={Link} icon={<CheckBoxOutlinedIcon />} iconPosition='start' to={"/approval"} sx={{ paddingBottom: 0 }} />
          <Tab label="Funeral Director" disableRipple component={Link} icon={<PersonOutlineOutlinedIcon />} iconPosition='start' to={"/funeral-director"} sx={{ paddingBottom: 0 }} />
          <Tab label="Sales" disableRipple icon={<ShoppingCartOutlinedIcon />} iconPosition='start' onClick={handleGotoVendure} sx={{ paddingBottom: 0 }} />
        </Tabs>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} align="right">
          <Button className='btnLinks' onClick={handleNavigate}><span style={{ color: value === -1 ? 'var(--TLC-gray)' : 'var(--TLC-bodytxt)' }}>Profile</span></Button>
          <Button className='btnLinks' onClick={handleLogout}>Logout</Button>
        </Typography>
      </Box>
    </>

  )
}

export default Navbar