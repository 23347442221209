import { restApi } from '../../api/restApi'
import { setFunerals, setSettings } from './dashboardSlice';
import { funeralDashboard } from '../../type';

type FuneralsResponse = {
  result: funeralDashboard[]
  checklist: any[]
}

export const dashboardRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getFunerals: builder.query({
      providesTags: [`dashboard`],
      query: () => ({
        url: '/api/app-template/funeral',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setFunerals(data))
        } catch (error) {}
      },
    }),
    getUserSettings: builder.query({
      providesTags: [`settings`],
      query: () => ({
        url: '/api/app-template/funeral/user-settings',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSettings(data))
        } catch (error) {}
      },
    }),
    deleteFuneral: builder.mutation({
      invalidatesTags: ['dashboard'],
      query: (payload) => ({
        url: '/api/app-template/funeral/delete',
        method: 'POST',
        body: payload
      })
    }),
    archiveFuneral: builder.mutation({
      invalidatesTags: ['dashboard'],
      query: (payload) => ({
        url: '/api/app-template/funeral/archive',
        method: 'POST',
        body: payload
      })
    }),
    updateUserSettings: builder.mutation({
      invalidatesTags: ['settings'],
      query: (payload) => ({
        url: '/api/app-template/funeral/user-settings',
        method: 'POST',
        body: payload
      })
    }) 
  }),
})

export const { useGetFuneralsQuery, useDeleteFuneralMutation, useArchiveFuneralMutation, useGetUserSettingsQuery, useUpdateUserSettingsMutation } = dashboardRestApi