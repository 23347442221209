import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import styles from './AccountCreation.module.css'
import Stepper from '../../components/base/Stepper/Stepper';
import Details from '../../components/Account/AccountSteps/Details';
// import PageTemplate from '../../components/Account/AccountSteps/PageTemplate';
// import MasterGuestList from '../../components/Account/AccountSteps/MasterGuestList';
// import MasterProductlist from '../../components/Account/AccountSteps/MasterProductlist';
// import EventProductList from '../../components/Account/AccountSteps/EventProductList';
import Obituary from '../../components/Account/AccountSteps/Obituary';
import Paynow from '../../components/Account/AccountSteps/Paynow';
import Messages from '../../components/Account/AccountSteps/Messages';
import EventAdd from '../../components/Account/AccountSteps/EventAdd';
import Checklist from '../../components/Account/AccountSteps/Checklist';
import Broadcast from '../../components/Account/AccountSteps/Broadcast';
import { getAccountState } from '../../features/account/accountSlice';
import { useUpdateAccountMutation } from '../../features/account/accountRestApi';
import { resetState } from '../../features/account/accountSlice';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


interface StepContainerProps {
  step: number
  handleStepper: (step: number) => void
}

const steps = [
  'Details of Deceased',
  // 'Page Template',
  // 'Create Master Guest List',
  // 'Create Master Product List',
  'Create Event',
  // 'Choose Products',
  'Obituary',
  'PayNow & Images',
  'Messages',
  'Checklist', 'Share & Broadcast'
];

const header = [
  'Create New Account',
  // 'Page Template',
  // 'Create Master Guest List',
  // 'Master Product List',
  'Create Event',
  // 'Choose Products',
  'Obituary',
  'PayNow & Images',
  'Condolences Messages', 'Checklist',
  'Share & Broadcast'
]

function StepContainer(props: StepContainerProps) {
  switch (props.step) {
    case 0:
      return (<Details handleStepper={props.handleStepper} />)
    // case 1:
    //   return (<PageTemplate handleStepper={props.handleStepper} />)
    // case 2:
    //   return (<MasterGuestList handleStepper={props.handleStepper} />)
    // case 3:
    //   return (<MasterProductlist handleStepper={props.handleStepper} />)
    case 1:
      return (<EventAdd handleStepper={props.handleStepper} />)
    // case 5:
    //   return (<EventProductList handleStepper={props.handleStepper} />)
    case 2:
      return (<Obituary handleStepper={props.handleStepper} />)
    case 3:
      return (<Paynow handleStepper={props.handleStepper} />)
    case 4:
      return (<Messages handleStepper={props.handleStepper} />)
    case 5:
      return (<Checklist handleStepper={props.handleStepper} />)
    case 6:
      return (<Broadcast handleStepper={props.handleStepper} />)
    default:
      return (<div>Default</div>)
  }
}

function AccountCreation() {
  const dispatch = useAppDispatch()
  const accountState = useAppSelector(getAccountState)
  const [activeStep, setActiveStep] = useState(0)
  const [updateAccount] = useUpdateAccountMutation()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(resetState(null))
  }, [])

  const handleStepper = (step: number) => {
    setActiveStep(step)
  }

  const handleClick = (step: number) => {
    if (activeStep != 0) {
      if (accountState.details.isCompleted === 0 && accountState.details.step < step) {
        updateAccount({ funeralId: accountState.details.id, query: { step: step } })
      }
      setActiveStep(step)
    }
  }

  const skip = () => {
    let current = activeStep
    let newStep = current + 1
    if (accountState.details.isCompleted === 0 && accountState.details.step < newStep) {
      updateAccount({ funeralId: accountState.details.id, query: { step: newStep } })
    }
    if (steps.length === current + 1) {
      navigate('/dashboard')
    } else {
      setActiveStep(newStep)
    }
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
        <Grid item md={10}>
          <div className={styles.header}>{header[activeStep]}</div>
        </Grid>
        <Grid item md={1}>
          {
            activeStep !== 10 && activeStep > 0 ? (
              <Link onClick={skip} style={{ cursor: 'pointer' }}>Skip</Link>
            ) : ('')
          }

        </Grid>
      </Grid>
      <Typography component={"div"} variant={"body2"} className="hr"></Typography>
      <Stepper steps={steps} active={activeStep} handleClick={handleClick} sx={{ mt: 2 }} />
      <StepContainer step={activeStep} handleStepper={handleStepper} />
    </>
  )
}

export default AccountCreation