import React, { useState, useEffect } from 'react'
import { useAppSelector } from '../../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CreateEvent from '../../Event/Create/CreateEvent'
import Button from '../../base/Button/Button';
import styles from './AccountSteps.module.css'
import { getAccountState } from '../../../features/account/accountSlice';
import { useUpdateAccountMutation } from '../../../features/account/accountRestApi';


const initial = {
  type: 'Wake Funeral',
  title: '',
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  address: '',
  livestream: 'Live Stream Not Available',
  livestreamUrl: '',
  details: '',
  products: ""
}

interface EventAddProps {
  handleStepper: (step: number) => void
}

const EventAdd: React.FC<EventAddProps> = (props) => {
  const accountState = useAppSelector(getAccountState);
  const [eventList, setEventlist] = useState<any[]>([initial])
  const [updateAccount] = useUpdateAccountMutation()

  useEffect(() => {
    if(accountState.details.eventList.length > 0) {
      setEventlist(accountState.details.eventList)
    }
    
  }, [accountState.details.eventList])

  const addEvent = () => {
    setEventlist([...eventList, initial])
  }

  const nextStep = () => {
    if(accountState.details.isCompleted === 0 && accountState.details.step < 5) {
      updateAccount({funeralId: accountState.details.id, query: {step: 5}})
    }
    // props.handleStepper(5)
    props.handleStepper(2)
  }
  const prevStep = () => {
    // props.handleStepper(3)
    props.handleStepper(0)
  }

 
  return (
    <>
      {
        eventList.map((item, index) => {
          return (
            <Box key={index} className={styles.eventContainer} sx={{mb:2, mt:2}}>
              <CreateEvent index={index} eventDetails={item} funeralId={accountState.details.id} type="account"/>
            </Box>
            
          )
        })
      }
      <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
        <Grid item xs={8} md={2.2} sx={{mt: 2}}>
          <Button className='btnSecondary' sx={{width: {xs: 250, sm: 270}, mb: 4}} onClick={addEvent}>Add Event</Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="end" alignItems="center">
        {/* <Grid item xs={8} md={2.2} sx={{mt: 2}}>
          <Button className='btnPrimary' sx={{width: {xs: 250, sm: 270}, mb: 4}} onClick={prevStep}>Previous</Button>
        </Grid> */}
        <Grid item xs={8} md={2.2} sx={{mt: 2}}>
          <Button className='btnSecondary' sx={{width: {xs: 250, sm: 270}, mb: 4}} onClick={nextStep}>Next</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default EventAdd