import React from 'react'
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';

const TlcSwitch = styled(Switch)({
  '&' : {
    width: 42,
    height: 26,
    padding: 0,
    borderRadius: 20
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: 'var(--TLC-pink)',
        opacity: 1,
        border: 0,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: 'var(--TLC-pink)',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:'var(--TLC-web-grey)'
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#d2d2d4',
    opacity: 1,
    
  },
});

const CustomSwitch: React.FC<SwitchProps> = (props) => {
  return (
    <TlcSwitch {...props} />
  )
}

export default CustomSwitch