import React, { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import CustomTextField from '../../components/base/Textfield/CustomTextField';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useGetActiveUserQuery } from '../../features/user/userRestApi';
import { useUpdateActiveAdministratorMutation } from '../../features/auth/authGqlAPI';
import { useVerifyPasswordMutation } from '../../features/auth/authRestApi';
import Button from '../../components/base/Button/Button';
import Toast from '../../components/base/Toast/Toast';
import styles from './Profile.module.css'

function Profile() {
  const { data, isLoading } = useGetActiveUserQuery(null)
  const [user, setUser] = useState({
    id: 0,
    email: '',
  })
  const [error, setError] = useState({
    isError: false,
    errMSg: ''
  })
  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [cfmPassword, setCfmPassword] = useState('')
  const [currPassword, setCurrPassword] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCfmPassword, setShowCfmPassword] = useState(false);
  const [showCurrPassword, setShowCurrPassword] = useState(false);
  const handleToggleNewPassword = () => setShowNewPassword(showNewPassword => !showNewPassword);
  const handleToggleCfmPassword = () => setShowCfmPassword(showCfmPassword => !showCfmPassword);
  const handleToggleCurrPassword = () => setShowCurrPassword(showCurrPassword => !showCurrPassword);

  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })
  const [resetFail, setResetFail] = useState({
    error: false,
    message: ''
  })

  const [resetPassword] = useUpdateActiveAdministratorMutation()
  const [verifyPassword] = useVerifyPasswordMutation()

  useEffect(() => {
    if (data && !isLoading) {
      setUser({
        id: data.id,
        email: data.email
      })
    }
  }, [data, isLoading])

  const handleSave = async () => {
    const isPass = validate()

    if (isPass) {
      setLoading(true)
      try {
        //verify current password is correct
        let rv0 = await verifyPassword({ password: currPassword }).unwrap()
        if (rv0.result) {
          let body = {
            password: newPassword
          }
          const rv = await resetPassword(body).unwrap()
          if (rv) {
            setToast({
              ...toast,
              type: 'success',
              message: 'Update Password Successfully!',
              open: true
            })
            setResetFail({
              error: false,
              message: ''
            })
            clearFields()
          }
        } else {
          setResetFail({
            error: true,
            message: 'Current password is incorrect!'
          })

        }

        setLoading(false)
      } catch (e: any) {
        setToast({
          ...toast,
          type: 'error',
          message: e.data.message || e.toString(),
          open: true
        })

        setLoading(false)
      }
    }
  }

  const validate = () => {
    if (!newPassword || !cfmPassword || !currPassword) {
      setResetFail({
        error: true,
        message: 'Please input all password fields!'
      })
      return false
    }
    if (newPassword !== cfmPassword) {
      setResetFail({
        error: true,
        message: 'New password and confirm password does not match'
      })
      return false
    }

    return true
  }

  const clearFields = () => {
    setNewPassword('')
    setCfmPassword('')
    setCurrPassword('')
  }

  return (
    <Box sx={{ mt: 2, px: 2 }}>
      <Toast {...toast} />
      <Grid container spacing={2} sx={{ mb: 4 }} justifyContent="space-between" alignItems="center">
        <Grid item>
          <div className={styles.header}>Profile</div>
        </Grid>
      </Grid>

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>Email</InputLabel>
            <CustomTextField sx={{ width: { xs: '250px', lg: '400px' }, mb: 2 }} disabled={true} value={user.email} id="email" name="email" size='small' />
          </Grid>

          <Grid item xs={12}>
            <InputLabel sx={{ fontSize: '18px' }}>Change Password</InputLabel>
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor="newPassword">Current Password</InputLabel>
            <CustomTextField sx={{ width: { xs: '250px', lg: '400px' }, mb: 2 }} id="currPassword" name="currPassword" value={currPassword} placeholder="Current Password"
              onChange={(event) => setCurrPassword(event.target.value)}
              size='small'
              type={showCurrPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton
                    className={styles.icon}
                    aria-label="toggle password visibility"
                    onClick={handleToggleCurrPassword}
                  >
                    {showCurrPassword ? <VisibilityOffIcon sx={{ color: 'var(--TLC-web-grey)' }} /> : <VisibilityIcon sx={{ color: 'var(--TLC-web-grey)' }} />}
                  </IconButton>
                )
              }} className={`${styles.input} ${styles.password}`} />
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor="newPassword">New Password</InputLabel>
            <CustomTextField sx={{ width: { xs: '250px', lg: '400px' }, mb: 2 }} id="newPassword" name="newPassword" value={newPassword} placeholder="New Password"
              onChange={(event) => setNewPassword(event.target.value)}
              size='small'
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton
                    className={styles.icon}
                    aria-label="toggle password visibility"
                    onClick={handleToggleNewPassword}
                  >
                    {showNewPassword ? <VisibilityOffIcon sx={{ color: 'var(--TLC-web-grey)' }} /> : <VisibilityIcon sx={{ color: 'var(--TLC-web-grey)' }} />}
                  </IconButton>
                )
              }} className={`${styles.input} ${styles.password}`} />
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor="cfmPassword">Confirm Password</InputLabel>
            <CustomTextField sx={{ width: { xs: '250px', lg: '400px' } }} id="cfmPassword" name="cfmPassword" value={cfmPassword} placeholder="Confirm Password"
              onChange={(event) => setCfmPassword(event.target.value)}
              size='small'
              type={showCfmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton
                    className={styles.icon}
                    aria-label="toggle password visibility"
                    onClick={handleToggleCfmPassword}
                  >
                    {showNewPassword ? <VisibilityOffIcon sx={{ color: 'var(--TLC-web-grey)' }} /> : <VisibilityIcon sx={{ color: 'var(--TLC-web-grey)' }} />}
                  </IconButton>
                )
              }} className={styles.input} />
          </Grid>

          <Grid item xs={12} >
            {
              resetFail.error ? <Box sx={{ ml: 1 }} className={styles.error}>{resetFail.message}</Box> : <Box sx={{ ml: 2, height: '30px' }}></Box>
            }
            <Button variant="contained" className="btnSecondary" sx={{ width: { xs: '250px', lg: '400px' } }} loading={loading} onClick={handleSave}>Save</Button>
          </Grid>

        </Grid>
      </Box>
    </Box>
  )
}

export default Profile