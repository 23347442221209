import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: '2px solid #545454'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 0
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  '&': {
    borderBottom: '2px solid #545454',
    borderTop: '2px solid #545454'
  },
  '& tr:not(:last-child)': {
    borderBottom: '2px solid #CCCCCC',
  },
}));

export const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  '& ': {
    color: 'var(--TLC-bodytxt)'
  },
  '&.Mui-active': {
    color: 'var(--TLC-gray)'
  },
  '&.Mui-active .MuiTableSortLabel-icon': {
    color: 'var(--TLC-gray)'
  }
}));
