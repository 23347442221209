import React, { useState, useEffect } from 'react'
import { useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Pagination from '../base/Pagination/Pagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import { StyledTableBody } from '../base/Table/CustomTable';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../base/Select/CustomSelect';
import CustomSearch from '../base/Textfield/CustomSearch';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import GuestTable from './GuestTable';
import { CheckedIcon, Icon, IndeterminateIcon } from '../base/Checkbox/CustomCheckbox';
import { getAccountState } from '../../features/account/accountSlice';
import { useGetMainGuestlistQuery } from '../../features/guestlist/guestlistRestApi';
import { useGetSpecificGuestlistQuery, useGetAvailableListQuery } from '../../features/guestlist/guestlistRestApi';
import Guestlist from '../../pages/guest/Guestlist';
import { formatDate } from '../../constants';

import styles from './Guestlist.module.css'
import Button from '../base/Button/Button';

interface MainlistProps {
  toggleMasterlist: (toggle: boolean) => void
  onSave: (list: any) => void
}

interface TableHeadProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  onRequestSearch: (value: string) => void;
}

const actions = [
  'Download',
  'Delete',
]

function TableCustomHead (props: TableHeadProps) {
  const { onSelectAllClick, onRequestSearch, numSelected, rowCount } = props;
  const [action, setAction] = useState('Delete');
  
  const handleActionChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setAction(value);
  };

  const requestSearch = (event: any) => {
    onRequestSearch(event.target.value)
   
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{paddingLeft:0, borderBottom: 0}}>
            <Checkbox
              icon ={<Icon />}
              checkedIcon={<CheckedIcon />}
              indeterminateIcon={<IndeterminateIcon/>}
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
        </TableCell>
        <TableCell colSpan={7} sx={{borderBottom: 0}}>
          <Grid container spacing={2} justifyContent={'space-between'}>
              <Grid item xs={12} md={6}>
                <span style={{fontWeight: 400}}>Action:</span>
                <CustomSelect
                  sx={{width: {xs: '150px', sm: '250px'}, mx:2}}
                  className={styles.input}
                  size='small'
                  labelId="action"
                  value={action}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  onChange={handleActionChange}
                  input={<OutlinedInput />}
                >
                  {actions.map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: 'var(--TLC-web-grey)',
                          '&:hover': {
                            backgroundColor: 'var(--TLC-web-grey)',
                          },
                        },
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </CustomSelect>
                <Button className='btnTransparent' sx={{my: 0,width: {xs: '150px', sm: '200px'}}}>Submit</Button>
              </Grid>

              <Grid item >
                <CustomSearch 
                  placeholder='Search'
                  sx={{width:  {xs: '150px', sm: '300px'}, mr:2}}
                  onChange={requestSearch}
                  className={styles.input}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon style={{color: 'var(--TLC-web-grey)'}}/>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button className='btnTransparent'  sx={{my: 0,width: {xs: '150px', sm: '200px'},visibility: 'hidden'}}>Download List</Button>
              </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableHead> 
  )
}

const Masterlist: React.FC<MainlistProps> = (props) => {
  const accountState = useAppSelector(getAccountState)
  const { id } = accountState.details
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useState('')
  const [selected, setSelected] = useState< any[]>([]);
  const [filtered, setFiltered] = useState< any[]>([]);
  //const {data, isLoading} = useGetMainGuestlistQuery(accountState.details.id, { refetchOnMountOrArgChange: true })

  //DropDown for selecting guestlist
  const initalList = [
    {eventId: 0, funeralId: id, type: 'Master', startDate: null}
  ]
  const listResult = useGetAvailableListQuery(id, { refetchOnMountOrArgChange: true })
  const [list, setList] = useState<any[]>(listResult.data)
  const [selectedList, setSelectedList] = useState<any>('')
  const [eventId, setEventId] = useState<number>(0)
  const [arg, setArg] = useState({eventId: 0, funeralId: accountState.details.id})
  const { data, isLoading} = useGetSpecificGuestlistQuery(arg, {refetchOnMountOrArgChange: true})

  //set guetlist when refetch
  useEffect(() => {
    if(data && !isLoading) {
      setFiltered(data.invites)
    }
  }, [data, isLoading])

  useEffect(() => {
    if(listResult.data) {
      setList([
        {eventId: 0, funeralId: id, type: 'Master'},
        ...listResult.data
      ])
    }
    
  }, [listResult])

  useEffect(() => {
    if(list) {
      setSelectedList(list[0])
    }
  }, [list])

  /* useEffect(() => {
    if(!isLoading && data) {
      setFiltered(data)
    }
  }, [isLoading]) */

  const filteredItems = filtered.filter((item: any) => {
    let str = item.lastName
    return str.toLowerCase().includes(query.toLowerCase())
  })

  const isSelected = (id: string | number) => {
    return selected.map(x => x.id).indexOf(id) !== -1
  }

  const handleSearch = (event: any/* value: string */) => {
    setQuery(event.target.value)
    setPage(0)
  }

  const handleListChange = async (event: SelectChangeEvent) => {
    const value: any = event.target.value
    if(value.type === 'Master') {
      setEventId(0)
    }else {
      setEventId(value.eventId)
    }
    
    setSelectedList(value)
  }

  const handleChangeView = () => {
    setArg({
      ...arg,
      eventId: eventId
    })
    setSelected([])
  }

  //All checkbox
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filtered;
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  //Individual checkbox
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selected.map(x => x.id).indexOf(row.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected.push(...selected, row) 
    }
    else if (selectedIndex === 0) {
      newSelected = selected.slice(1)
    }
    else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1)
    }
    else if (selectedIndex > 0) {
      let array1 = selected.slice(0, selectedIndex)
      let array2 = selected.slice(selectedIndex + 1)
      newSelected = array1.concat(array2)
    }
    setSelected(newSelected)
  };

  //Pagination
  const totalPages = Math.ceil(filteredItems.length / rowsPerPage)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage-1);
  };

  const handleChangeRowsPerPage = (rpp: number) => {
    setRowsPerPage(rpp);
    setPage(0);
  };

  return (
    <Box >
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <div className={styles.header}>Master Guest Lists</div>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{px:0, mt:2}}>
        <Grid item sx={{px:0}}>
          <CustomSearch 
            placeholder='Search'
            sx={{width:  {xs: '150px', sm: '300px'}, mr:2}}
            onChange={handleSearch}
            className={styles.input}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon style={{color: 'var(--TLC-web-grey)'}}/>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <CustomSelect
            className={styles.input}
            sx={{width:  {xs: '150px', sm: '300px'}, mr:2}}
            size='small'
            labelId="action"
            value={selectedList || ''}
            onChange={handleListChange}
            MenuProps={{
              disableScrollLock: true,
            }}
            input={<OutlinedInput />}
          >
            {list && list.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'var(--TLC-web-grey)',
                    '&:hover': {
                      backgroundColor: 'var(--TLC-web-grey)',
                    },
                  },
                }}
              >
                {item.type + ' ' + formatDate(item.startDate, 'DD MMMM YYYY')}
              </MenuItem>
            ))}
          </CustomSelect>
          <Button className='btnTransparent'  sx={{my: 0,width: {xs: '150px', sm: '200px'}, ml:2}} onClick={handleChangeView}>View</Button>
        </Grid>
      </Grid>
      <TableContainer style={{minHeight: '300px'}}>
      <Table>
        {/* <TableCustomHead 
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          onRequestSearch={handleSearch}
          rowCount={filtered.length}
        /> */}
        <TableHead>
          <TableRow>
            <TableCell sx={{padding: 0}}>
            <Checkbox
              icon ={<Icon />}
              checkedIcon={<CheckedIcon />}
              indeterminateIcon={<IndeterminateIcon/>}
              color="primary"
              indeterminate={selected.length > 0 && selected.length < filtered.length}
              checked={filtered.length > 0 && selected.length === filtered.length}
              onChange={handleSelectAllClick} />
            </TableCell>
            <TableCell sx={{width: {xs: 200}}}>
            Name
            </TableCell>
            <TableCell sx={{width: {xs: 200}}}>
              Email
            </TableCell>
            <TableCell sx={{width: {xs: 150}}} className={styles.tableheader}>Phone Number</TableCell>
            <TableCell sx={{width: {xs: 150}}} className={styles.tableheader}>Relationship</TableCell>
            <TableCell sx={{width: {xs: 100}}} colSpan={3} className={styles.tableheader}>In Event Guest Lists</TableCell>
          </TableRow>
        </TableHead>
        <StyledTableBody>
          {filteredItems
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: any) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `eventlist-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell sx={{padding: 0}}>
                      <Checkbox
                        icon ={<Icon />}
                        checkedIcon={<CheckedIcon />}
                        onClick={(event) => handleClick(event, row)}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 300}}}>{row.lastName}</TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 300}}}>{row.email}</TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 500}}}>{row.phoneNumber}</TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 500}}}>{row.relationship}</TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 500}}}>{row.eventCount}</TableCell>
                  </TableRow>
                )
              })
          }
        </StyledTableBody>
      </Table>
      {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filtered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
    </TableContainer>
    <Pagination
        sx={{mt:3}}
        variant="outlined" shape="rounded"
        count={totalPages}
        page={page+1}
        onChange={handleChangePage} 
        handleViewChange={handleChangeRowsPerPage}
      />
    <Grid container spacing={2} sx={{mt:2}} justifyContent={'space-between'}>
      <Grid item xs={12} md={4}>
        <Button className='btnSecondary' sx={{width: {xs: '250px', sm: '350px'}}} onClick={() => props.toggleMasterlist(false)}>Back</Button>
      </Grid>
      <Grid item>
         <Button className='btnPrimary' sx={{width: {xs: '250px', sm: '350px'}}} onClick={() => props.onSave(selected)}>Save to Event Guest List</Button>
      </Grid>
    </Grid>
   
    </Box>
   /*  <Box className={styles.container} sx={{mb:2, mt:2}}>
      <div className={styles.label}>Master Guest List</div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{paddingLeft:0}}>
                <Checkbox
                  icon ={<Icon />}
                  checkedIcon={<CheckedIcon />}
                  indeterminateIcon={<IndeterminateIcon/>}
                  color="primary"
                  indeterminate={selected.length > 0 && selected.length < filtered.length}
                  checked={filtered.length > 0 && selected.length === filtered.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Email
              </TableCell>
              <TableCell>
                Phone Number
              </TableCell>
              <TableCell>
                Relationship
              </TableCell>
              <TableCell>
                In Events Guest List
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              filteredItems
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item,index) => {
                const isItemSelected = isSelected(item.id);
                const labelId = `masterguestlist-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={item.id}
                    selected={isItemSelected}
                  >
                    <TableCell sx={{padding: 0}}>
                      <Checkbox
                        icon ={<Icon />}
                        checkedIcon={<CheckedIcon />}
                        onClick={(event) => handleClick(event, item)}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 300}}}>{item.firstName + ' ' + item.lastName}</TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 300}}}>{item.email}</TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 500}}}>{item.phoneNumber}</TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 500}}}>{item.relationship}</TableCell>
                    <TableCell sx={{width: {xs: 200, sm: 500}}}>0</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filtered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Button className='btnPrimary' onClick={() => props.onSave(selected)}>Save to Event Guest List</Button>
    </Box> */
  )
}

export default Masterlist