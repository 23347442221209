import React, { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { useParams, useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '../../base/Button/Button';
import Share from '../../Share/Share';
import styles from 'EventSteps.module.css'

import { getAccountState } from '../../../features/account/accountSlice';
import { getEventState } from '../../../features/event/eventSlice';

interface ShareProps {
  handleStepper: (step: number) => void
}

const EventShare: React.FC<ShareProps> = (props) => {
  const { funeralId, eventId, id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const accountState = useAppSelector(getAccountState)
  const eventState = useAppSelector(getEventState)

  useEffect(() => {
    //console.log(accountState)
    //console.log(eventState)
  }, [accountState, eventState])

  const prevStep = () => {
    props.handleStepper(2)
  }

  const onDone = () => {
    let actId = id || funeralId
    navigate(`/account/event/${actId}`)
  }
  
  return (
    <>
      <Box>
        <Share account={accountState.details} eventlist={eventState.eventlist}/>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{mt:2}}>
          <Grid item xs={6} md={2.2} sx={{mt: 2}}>
            <Button className='btnPrimary' sx={{width: {xs: 200, sm: 300}, mb: 4}} onClick={prevStep}>Previous</Button>
          </Grid>
          <Grid item xs={8} md={2.2} sx={{mt: 2}}>
            <Button className='btnPrimary' sx={{width: {xs: 250, sm: 270}, mb: 4}} onClick={onDone}>Done</Button>
          </Grid>
        </Grid>
      </Box>

    </>
  )
}

export default EventShare