import React, { useCallback, useState } from 'react'
import styles from './FuneralDirectorEdit.module.css'
import { Box, Card, Grid, InputLabel, Typography } from '@mui/material'
import Toast from '../../base/Toast/Toast'
import CustomTextField from '../../base/Textfield/CustomTextField'
import Button from '../../base/Button/Button'
import { debounce, validateDirectorName } from '../../../constants'
import { useAddFuneralDirectorMutation, useUpdateFuneralDirectorMutation } from '../../../features/funeralDirector/funeralDirectorRestApi'

interface EditDirectorProps {
    funeralDirector: any
    handleCloseModal: () => any
}

const FuneralDirectorEdit: React.FC<EditDirectorProps> = ({ funeralDirector, handleCloseModal }) => {

    const [toast, setToast] = useState({
        message: "",
        type: "",
        open: false,
        handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
    })
    const [funeralDirectorForm, setFuneralDirectorForm] = useState(funeralDirector)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({
        name: false,
    })

    // post
    const [addFuneralDirector] = useAddFuneralDirectorMutation()
    const [editFuneralDirector] = useUpdateFuneralDirectorMutation()

    // validation
    const validateSingleField = (obj: { key: string; value: string }) => {
        const { key, value } = obj
        let rv
        if (key === "name") {
            if (value === '') {
                rv = false
            } else {
                rv = validateDirectorName(value)
            }
        }

        if (!rv) {
            setErrors((prev) => ({ ...prev, [key]: true }))
        } else {
            setErrors((prev) => ({ ...prev, [key]: false }))
        }
    }

    const debounceHandler = useCallback(debounce(validateSingleField), [])

    const handleChange = (e: any) => {
        let key = e.target.id
        let value = e.target.value

        setFuneralDirectorForm({
            ...funeralDirectorForm,
            [key]: value
        })

        debounceHandler({ key, value: value.trim() })
    }

    const updateFuneralDirector = async () => {
        setLoading(true)
        try {
            if (funeralDirectorForm.name === '') {
                setErrors({ ...errors, name: true })
                throw new Error('Name field is required.')
            }

            await editFuneralDirector(funeralDirectorForm).unwrap()

            setToast({
                ...toast,
                open: true,
                message: 'Successfully updated a funeral director.',
                type: 'success'
            })

            setTimeout(() => {
                setLoading(false)
                handleCloseModal()
            }, 1000)
        } catch (e: any) {
            setLoading(false)
            setToast({
                ...toast,
                open: true,
                message: e.data.message ? e.data.message : e.message,
                type: 'error'
            })
        }
    }

    const handleAddFuneralDirector = async () => {
        setLoading(true)
        try {
            if (funeralDirectorForm.name === '') {
                setErrors({ ...errors, name: true })
                throw new Error('Name field is required.')
            }

            await addFuneralDirector({ name: funeralDirectorForm.name }).unwrap()

            setToast({
                ...toast,
                open: true,
                message: 'Successfully added new funeral director.',
                type: 'success'
            })

            setTimeout(() => {
                setLoading(false)
                handleCloseModal()
            }, 1000)

        } catch (e: any) {
            setLoading(false)
            setToast({
                ...toast,
                open: true,
                message: e.data.message ? e.data.message : e.message,
                type: 'error'
            })
        }
    }

    return (
        <>
            <Toast {...toast} />
            <Card className={styles.card} sx={{ p: 4, width: { xs: "250px", lg: "365px" } }}>
                <Box>
                    <Typography className={styles.modalTitle}>
                        {
                            funeralDirectorForm.id === 0 ?
                                'Add Funeral Director'
                                :
                                'Edit Funeral Director'
                        }
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ height: "100px" }}>
                            <InputLabel error={errors.name}>
                                <span className={styles.mandatory}>Name</span>{" "}
                            </InputLabel>
                            <CustomTextField
                                error={errors.name}
                                helperText={errors.name ? "Requires a valid name" : ""}
                                sx={{ width: { sm: "250px", lg: "365px" }, mb: 2 }}
                                value={funeralDirectorForm.name}
                                onChange={handleChange}
                                id="name"
                                name="name"
                                placeholder="Name"
                                size="small"
                                className={styles.input}
                            />
                        </Grid>

                        <Grid item xs={12} className={styles.isflex}>
                            <Button
                                className="btnPrimary"
                                sx={{ width: { xs: "250px", lg: "365px" }, mb: 2 }}
                                loading={loading}
                                onClick={() =>
                                    funeralDirectorForm.id === 0 ?
                                        handleAddFuneralDirector()
                                        :
                                        updateFuneralDirector()
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </>
    )
}

export default FuneralDirectorEdit