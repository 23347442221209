import { restApi } from '../../api/restApi'
import { setAuthToken } from './authSlice'

export const authRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    backendLogin: builder.mutation({
      query: (payload) => ({
        url: '/api/auth/login',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAuthToken(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    healthcheck: builder.query({
      query: () => ({
        url: '/api/healthcheck',
        method: 'GET',
      }),
    }),
    checkAdminEmail: builder.mutation({
      query: (payload) => ({
        url: `/api/app-template/auth/email`,
        method: 'POST',
        body: payload
      }),
    }),
    verifyPassword: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/auth/verifyPassword',
        method: 'POST',
        body: payload,
      })
    }),
    verifyToken: builder.query({
      query: () => ({
        url: '/api/app-template/auth/vendureToken',
        method: 'GET',
      }),
    }), 
  }),
})

export const { 
  useBackendLoginMutation, 
  useHealthcheckQuery, 
  useCheckAdminEmailMutation, 
  useVerifyPasswordMutation, 
  useVerifyTokenQuery,
} = authRestApi