import React, { useState, useEffect, useCallback } from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import CustomTextField from '../../base/Textfield/CustomTextField';
import DragAndDrop from '../../base/Upload/DragAndDrop';
import Button from '../../base/Button/Button';
import styles from './AccountSteps.module.css'
import { getAccountState, updateState } from '../../../features/account/accountSlice';
import { useUpdatePaynowMutation, useUpdateAccountMutation } from '../../../features/account/accountRestApi';
import Toast from '../../base/Toast/Toast';
import { InputAdornment } from '@mui/material';
import { debounce, validateName, validatePayNowName, validateTel } from '../../../constants';

interface PaynowProps {
  handleStepper: (step: number) => void
}

const Paynow: React.FC<PaynowProps> = (props) => {
  const dispatch = useAppDispatch()
  const accountState = useAppSelector(getAccountState);
  const [payNowFile, setPayNowFile] = useState<File | string>('')
  const [bannerFile, setBannerFile] = useState<File | string>('')
  const [bankDetails, setBankDetails] = useState('')
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })

  const [details, setDetails] = useState<any>({
    payNowName: '',
    phoneNumber: '',
  })

  const [errors, setErrors] = useState({
    payNowName: false,
    phoneNumber: false
  })

  const [updatePaynow] = useUpdatePaynowMutation()
  const [updateAccount] = useUpdateAccountMutation()

  useEffect(() => {
    let str = accountState.details.bankDetails
    setBankDetails(str ? str : '')
  }, [])

  // handle File events
  const handlePaynowFile = function (e: any) {
    if (e) {
      setPayNowFile(e[0])
    } else {
      setPayNowFile('')
    }

  };
  const handleBannerFile = function (e: any) {
    if (e) {
      setBannerFile(e[0])
    } else {
      setBannerFile('')
    }
  };
  const clearPaynowFile = () => {
    setPayNowFile('')
  }
  const clearBannerFile = () => {
    setBannerFile('')
  }

  const onSave = async () => {
    try {
      let body = {
        funeralId: accountState.details.id,
        bankDetails: bankDetails
      }
      const formData = new FormData()
      formData.append('file1', payNowFile);
      formData.append('file2', bannerFile);
      formData.append('data', JSON.stringify(body));

      let rv = await updatePaynow(formData).unwrap()

      if (rv.status === 'success') {
        setToast({
          ...toast,
          message: "Information saved successfully!",
          type: 'success',
          open: true
        })

        dispatch(updateState({ bankDetails: bankDetails, banner_image: rv.banner_image, paynow_image: rv.paynow_image }))
        nextStep()
      }
    } catch (e: any) {
      setToast({
        ...toast,
        message: e.toString(),
        type: 'error',
        open: true
      })
    }
  }

  const prevStep = () => {
    // props.handleStepper(6)
    props.handleStepper(2)
  }

  const nextStep = () => {
    if (accountState.details.isCompleted === 0 && accountState.details.step < 8) {
      updateAccount({ funeralId: accountState.details.id, query: { step: 8 } })
    }
    // props.handleStepper(8)
    props.handleStepper(4)
  }

  const validateSingleField = (obj: { key: string, value: string }) => {
    const { key, value } = obj
    let rv
    if (key === 'payNowName') {
      if (value) {
        rv = validatePayNowName(value)
      } else rv = true
    }
    if (key === 'phoneNumber') {
      if (value) {
        rv = validateTel(value)
      } else rv = true

    }

    if (!rv) {
      setErrors(prev => ({ ...prev, [key]: true }))
    } else {
      setErrors(prev => ({ ...prev, [key]: false }))
    }

  }

  const debounceHandler = useCallback(
    debounce(validateSingleField)
    , []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.name
    let value = event.target.value

    setDetails({
      ...details,
      [key]: value
    })

    debounceHandler({ key, value })
  }

  return (
    <Box className={styles.container} sx={{ mt: 4 }}>
      <Toast {...toast} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <InputLabel className={styles.labelText}>Upload PayNow QR code ({payNowFile || accountState.details.paynow_image ? 1 : 0}/1)</InputLabel>
          <DragAndDrop label="paynow" filetype='.jpeg, .png' handleFile={handlePaynowFile} currFileName={accountState.details.paynow_image} file={payNowFile} clearUpload={clearPaynowFile} />
        </Grid>

        {/* <Grid
          item
          xs={12}
        >
          <Grid item xs={12} md={4}>
            <InputLabel>
              Phone Number <span className='optional'>(Optional)</span>
            </InputLabel>
            <CustomTextField
              error={errors.phoneNumber}
              helperText={errors.phoneNumber ? 'Requires a valid phone number' : ''}
              sx={{ width: { xs: '100%' }, mb: 4 }}
              value={details.phoneNumber}
              onChange={handleChange}
              id='phoneNumber'
              name='phoneNumber'
              size='small'
              className={styles.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>+65</span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel
              error={errors.payNowName}
            >
              <span className={styles.mandatory}>
                Paynow Name <span className='optional'>(Optional)</span>
              </span>
            </InputLabel>
            <CustomTextField
              error={errors.payNowName}
              helperText={errors.payNowName ? 'Requires a valid name' : ''}
              sx={{ width: { xs: '100%', md: '100%' }, height: 40, mb: 4 }}
              value={details.payNowName}
              onChange={handleChange}
              id="payNowName"
              name="payNowName"
              placeholder='Paynow Name' size='small'
              className={styles.input}
            />
          </Grid>
        </Grid> */}

        <Grid item xs={12}>
          <InputLabel
            className={styles.labelText}>
            Bank Details
          </InputLabel>
          <CustomTextField
            className={styles.input}
            sx={{ width: { xs: '100%' } }}
            value={bankDetails.replace(/<br \/>?/g, '\n')}
            onChange={(event) => setBankDetails(event.target.value.replace(/\n\r?/g, '<br />'))}
            multiline
            rows={4}
            placeholder="Bank details to donate">
          </CustomTextField >
        </Grid>

        {/* <Grid item xs={12} md={4}>
          <InputLabel className={styles.labelText}>Upload feature banner image ({bannerFile || accountState.details.banner_image ? 1 : 0}/1)</InputLabel>
          <DragAndDrop label="banner" filetype='.jpeg, .png' handleFile={handleBannerFile} currFileName={accountState.details.banner_image} file={bannerFile} clearUpload={clearBannerFile}/>
        </Grid> */}
      </Grid>
      {/* <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
        <Grid item xs={8} md={2.2} sx={{mt: 2}}>
          <Button className='btnSecondary' sx={{width: {xs: 250, sm: 300}, mb: 4}} onClick={onSave}>Save</Button>
        </Grid>
      </Grid> */}
      <Grid container spacing={2} justifyContent="end" alignItems="center" sx={{ mt: 2 }}>
        {/* <Grid item xs={8} md={2.2} sx={{mt: 2}}>
          <Button className='btnPrimary' sx={{width: {xs: 250, sm: 300}, mb: 4}} onClick={prevStep}>Previous</Button>
        </Grid> */}
        <Grid item xs={8} md={2.2} sx={{ mt: 2 }}>
          <Button className='btnSecondary' sx={{ width: { xs: '100%', sm: 300 }, mb: 4 }} onClick={onSave}>Next</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Paynow