import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '../../base/Button/Button';
import InputLabel from '@mui/material/InputLabel';
import CustomTextField from '../../base/Textfield/CustomTextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../../base/Select/CustomSelect';
import Toast from '../../base/Toast/Toast';
import styles from './EventSteps.module.css'
import { relationships, debounce, validateEmail, validateName, validateTel } from '../../../constants';
import { useUpdateRFMMutation } from '../../../features/account/accountRestApi';

interface RecipientFormProps {
  rfmList: any[]
  onNext: () => void
}

const RecipientForm: React.FC<RecipientFormProps> = (props) => {
  const { funeralId } = useParams()
  const { rfmList, onNext } = props
  const [ form, setForm] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    relationship:"-",
    address: ''
  })
  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    postalCode: ''
  })
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false
  })
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => {setToast({...toast, open: false})}
  })

  const [updateRFM] = useUpdateRFMMutation()

  const validateSingleField = (obj: {key: string, value: string}) => {
    const { key, value} = obj
    let rv
    if(key === 'firstName' || key === 'lastName') {
      rv = validateName(value)
    }
    if(key === 'phoneNumber') {
      if(value) {
        rv = validateTel(value)
      }else rv = true
      
    }
    if(key === 'email') {
      rv = validateEmail(value)
    }

    if(!rv) {
      setErrors(prev => ({...prev, [key]: true})) 
    }else {
      setErrors(prev => ({...prev, [key]: false})) 
    }
  }

  const debounceHandler = useCallback(
    debounce(validateSingleField)
  , []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value
    
    setForm({
      ...form,
      [key]: value
    })

    debounceHandler({key, value:value.trim()})
  }

  const handleRelationshipChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    
    setForm({...form, relationship: value})
  }

  const resetForm = () => {
    setForm({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      relationship:"-",
      address: ''
    })
    setAddress({
      line1: '',
      line2: '',
      postalCode: ''
    })
  }

  const checkIfAdded = async (email: string) => {
    //check if added
    let found = rfmList.find((item) => {
      return item.email === email.trim()
    })
    if(found) {
      return true
    }
  }

  const addRFM = async () => {
    const hasError = validateRFM(form)

    if(hasError) {
      return
    }

    try {
      let found = await checkIfAdded(form.email)
      if(found) {
        setToast({
          ...toast,
          open: true,
          message: "RFM alrdy exisit!",
          type: 'error'
        })
        return
      }
      let addressString = await addAddress()
      let body = {
        type: 'add',
        funeralId: funeralId, 
        details: {...form, address: addressString}
      }
      let rv = await updateRFM(body).unwrap()
      if(rv) {
        setToast({
          ...toast,
          open: true,
          message: rv.message,
          type: 'success'
        })

        resetForm()
      }
    }catch(e) {
      console.log("addRFM", e)
    }
  }

  const addAddress = async () => {
    let str: string = ''
    if(address.line1) {
      str = address.line1
    }
    if(address.line2) {
      str += ', ' + address.line2
    }
    if(address.postalCode) {
      str += ', ' + address.postalCode
    }
    return str.trim()
  }

  const handleAddressChange =(event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value
    
    setAddress({
      ...address,
      [key]: value.trim()
    })

    debounceHandler({key, value:value.trim()})
  }

  const validateRFM = (item: any) => {
    let hasError = false
    for (const [key, value] of Object.entries(item)) {
      switch(key) {
        case "firstName": 
          if(!value) {
            setErrors(prev => ({...prev, [key]: true})) 
            hasError = true
          }else {
            errors.firstName ? hasError = true : setErrors(prev => ({...prev, [key]: false}))
          }
          break;
        case "lastName": 
          if(!value) {
            setErrors(prev => ({...prev, [key]: true})) 
            hasError = true
          }else {
            errors.lastName ? hasError = true : setErrors(prev => ({...prev, [key]: false}))
          }
          break;
        case "email": 
          if(!value) {
            setErrors(prev => ({...prev, [key]: true})) 
            hasError = true
          }else {
            errors.email ? hasError = true : setErrors(prev => ({...prev, [key]: false}))
          }
          break;
        default: 
          break;
      }
    }
    return hasError
  }

  return (
    <>
      <Toast {...toast}/>
      <Grid container spacing={2}>
        <Grid item xs={12} className={`${styles.isflex}`}>
          <Box sx={{width: '30%'}}>
            <InputLabel error={errors.firstName}><span className={styles.mandatory}>First Name </span> </InputLabel>
            <CustomTextField error={errors.firstName} helperText={errors.firstName ? 'Requires a valid name' : ''} sx={{width: {xs: '365px'}, height: 40, mb:2}} value={form.firstName} onChange={handleChange} id='firstName' name='firstName' placeholder='First Name'  size='small' className={styles.input}/>
          </Box>
          <Box sx={{width: '30%'}}>
            <InputLabel error={errors.lastName}><span className={styles.mandatory}>Last Name </span></InputLabel>
            <CustomTextField error={errors.lastName} helperText={errors.lastName ? 'Requires a valid name' : ''} sx={{width: {xs: '365px'}, height: 40, mb:2}} value={form.lastName} onChange={handleChange} id='lastName' name='lastName' placeholder='Last Name'  size='small' className={styles.input}/>
          </Box>
          <Box sx={{width: '30%'}}>
            <InputLabel>Relationship to Deceased <span className='optional'></span></InputLabel>
            <CustomSelect
              className={styles.input}
              sx={{width:  {xs: '365px', md: '365px'}}}
              size='small'
              labelId="action"
              value={form.relationship}
              defaultValue='-'
              onChange={handleRelationshipChange}
            >
              {relationships.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'var(--TLC-web-grey)',
                      '&:hover': {
                        backgroundColor: 'var(--TLC-web-grey)',
                      },
                    },
                    
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </CustomSelect>
          </Box>
        </Grid>

        <Grid item xs={12} className={`${styles.isflex}`}>
          <Box sx={{width: '30%'}}>
            <InputLabel error={errors.email}><span className={styles.mandatory}>Email </span> </InputLabel>
            <CustomTextField error={errors.email} helperText={errors.email ? 'Requires a valid email' : ''} sx={{width: {xs: '365px'}, mb:4}} value={form.email} onChange={handleChange} id='email' name='email' placeholder='Email'  size='small' className={styles.input}/>
          </Box>
          <Box sx={{width: '30%'}}>
            <InputLabel>Phone Number <span className='optional'></span></InputLabel>
            <CustomTextField 
              error={errors.phoneNumber} helperText={errors.phoneNumber ? 'Requires a valid phone number' : ''} 
              sx={{width: {xs: '365px'}, mb:4}} value={form.phoneNumber} onChange={handleChange} 
              id='phoneNumber' name='phoneNumber'
              size='small' className={styles.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <span style={{color: 'rgba(0, 0, 0, 0.54)'}}>+65</span>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sx={{mb:4}} className={`${styles.isflex}`} style={{flexDirection: 'column'}}>
          <InputLabel>Address <span className='optional'></span></InputLabel>
          <CustomTextField sx={{width: {xs: '365px'}, mb:1}} value={address.line1} onChange={handleAddressChange} id='line1' name='line1' placeholder='Address Line 1'  size='small' className={styles.input}/>

          <CustomTextField sx={{width: {xs: '365px'}, mb:1}} value={address.line2} onChange={handleAddressChange} id='line2' name='line2' placeholder='Address Line 2'  size='small' className={styles.input}/>

          <CustomTextField sx={{width: {xs: '200px'}, mb:4}} value={address.postalCode} onChange={handleAddressChange} id='postalCode' name='postalCode' placeholder='Postal Code'  size='small' className={styles.input}/>
          
          
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{mt:4}}>
        <Grid item xs={6} md={3} sx={{mt: 2}}>
          <Button className='btnTransparent' sx={{width: {xs: 200, sm: 250}, mb: 4}} onClick={addRFM}>Add Another RFM</Button>
        </Grid>
       
        <Grid item xs={6} md={3} sx={{mt: 2}}>
          <Button className='btnSecondary' sx={{width: {xs: 200, sm: 300}, mb: 4}} onClick={onNext}>Next</Button>
        </Grid>
      </Grid>
      
    </>
  )
}

export default RecipientForm