import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './EventCreate.module.css'
import Stepper from '../../../components/base/Stepper/Stepper';
import CreateEventPage from '../../../components/Event/EventSteps/CreateEventPage';
import Productslist from '../../../components/Event/EventSteps/Products';
import Recipient from '../../../components/Event/EventSteps/Recipient';
import EventShare from '../../../components/Event/EventSteps/EventShare';
import { getAccountState } from '../../../features/account/accountSlice';
import { resetEventState } from '../../../features/event/eventSlice';
import { useGetAccountQuery } from '../../../features/account/accountRestApi';

interface StepContainerProps {
  step: number
  handleStepper: (step: number) => void
}

const steps = [
  'Create Event',
  // 'Products',
  'Product Recipient',
  'Share'
]
const headers = [
  'Create Event',
  // 'Event Products',
  'Post Funeral Event',
  'Post Funeral Event'
]

function StepContainer(props: StepContainerProps) {
  switch (props.step) {
    case 0:
      return (<CreateEventPage handleStepper={props.handleStepper} />)
    // case 1:
    //   return (<Productslist handleStepper={props.handleStepper} />)
    case 1:
      return (<Recipient handleStepper={props.handleStepper} />)
    case 2:
      return (<EventShare handleStepper={props.handleStepper}></EventShare>)
    default:
      return (<div>Default</div>)
  }
}

function EventCreate() {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { details } = useAppSelector(getAccountState)
  const [deceased, setDeceased] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  useGetAccountQuery(id, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    dispatch(resetEventState(null))
  }, [])

  useEffect(() => {
    let name = details.lastName
    setDeceased(name)

  }, [details])

  const handleStepper = (step: number) => {
    setActiveStep(step)
  }

  const handleLabelClick = (step: number) => {
    if (activeStep !== 0) {
      setActiveStep(step)
    }
  }

  return (
    <Box sx={{ mx: 1, py: 2, px: 1 }}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <div className={styles.header}>{headers[activeStep]}</div>
        </Grid>
        <Grid item>
        </Grid>
      </Grid>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <Link className={styles.link} to="/dashboard">
          Accounts
        </Link>,
        <div>Account: <span className={styles.name}>{deceased}</span></div>
        <Link className={styles.link} to={`/account/event/${id}`}>
          Events List
        </Link>
        <Box>Create</Box>
      </Breadcrumbs>
      <Stepper steps={steps} active={activeStep} handleClick={handleLabelClick} sx={{ width: '50%', pt: 2 }} />
      <StepContainer step={activeStep} handleStepper={handleStepper} />
    </Box>
  )
}

export default EventCreate