import { gqlApi } from '../../api/graphql'
import { gql } from 'graphql-request'
import { userGqlApi } from '../user/userGqlApi'
import { logout } from '../user/userSlice'

export const authGqlApi = gqlApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (user) => ({
        body: gql`
          mutation {
            login (
              username: "${user.email}",
              password: "${user.password}",
            ) {
              ... on CurrentUser{
                id,
                identifier,
              } 
              ... on InvalidCredentialsError{
                errorCode,
                message
              }
              ... on NativeAuthStrategyError{
                errorCode,
                message
              }
              
            }
          }
        `
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(userGqlApi.endpoints.getMe.initiate(null));
        } catch (error) {}
      },
    }),
    logout: builder.mutation({
      query: () => ({
        body: gql`
          mutation {
            logout {
              success
            }
          }
        `
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let {data} = await queryFulfilled;
          if(data.logout.success) {
            dispatch(logout());
          }
        } catch (error) {}
      },
    }),
    updateActiveAdministrator: builder.mutation({
      query: (payload) => ({
        body: gql`
          mutation {
            updateActiveAdministrator (input: {
              password: "${payload.password}"
            }), {
              id
            }
          }
        `
      })
    }),
    requestPasswordReset: builder.mutation({
      query: (body) => ({
        body: gql`
          mutation {
            requestPasswordReset (
              emailAddress: "${body.emailAddress}"
            ) {
              ... on Success{
                success
              }
              ... on NativeAuthStrategyError{
                errorCode,
                message
              }
            }
          }
        `
      })
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        body: gql`
          mutation {
            resetPassword (
              token: "${body.token}",
              password: "${body.password}",
            ) {
              ...on CurrentUser {
                id
              },
              ...on PasswordResetTokenInvalidError {
                errorCode
                message
              },
              ...on PasswordResetTokenExpiredError {
                errorCode
                message
              }
            }
          }
        `
      })
    })
  }),
})

export const { useLoginMutation, useLogoutMutation, useUpdateActiveAdministratorMutation, useRequestPasswordResetMutation, useResetPasswordMutation } = authGqlApi