import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ChecklistTable from '../../Checklist/ChecklistTable';
import Button from '../../base/Button/Button';
import { getChecklistState } from '../../../features/checklist/checklistSlice';
import { getAccountState } from '../../../features/account/accountSlice';
import { useGetChecklistQuery } from '../../../features/checklist/checklistRestApi';

interface ChecklistProps {
  handleStepper: (step: number) => void
}


const Checklist: React.FC<ChecklistProps> = (props) => {
  const { details } = useAppSelector(getAccountState);
  const { checklist} = useAppSelector(getChecklistState)
  useGetChecklistQuery(JSON.stringify(details.id), { refetchOnMountOrArgChange: true })

  const prevStep = () => {
    // props.handleStepper(8)
    props.handleStepper(4)
  }

  const nextStep = () => {
    // props.handleStepper(10)
    props.handleStepper(6)
  }


  return (
    <Box sx={{mt:4, px:2}}>
      <ChecklistTable data={checklist}/>
      <Grid container spacing={2} justifyContent="end" alignItems="center">
        {/* <Grid item xs={8} md={2.2} sx={{mt: 2}}>
          <Button className='btnPrimary' sx={{width: {xs: 250, sm: 270}, mb: 4}} onClick={prevStep}>Previous</Button>
        </Grid> */}
        <Grid item xs={8} md={2.2} sx={{mt: 2}}>
          <Button className='btnSecondary' sx={{width: {xs: '100%', sm: 270}, mb: 4}} onClick={nextStep}>Next</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Checklist