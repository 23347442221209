import { restApi } from '../../api/restApi'
import { setApproval, setMsgList } from './approvalSlice';
import { approval } from '../../type';

type ApprovalResponse = {
  approvaTable: approval[]
  messageCount: any[]
}

export const approvalRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getApprovals: builder.query({
      providesTags: [`approvalTable`],
      query: () => ({
        url: '/api/app-template/approval/list',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setApproval(data))
        } catch (error) {}
      },
    }),
    getMessages: builder.query({
      providesTags: [`messages`],
      query: (funneralId) => ({
        url: `/api/app-template/approval/messages/${funneralId}`,
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setMsgList(data))
        } catch (error) {}
      },
    }),
    updateMessage: builder.mutation({
      invalidatesTags: [`messages`, 'approvalTable'],
      query: (payload) => ({
        url: '/api/app-template/approval/update',
        method: 'POST',
        body: payload,
      })
    }),
    deleteMessage: builder.mutation({
      invalidatesTags: [`messages`, 'approvalTable'],
      query: (payload) => ({
        url: '/api/app-template/approval/delete',
        method: 'POST',
        body: payload,
      })
    }),
  }),
})

export const { useGetApprovalsQuery, useGetMessagesQuery, useUpdateMessageMutation, useDeleteMessageMutation } = approvalRestApi