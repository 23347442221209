import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '../../components/base/Button/Button';
import Grid from '@mui/material/Grid';
import { InputLabel } from '@mui/material';
import CustomTextField from '../../components/base/Textfield/CustomTextField';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './Login.module.css'
import { validatePw } from '../../constants';
import Toast from '../../components/base/Toast/Toast';
import { useResetPasswordMutation } from '../../features/auth/authGqlAPI';

interface CustomFailMsgProps {
  error: any
  message: string
}

const CustomFailMsg: React.FC<CustomFailMsgProps> = (props) => {
  let { error, message } = props
  if (error) {
    return <p className={styles.error}>{message}</p>
  } else {
    return <p style={{ height: 10 }}></p>
  }
}

function ForgetPw() {
  const navigate = useNavigate()
  const useQuery = () => new URLSearchParams(useLocation().search)
  const queryUrl = useQuery()
  const [loading, setLoading] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCfmPassword, setShowCfmPassword] = useState(false);
  const [resetForm, setResetForm] = useState({
    newPassword: '',
    cfmPassword: ''
  })
  const [resetError, setResetError] = useState({
    error: false,
    message: ''
  })
  const [toast, setToast] = useState({
    open: false,
    message: '',
    type: 'success',
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })

  const [resetPw] = useResetPasswordMutation()

  const handleTogglePassword = (type: string) => {
    if (type === 'newPassword') {
      setShowNewPassword(!showNewPassword)
    } else {
      setShowCfmPassword(!showCfmPassword)
    }
  }

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (data: any) => {
    let password = data.newPassword
    let cfmPassword = data.cfmPassword
    console.log("AAA", password, cfmPassword)
    if (!password || !cfmPassword) {
      setResetError({
        error: true,
        message: "Please input all the fields"
      })
      return false
    }
    if (password) {
      let rv = validatePw(password)
      if (!rv) {
        setResetError({
          error: true,
          message: "Password need to fulfill the above requirements!"
        })
        return false
      }
    }
    if (password !== cfmPassword) {
      setResetError({
        error: true,
        message: "New password and confirm password is not the same"
      })
      return false
    }
    setResetError({
      error: false,
      message: ''
    })
    return true
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    setResetForm({
      ...resetForm,
      [key]: value.trim()
    })

    //debounceHandler({key, value})
  }

  const handleSubmit = async () => {
    setLoading(true)
    const isValidated = validate(resetForm)

    try {
      if (isValidated) {
        let body = {
          token: queryUrl.get('token'),
          password: resetForm.newPassword
        }
        let rv = await resetPw(body).unwrap()
        if (rv.resetPassword.errorCode) {
          setToast({
            ...toast,
            open: true,
            message: rv.resetPassword.message,
            type: 'error'
          })
        } else {
          setToast({
            ...toast,
            open: true,
            message: "Successfully reset password!",
            type: 'success'
          })
          navigate('/login')
        }
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log("error", e)
    }
  };

  return (
    <div className={styles.loginContainer}>
      <Toast {...toast} />
      <Box>
        <img
          src={`/images/TLC-MAIN-LOGO.png`}
          alt="The Life Celebrant"
          width={250}
          loading="lazy"
        />
      </Box>
      <Card className={styles.card} sx={{ width: { xs: '90%', md: '38%' } }}>
        <div className={styles.login}>Forgot Password</div>
        <Box component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 4 }}
        >
          {/* <InputLabel sx={{mb:2}} htmlFor="email">Email</InputLabel>
          <CustomTextField sx={{mb:4}} required id="email" name="email" placeholder='Email' fullWidth className={styles.input}/> */}

          <InputLabel sx={{ mb: 2 }} htmlFor="newPassword">New Password</InputLabel>
          <CustomTextField sx={{ mb: 1 }} id="newPassword" name="newPassword" placeholder="●●●●●●●●" fullWidth
            type={showNewPassword ? "text" : "password"} onChange={handleChange}
            InputProps={{
              endAdornment: (
                <IconButton
                  className={styles.icon}
                  aria-label="toggle password visibility"
                  onClick={() => handleTogglePassword('newPassword')}
                >
                  {showNewPassword ? <VisibilityOffIcon sx={{ color: 'var(--TLC-web-grey)' }} /> : <VisibilityIcon sx={{ color: 'var(--TLC-web-grey)' }} />}
                </IconButton>
              )
            }} className={styles.input} />

          <Box sx={{ pl: 0.5, mb: 4 }} className={styles.pwhelper}>
            Password must include
            <ul>
              <li>at least 8 characters</li>
              <li>a lowercase letter</li>
              <li>one capital letter</li>
              <li>and one number</li>
            </ul>
          </Box>

          <InputLabel sx={{ mb: 2 }} htmlFor="cfmPassword">Confirm New Password</InputLabel>
          <CustomTextField sx={{ mb: 1 }} required id="cfmPassword" name="cfmPassword" placeholder="●●●●●●●●" fullWidth
            type={showCfmPassword ? "text" : "password"} onChange={handleChange}
            InputProps={{
              endAdornment: (
                <IconButton
                  className={styles.icon}
                  aria-label="toggle password visibility"
                  onClick={() => handleTogglePassword('cfmPassword')}
                >
                  {showCfmPassword ? <VisibilityOffIcon sx={{ color: 'var(--TLC-web-grey)' }} /> : <VisibilityIcon sx={{ color: 'var(--TLC-web-grey)' }} />}
                </IconButton>
              )
            }} className={styles.input} />

          <Grid container sx={{ mb: 4 }}>
            <Grid item xs>
              {<Link to="/login" className={styles.link}>
                Go back to login page
              </Link>}
            </Grid>
          </Grid>
          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >Login</Button> */}
          <CustomFailMsg error={resetError.error} message={resetError.message} />
          <Button loading={loading} fullWidth variant="contained" className="btnPrimary" onClick={handleSubmit}>Reset</Button>
        </Box>
      </Card>
      <p className={styles.notice}> &#169; {new Date().getFullYear()} The Life Celebrant. All Rights Reserved. Powered by
        <a href="https://www.visiongroup.co/" target="_blank" rel="noopener noreferrer">VisionTech</a>.</p>
    </div>
  )
}

export default ForgetPw