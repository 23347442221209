import { restApi } from '../../api/restApi'
import { formatDate } from '../../constants'

export const guestRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableList: builder.query({
      query: (funeralId) => ({
        url: `/api/app-template/guestlist/list/${funeralId}`,
        method: 'GET',
      }),
      /* transformResponse: (response: any) => {
        let arr = response.map((item: any) => {
          let date = formatDate(item.startDate,'DD MMM YYYY')
          return item.type + '_' + item.eventId + '_' + date
        })

        return arr
      } */
    }),
    getSpecificGuestlist: builder.query({
      providesTags: ['guestlist'],
      query: (payload) => ({
        url: `/api/app-template/guestlist`,
        method: 'GET',
        params: payload
      })
    }),
    getEventGuestlist: builder.query({
      providesTags: ['guestlist'],
      query: ({eventId, funeralId}) => ({
        url: `/api/app-template/guestlist/${funeralId}/event/${eventId}`,
        method: 'GET',
      })
    }),
    getMainGuestlist: builder.query({
      providesTags: ['mainGuestlist'],
      query: (funeralId) => ({
        url: `/api/app-template/guestlist/${funeralId}`,
        method: 'GET',
      })
    }),
    getDownloadlist: builder.query({
      query: (funeralId) => ({
        url: `/api/app-template/guestlist/download/${funeralId}`,
        method: 'GET',
      })
    }),
    updateMainGuest: builder.mutation({
      invalidatesTags: ['guestlist', 'mainGuestlist'],
      query: (payload) => ({
        url: '/api/app-template/guestlist/update/main',
        method: 'POST',
        body: payload,
      })
    }),
    updateEventGuest: builder.mutation({
      invalidatesTags: ['guestlist', 'mainGuestlist'],
      query: (payload) => ({
        url: '/api/app-template/guestlist/update/event',
        method: 'POST',
        body: payload,
      })
    }),
    bulkAddGuest: builder.mutation({
      invalidatesTags: ['guestlist', 'mainGuestlist'],
      query: (payload) => ({
        url: '/api/app-template/guestlist/bulk',
        method: 'POST',
        body: payload,
      })
    }),
    bulkDeleteGuest: builder.mutation({
      invalidatesTags: ['guestlist', 'mainGuestlist'],
      query: (payload) => ({
        url: '/api/app-template/guestlist/bulk/delete',
        method: 'POST',
        body: payload,
      })
    }),
    addCsvGuest: builder.mutation({
      invalidatesTags: ['guestlist', 'mainGuestlist'],
      query: (payload) => ({
        url: '/api/app-template/guestlist/csv',
        method: 'POST',
        body: payload,
      })
    }),
    editGuest: builder.mutation({
      invalidatesTags: ['guestlist', 'mainGuestlist'],
      query: (payload) => ({
        url: '/api/app-template/guestlist/edit',
        method: 'POST',
        body: payload,
      })
    }),
  })
})

export const { 
  useGetAvailableListQuery, 
  useGetSpecificGuestlistQuery, 
  //useGetEventGuestlistQuery, 
  useGetMainGuestlistQuery, 
  useGetDownloadlistQuery,
  useUpdateMainGuestMutation, 
  useUpdateEventGuestMutation ,
  useBulkAddGuestMutation,
  useAddCsvGuestMutation,
  useEditGuestMutation,
  useBulkDeleteGuestMutation
} = guestRestApi