import React, { useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import CustomTextField from '../../base/Textfield/CustomTextField';
import Switch from '@mui/material/Switch';
import CustomSwitch from '../../base/Switch/CustomSwitch';
import DragAndDrop from '../../base/Upload/DragAndDrop';
import Button from '../../base/Button/Button';
import styles from './AccountSteps.module.css'
import { getAccountState, updateState } from '../../../features/account/accountSlice';
import { useUpdateAccountMutation, useUpdateObituaryMutation } from '../../../features/account/accountRestApi';
import Toast from '../../base/Toast/Toast';

interface ObituaryProps {
  handleStepper: (step: number) => void
}

const Obituary: React.FC<ObituaryProps> = (props) => {
  const dispatch = useAppDispatch()
  const accountState = useAppSelector(getAccountState);
  const [toast, setToast] = useState({
    message: '',
    type: '',
    open: true
  })
  const [checked, setChecked] = useState(true);
  const [isGiftsEnabled, setIsGiftsEnabled] = useState(true);
  const [file, setFile] = useState<File | string>('')
  const [obituary, setObituary] = useState({
    note: '',
    obituary: ''
  })

  const [updateObituary] = useUpdateObituaryMutation()
  const [updateAccount] = useUpdateAccountMutation()

  useEffect(() => {
    let initialise = {
      note: accountState.details.note ? accountState.details.note : '',
      obituary: accountState.details.obituary ? accountState.details.obituary : ''
    }
    setObituary(initialise)
  }, [accountState.details.obituary, accountState.details.note])

  useEffect(() => {
    let bol = accountState.details.showObituary ? true : false
    setChecked(bol)

    let gifts = accountState.details.showGifts ? true : false
    setIsGiftsEnabled(gifts)
  }, [accountState.details.showObituary, accountState.details.showGifts])

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    let body = {
      funeralId: accountState.details.id,
      query: {
        showObituary: event.target.checked ? 1 : 0
      }
    }
    updateAccount(body)
    dispatch(updateState({ showObituary: event.target.checked ? 1 : 0 }))
  };

  const handleEnableGiftsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    let body = {
      funeralId: accountState.details.id,
      query: {
        showGifts: event.target.checked ? 1 : 0
      }
    }
    updateAccount(body)
    dispatch(updateState({ showGifts: event.target.checked ? 1 : 0 }))
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value
    setObituary({
      ...obituary,
      [key]: value.replace(/\n\r?/g, '<br />')
    })
  }

  // handle File events
  const handleFile = function (e: any) {
    setFile(e[0])
  };

  const clearUpload = () => {
    setFile('')
  }

  const onSave = async () => {
    try {
      let body = {
        funeralId: accountState.details.id,
        ...obituary
      }
      const formData = new FormData()
      formData.append('file', file);
      formData.append('data', JSON.stringify(body));

      let rv = await updateObituary(formData).unwrap()
      if (rv.status === 'success') {
        setToast({
          message: 'Orbituary successfully updated!',
          type: 'success',
          open: true
        })
        dispatch(updateState({ ...obituary, obituary_image: rv.obituary_image }))
        nextStep()
      } else {
        setToast({
          message: 'Error updating obituary, please try again or contact our support team!',
          type: 'error',
          open: true
        })
      }

    } catch (e: any) {
      setToast({
        message: e.toString(),
        type: 'error',
        open: true
      })
    }
  }

  const nextStep = () => {
    if (accountState.details.isCompleted === 0 && accountState.details.step < 7) {
      updateAccount({ funeralId: accountState.details.id, query: { step: 7 } })
    }
    // props.handleStepper(7)
    props.handleStepper(3)
  }
  const prevStep = () => {
    props.handleStepper(1)
  }

  return (
    <Box className={styles.container} sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <InputLabel className={styles.labelText}>Personal Note </InputLabel>
          <CustomTextField  className={styles.input} sx={{width: {xs: '100%'}}} value={obituary.note.replace(/<br \/>?/g, '\n')} onChange={handleChange} id="note" name="note" multiline rows={4} placeholder="Personal Note"></CustomTextField >
        </Grid> */}
        <Grid item>
          <InputLabel className={styles.labelText}>
            Gifts
            <CustomSwitch
              sx={{ ml: 1 }}
              checked={isGiftsEnabled}
              onChange={handleEnableGiftsChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </InputLabel>
        </Grid>
        <Grid item>
          <InputLabel className={styles.labelText}>
            Obituary
            <CustomSwitch
              sx={{ ml: 1 }}
              checked={checked}
              onChange={handleCheckChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <div>Turn off Obituary Section (Default ON)</div>
          <CustomTextField className={styles.input} sx={{ width: { xs: '100%' } }} disabled={!checked} value={obituary.obituary.replace(/<br \/>?/g, '\n')} onChange={handleChange} id="obituary" name="obituary" multiline rows={4} placeholder="Obituary"></CustomTextField >
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLabel className={styles.labelText}>Upload Obituary image ({file || accountState.details.obituary_image ? 1 : 0}/1)</InputLabel>
          <DragAndDrop label="obituary-image" filetype='.jpeg, .png' handleFile={handleFile} currFileName={accountState.details.obituary_image} file={file} clearUpload={clearUpload} />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="end" alignItems="center">
        <Grid item xs={8} md={2.2} sx={{ mt: 2 }}>
          <Button className='btnSecondary' sx={{ width: { xs: '100%', sm: 270 }, mb: 4 }} onClick={onSave}>Next</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Obituary