import React, { useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styles from './EventSteps.module.css'
import CreateEvent from '../Create/CreateEvent';
import { getEventState, resetEventState } from '../../../features/event/eventSlice';
import Button from '../../base/Button/Button';
import Toast from '../../base/Toast/Toast';

interface CreateEventPageProps {
  handleStepper: (step: number) => void
}

const initial = {
  type: 'Funeral',
  title: '',
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  address: '',
  livestream: 'Live Stream Not Available',
  livestreamUrl: '',
  details: '',
  products: ''
}

const CreateEventPage: React.FC<CreateEventPageProps> = (props) => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const eventState = useAppSelector(getEventState)
  const [errors, setErrors] = useState({
    event: false
  })
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => {setToast(prev => ({...prev, open: false}))}
  })
  const [eventList, setEventlist] = useState([initial])

  useEffect(() => {
    let initial = {
      id: eventState.event.id,
      type: eventState.event.type,
      title: eventState.event.title,
      startDate: eventState.event.startDate,
      endDate: eventState.event.endDate,
      startTime: eventState.event.startTime,
      endTime: eventState.event.endTime,
      address: eventState.event.address,
      livestream: eventState.event.livestream,
      livestreamUrl: eventState.event.livestreamUrl,
      details: eventState.event.details,
      products: eventState.event.products
    }
    
    setEventlist([initial])
  }, [eventState.event])

  const nextStep = () => {
    console.log("AA", eventState.event)
    if(eventState.event.id) {
      setErrors({event: false})
      props.handleStepper(1)
    }else {
      setToast({
        ...toast,
        open: true,
        type: 'error',
        message: 'Please create an Event first!'
      })
      setErrors({event: true})
    }
    
  }

  return (
    <>
    <Toast {...toast}/>
      {
        eventList.map((item, index) => {
          return (
            <Box key={index} className={styles.container} sx={{mb:2, mt:2}}>
              <CreateEvent index={index} eventDetails={item} funeralId={eventState.event.funeralId || id} type="event"/>
            </Box>
          )
        })
      }
      <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
        <Grid item sx={{mt: 2}}>
          <Button className='btnPrimary' sx={{width: {xs: 250, sm: 300}, mb: 4}} onClick={nextStep}>Next</Button>
        </Grid>
      </Grid>
    </>
    
  )
}

export default CreateEventPage