import { StyledEngineProvider, createTheme, ThemeProvider } from '@mui/material/styles';
import { RouterProvider } from "react-router-dom";
import { router } from './router'
import Authenticate from './router/Authenticate';
import './App.css';

const theme = createTheme({
  typography: {
    fontFamily: ['Kumbh Sans', 'sans-serif'].join(','),
    fontSize: 16,
    button: {
      fontFamily: ['Kumbh Sans', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
      textTransform: 'none'
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: 'var(--TLC-gray)',
          color: 'white'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '18px',
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginBottom: '0px',
          marginTop: '0px'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px'
        }
      }
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Authenticate>
          <RouterProvider router={router} />
        </Authenticate>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
