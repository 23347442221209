import React from 'react'
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';

const TlcSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid var(--TLC-web-grey)',
    borderRadius: '3px',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #545454'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #545454'
  },
  '& .MuiListItem-root': {
    '&:hover': {
      backgroundColor: 'var(--TLC-web-grey)',
    },
    '&.Mui-selected': {
      backgroundColor: 'var(--TLC-gray)',
      color: 'var(--TLC-white)',
    },
  },
}
);

interface CustomSelectProps extends SelectProps {
  onChange: (event: SelectChangeEvent<any>) => void
};

const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  return (
    <TlcSelect {...props} />
  )
}

export default CustomSelect