import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import Link from '@mui/material/Link'
import Badge from '@mui/material/Badge'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import { CheckedIcon, Icon, IndeterminateIcon } from '../base/Checkbox/CustomCheckbox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CustomTextField from '../base/Textfield/CustomTextField';
import CustomSearch from '../base/Textfield/CustomSearch';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import styles from './ChecklistTable.module.css'
import Button from '../base/Button/Button';
import EditIcon from '../base/Icons/EditIcon';
import Toast from '../base/Toast/Toast';
import { getAccountState } from '../../features/account/accountSlice';
import { useUpdateChecklistMutation, useDeleteChecklistMutation, useAddChecklistMutation } from '../../features/checklist/checklistRestApi';
import { checklist } from '../../type';


interface ChecklistTableProps {
  data: checklist[]
}

interface TableToolbarProps {
  onRequestAdd: (value: string) => Promise<boolean>;
  onRequestSearch: (value: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected: checklist[]
  total: checklist[]
}

function TableToolbar (props: TableToolbarProps) {
  const { onRequestAdd, onRequestSearch, onSelectAllClick, selected, total } = props
  const [itemToAdd, setItemToAdd] = useState('')

  const addItem = (event: any) => {   
    setItemToAdd(event.target.value) 
  };

  const onAddItem = async () => {
    let rv = await onRequestAdd(itemToAdd)
    if(rv) {
      setItemToAdd('')
    }
  }

  return (
    <Box sx={{mb:1, px:2}}>
      <Grid container spacing={2} justifyContent="end" alignItems="center">
        <Grid item>
        <CustomSearch sx={{mb:4}} 
              placeholder='Search'
              onChange={e => onRequestSearch(e.target.value)}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="start" alignItems="center">
        <Grid item>
          <Checkbox
            sx={{visibility: 'hidden'}}
            icon ={<Icon />}
            checkedIcon={<CheckedIcon />}
            indeterminateIcon={<IndeterminateIcon/>}
            edge="start"
            checked={total.length > 0 && selected.length === total.length}
            indeterminate={selected.length > 0 && selected.length < total.length }
            onChange={onSelectAllClick}
            disableRipple
          />
        </Grid>
        <Grid item>
          <CustomTextField sx={{mb:4, marginBottom: 0, minWidth: {xs: '100%'}}} 
                placeholder='Add Item'
                value={itemToAdd}
                onChange={addItem}
                size="small"
          />
        </Grid>
        <Grid item>
              <Button className='btnPrimary' sx={{my: 0,width: {xs: '100%', sm: 300}}} onClick={onAddItem}>Add Item</Button>
        </Grid>
      </Grid>
    </Box>
  );
}

const ChecklistTable: React.FC<ChecklistTableProps> = (props) => {
  const accountState = useAppSelector(getAccountState);
  const { id } = useParams()
  const [selected, setSelected] = useState<checklist[]>([]);
  const [query, setQuery] = useState('')
  const [edit, setEdit] = useState<number | string>(0)
  const [error, setError] = useState(false)
  const [editDescription, setEditDescription] = useState('')
  const [toast, setToast] = useState({
    type: 'success',
    open: false,
    message: "",
    handleClose: () => {setToast(prev => ({...prev, open: false}))}
  })
  //Api call
  const [addChecklist] = useAddChecklistMutation()
  const [updateChecklist] = useUpdateChecklistMutation()
  const [deleteChecklist] = useDeleteChecklistMutation()

  useEffect(() => {
    let checked = props.data.filter((item) => item.status === 1)
    setSelected(checked)
  }, [props.data])

  const filteredItems = props.data.filter((item) => {
    return item.description.toLowerCase().includes(query.toLowerCase())
  })

  const isSelected = (id: string | number) => {
    return selected.map(x => x.id).indexOf(id) !== -1
  }

  const isEdited = (id: string | number ) => {
    return edit === id
  }

  const toggleChecklistItem = async (row: checklist, status: number) => {
    try {
      let body = {
        id: row.id,
        funeralId: row.funeralId,
        update: {
          status: status
        }
      }
      const payload = await updateChecklist(body).unwrap();
      if(payload.message === 'success') {
      }
    }catch(e) {
      console.error('rejected', e);
    }
  }

  //All checkbox
  const handleSelectAllClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = filteredItems;
    if (event.target.checked) {
      for(var item of filteredItems) {
        toggleChecklistItem(item, 1)
      }
      setSelected(newSelected);
      return;
    }else {
      for(var item of filteredItems) {
        toggleChecklistItem(item, 0)
      }
    }
    setSelected([]);
  };

  const handleCheck = async (event: React.MouseEvent<unknown>, row: checklist) => {
    try {
      let body = {
        id: row.id,
        funeralId: row.funeralId,
        update: {
          status: row.status ? 0 : 1
        }
      }
      const payload = await updateChecklist(body).unwrap();
      if(payload.message === 'success') {
      }
    }catch(e) {
      console.error('rejected', e);
    }
  }

  const handleChange = (event: any) => {
    let value = event.target.value.trim()
    if(!value) {
      setError(true)
      return
    }else {
      setError(false)
    }
    setEditDescription(value)
  }

  const handleAdd = async (value: string) => {
    let body = {
      funeralId: id || accountState.details.id,
      description: value
    }
    let found = filteredItems.find((item) => {
      return item.description === value.trim()
    })
    if(!found && value) {
      try {
        if(body.funeralId) {
          let rv = await addChecklist(body).unwrap();
          if(rv) return true
        }
        return false
      } catch (error) {
        
        console.error('rejected', error);
        return false
      }
    }else {
      setToast({
        ...toast,
        open: true,
        type: 'error',
        message: "This is a duplicate entry!"
      })
      return false
    }
    
  }

  const handleEdit = (event: React.MouseEvent<unknown>, row: checklist) => {
    setError(false)
    setEditDescription(row.description)
    setEdit(row.id)
  }

  const handleUpdate = async (event: React.MouseEvent<unknown>, row: checklist, property: keyof checklist) => {
    let body = {
      id: row.id,
      funeralId: row.funeralId,
      update: {
        [property]: editDescription
      }
    }
    if(!error) {
      try {
        const payload = await updateChecklist(body).unwrap();
        if(payload.message === 'success') {
          setEditDescription('')
          setEdit(0)
        }
      } catch (error) {
        console.error('rejected', error);
      }
    }else {
      setError(true)
    }
  }

  const handleDelete = async (event: React.MouseEvent<unknown>, row: checklist) => {
    try {
      let body = {
        id: row.id
      }
      const payload = await deleteChecklist(body).unwrap()
      if(payload.message === 'success') {
        setEdit(0)
      }
    }catch(e) {
      console.error('rejected', e);
    }
  }

  const handleCancel = async (event: React.MouseEvent<unknown>, row: checklist) => {
    try {
      setError(false)
      setEditDescription('')
      setEdit(0)
    }catch(e) {
      console.error('rejected', e);
    }
  }

  const handleSearch = (value: string) => {
    setQuery(value)
  }

  return (
    <Box sx={{minHeight: 350}}>
      <Toast {...toast}/>
      <TableToolbar onRequestAdd={handleAdd} onRequestSearch={handleSearch} onSelectAllClick={handleSelectAllClick} selected={selected} total={filteredItems}/>
      <Box className={styles.title} sx={{mb:1, px:1}}>List</Box>
      <Box className={styles.outlined}>
        {filteredItems.map((row,index) => {
          const isItemSelected = isSelected(row.id);
          const isItemEdit = isEdited(row.id)

          if(isItemEdit) {
            return (
              <ListItem 
                key={row.id}
                className={styles.mobileFlex}
              >
                <ListItemIcon>
                  <Checkbox
                    icon ={<Icon />}
                    checkedIcon={<CheckedIcon />}
                    edge="start"
                    onClick={(event) => handleCheck(event, row)}
                    checked={isItemSelected}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText>
                  <CustomTextField sx={{mb:4, marginBottom: 0, minWidth: {xs: '100%', md:400}}} 
                        defaultValue={row.description}
                        error={error} 
                        helperText={error ? 'Requires a valid description' : ''}
                        onChange={handleChange}
                        size="small"
                  />
                </ListItemText>
                <Box sx={{width: '100%', display: {xs: 'block', md:'contents'}, alignItems: 'center'}}>
                  <Link variant='inherit' onClick={(event) => handleDelete(event, row)} className={styles.link}>
                    <span style={{marginRight:'20px'}}>Delete</span>
                  </Link>
                  <Link variant='inherit' onClick={(event) => handleCancel(event, row)} className={styles.link}>
                    <span style={{marginRight:'20px'}}>Cancel</span>
                  </Link>
                  <Button className='btnSecondary' onClick={(event) => handleUpdate(event, row, 'description')}>Save</Button>
                </Box>
              </ListItem>
            )
          }else {
            return (
              <ListItem 
                key={row.id}
              >
                <ListItemIcon>
                  <Checkbox
                    icon ={<Icon />}
                    checkedIcon={<CheckedIcon />}
                    edge="start"
                    onClick={(event) => handleCheck(event, row)}
                    checked={row.status ? true : false}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={<span className={styles.description}>{row.description}</span>} 
                  className={row.status ? styles.completed : styles.text} sx={{width: {xs: 100}, overflow:'hidden'}}
                  disableTypography
                />
                <Box sx={{width: {xs: 100, md:200}, display: 'contents', alignItems: 'center'}}>
                  <Link variant='inherit' onClick={(event) => handleEdit(event, row)} className={styles.link}>
                    <Badge badgeContent={0}>
                      <EditIcon/>
                    </Badge>
                    <span style={{marginLeft:'10px'}}>Edit</span>
                  </Link>
                </Box>
              </ListItem>
            )
          }
          
        })}
      </Box>

    </Box>
  )
}

export default ChecklistTable