import {
  createBrowserRouter,
  Link, Outlet, useRouteError, Navigate, useLocation
} from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';
import Login from '../pages/login/Login';
import ForgetPw from '../pages/login/ForgetPw';
import ResetPassword from '../pages/login/ResetPassword';
import Dashboard from '../pages/dashboard/Dashboard';
import Approval from '../pages/approval/Approval';
import Checklist from '../pages/checklist/Checklist';
import Event from '../pages/event/event_list/Eventlist'
import Sales from '../pages/sales/Sales';
import EventCreate from '../pages/event/event_create/EventCreate';
import AccountCreation from '../pages/account/AccountCreation'
import EditAccount from '../pages/edit/EditAccount';
import Guestlist from '../pages/guest/Guestlist';
import EditEvent from '../pages/event/edit/EditEvent';
import ApprovalList from '../pages/approval/ApprovalList';
import Profile from '../pages/profile/Profile';
import EventRsvp from '../pages/event/event_list/EventRsvp';
import Footer from '../layouts/Footer/Footer';
import NavigateExternal from './NavigateExternal';
import FuneralList from "../pages/funeralDirector/funeral_list/FuneralList";
import FuneralAdd from "../pages/funeralDirector/funeral_add/FuneralAdd";

const HeaderLayout = () => {
  const location = useLocation()
  let routes = ['/login', '/request-reset', '/password-reset']
  return (
    <>
      <Outlet />
      {!routes.includes(location.pathname) ?
        <Footer /> : <></>
      }

    </>
  )
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HeaderLayout />,
    children: [
      {
        path: "/",
        element: (
          <Navigate to={'/login'} />
        ),
      },
      {
        path: "login",
        element: (
          <Login />
        ),
      },
      {
        path: "request-reset",
        element: (
          <ResetPassword />
        ),
      },
      {
        path: "password-reset",
        element: (
          <ForgetPw />
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
      },
      {
        path: "approval",
        element: (
          <ProtectedRoute>
            <Approval />
          </ProtectedRoute>
        ),
      },
      {
        path: "/funeral-director",
        element: (
          <ProtectedRoute>
            <FuneralList />
          </ProtectedRoute>
        ),
      },
      {
        path: "/funeral-director/add",
        element: (
          <ProtectedRoute>
            <FuneralAdd />
          </ProtectedRoute>
        ),
      },
      {
        path: "approval/:id",
        element: (
          <ProtectedRoute>
            <ApprovalList />
          </ProtectedRoute>
        ),
      },
      {
        path: "dashboard",
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "sales",
        element: (
          <NavigateExternal to="/orders" />
        ),
      },
      {
        path: "account/create",
        element: (
          <ProtectedRoute>
            <AccountCreation />
          </ProtectedRoute>
        ),
      },
      {
        path: "account/event/:id/create",
        element: (
          <ProtectedRoute>
            <EventCreate />
          </ProtectedRoute>
        ),
      },
      {
        path: "account/checklist/:id",
        element: (
          <ProtectedRoute>
            <Checklist />
          </ProtectedRoute>
        ),
      },
      {
        path: "account/guestlist/:id",
        element: (
          <ProtectedRoute>
            <Guestlist />
          </ProtectedRoute>
        ),
      },
      {
        path: "account/event/:id",
        element: (
          <ProtectedRoute>
            <Event />
          </ProtectedRoute>
        ),
      },
      {
        path: "account/edit/:id",
        element: (
          <ProtectedRoute>
            <EditAccount />
          </ProtectedRoute>
        ),
      },
      {
        path: "account/:id/event/edit/:eventId",
        element: (
          <ProtectedRoute>
            <EditEvent />
          </ProtectedRoute>
        ),
      },
      {
        path: "account/:id/event/rsvp/:eventId",
        element: (
          <ProtectedRoute>
            <EventRsvp />
          </ProtectedRoute>
        ),
      },
      {
        path: "*",
        element: (
          <Navigate to={'/login'} />
        ),
      },
    ]
  },

]);

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>Dang!</div>;
}